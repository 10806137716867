<template>
  <div>
    <div class="row my-1">
      <!-- Nome da Fase -->
      <div class="col-3">
        <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
        <input
          id="nomepublicacaoVaga"
          ref="nomepublicacaoVaga"
          type="text"
          class="form-control"
          name="Nome da Fase"
          v-model="propPublicacaoVaga.configs.text"
        />
      </div>
      <!-- Flag Fase ativa -->
      <div class="col-2 text-center">
        <p>Fase Ativa<br>
          <base-switch
            v-model="propPublicacaoVaga.configs.ativo"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Obrigatória -->
      <div class="col-2 text-center">
        <p>Obrigatória<br>
          <base-switch
            v-model="propPublicacaoVaga.configs.required"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Requer Aprovação -->
      <div class="col-2 text-center">
        <p>Requer Aprovação<br>
          <base-switch
            v-model="propPublicacaoVaga.configs.requerAprovacao"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Solicitante aprova -->
      <div class="col-2 text-center">
        <p>Solicitante Aprova<br>
          <base-switch
            v-model="propPublicacaoVaga.configs.solicitanteAprova"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-3">
        <label for="tipoRH">Tipo da Aprovação do RH:</label>
        <el-select
          ref="tipoRH"
          name="Tipo da Aprovação do RH:"
          class="select-primary"
          id="tipoRH"
          v-model="propPublicacaoVaga.configs.aprovacaoTipo.tipoRh"
        >
          <el-option value="area" label="Por Área"></el-option>
          <el-option value="usuario" label="Por Usuário"></el-option>
        </el-select>
      </div>
      <div class="col-3">
        <label for="areaRh">Área de Aprovação do RH:</label>
        <el-select
          ref="areaRh"
          name="Área de Aprovação do RH:"
          class="select-primary"
          id="areaRh"
          :disabled="propPublicacaoVaga.configs.aprovacaoTipo.tipoRh === 'usuario'"
          v-model="propPublicacaoVaga.configs.aprovacaoTipo.tipoAcesso"
        >
          <el-option
            v-for="tipo in tipoAcesso"
            :key="tipo.value"
            :label="tipo.text"
            :value="tipo.value">
          </el-option>
        </el-select>
      </div>
      <div class="col-3">
        <label for="usuarioRh">Usuário do RH:</label>
        <el-select
          ref="usuarioRh"
          name="Usuário do RH:"
          class="select-primary"
          id="usuarioRh"
          :disabled="propPublicacaoVaga.configs.aprovacaoTipo.tipoRh === 'area'"
          v-model="propPublicacaoVaga.configs.aprovacaoTipo.usuario"
        >
          <el-option
            v-for="user in propUsuarios"
            :key="user.id_empresa_user"
            :label="user.nm_nome"
            :value="user.id_empresa_user">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { Badge, BaseSwitch, Modal } from "src/components";
import { Select, Option } from 'element-ui';
export default {
  name: "PublicacaoVaga",
  props: {
    propPublicacaoVaga: { type: Object },
    propUsuarios: { type: Array }
  },
  components: {
    Badge,
    BaseSwitch,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      tipoAcesso: [
        { value: '2', text: "Gerência de RH" },
        { value: '3', text: "Coordenação de RH" },
        { value: '4', text: "Recrutadora de RH" }
      ],
    }
  }
}
</script>

<style>

</style>
