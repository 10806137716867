<template>
  <div>
    <div class="row my-1">
      <!-- Nome da Fase -->
      <div class="col-3">
        <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
        <input
          id="nomepublicacaoVaga"
          ref="nomepublicacaoVaga"
          type="text"
          class="form-control"
          name="Nome da Fase"
          v-model="propTestePerfil.configs.text"
        />
      </div>
      <!-- Flag Fase ativa -->
      <div class="col-2 text-center">
        <p>Fase Ativa<br>
          <base-switch
            v-model="propTestePerfil.configs.ativo"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Obrigatória -->
      <div class="col-2 text-center">
        <p>Obrigatória<br>
          <base-switch
            v-model="propTestePerfil.configs.required"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- ID do Test de Perfil -->
      <div class="col-2">
        <label for="idtesteperfil">ID do Teste de Perfil <span class="text-danger">*</span></label>
        <input
          id="idtesteperfil"
          ref="idtesteperfil"
          type="text"
          class="form-control"
          name="ID do Test de Perfil"
          v-model="propTestePerfil.configs.id_teste"
        />
      </div>
      <!-- Origem do Test de Perfil -->
      <div class="col-2">
        <label for="origemtesteperfil">Origem do Teste <span class="text-danger">*</span></label>
        <input
          id="origemtesteperfil"
          ref="origemtesteperfil"
          type="text"
          class="form-control"
          name="ID do Test de Perfil"
          v-model="propTestePerfil.configs.fl_origem_teste"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Badge, BaseSwitch, Modal } from "src/components";
import { Select, Option } from 'element-ui';
export default {
  name: "TestePerfil",
  props: {
    propTestePerfil: { type: Object }
  },
  components: {
    Badge,
    BaseSwitch,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
    }
  }
}
</script>

<style>

</style>
