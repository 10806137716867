<template>
  <div class="card-empresas" v-loading="loading">
      <!-- ID / Razão / Tipo Numero / Numero / N.Fantasia -->
      <div class="row my-2">
        <!-- Campo ID -->
        <div class="col-lg-2">
          <label for="id">ID <span class="text-danger">*</span></label>
          <input
            id="id"
            ref="id"
            type="text"
            class="form-control"
            name="Razão Social"
            v-model="empresa.id_empresa"
          />
        </div>
        <!-- Campo Razão Social -->
        <div class="col-lg-3">
          <label for="razao">Razão Social <span class="text-danger">*</span></label>
          <input
            id="razao"
            ref="razao"
            type="text"
            class="form-control"
            name="Razão Social"
            v-model="empresa.nm_razao"
          />
        </div>
        <!-- Tipo Numero -->
        <div class="col-lg-2">
          <label for="fl_cpf_cnpj">CNPJ/CPF</label>
          <el-select
            ref="fl_cpf_cnpj"
            name="CNPJ/CPF"
            class="select-primary"
            id="fl_cpf_cnpj"
            v-model="empresa.fl_cpf_cnpj"
          >
            <el-option
              v-for="tipo in cpfcnpj"
              :key="tipo.value"
              :value="tipo.value"
              :label="tipo.label"
            ></el-option>
          </el-select>
        </div>
        <!-- Numero de cadastro -->
        <div class="col-lg-2">
          <label for="nu_cadastro">{{ empresa.fl_cpf_cnpj === "0" ? "CNPJ" : "CPF" }}  <span class="text-danger">*</span></label>
          <input
            id="nu_cadastro"
            ref="nu_cadastro"
            type="text"
            class="form-control"
            :name="empresa.fl_cpf_cnpj === '0' ? 'CNPJ' : 'CPF'"
            v-model="empresa.nu_cadastro"
          />
        </div>
        <!-- Nome Fantasia -->
        <div class="col-lg-3">
          <label for="nm_fantasia">Nome Fantasia <span class="text-danger">*</span></label>
          <input
            id="nm_fantasia"
            ref="nm_fantasia"
            type="text"
            maxlength="30"
            class="form-control"
            name="Nome Fantasia"
            placeholder="Nome Fantasia"
            v-model.trim="empresa.nm_fantasia"
            @blur="validaOnBlur('nm_fantasia')"
          />
        </div>
      </div>
      <!-- URL Amigavel / Tipo de Recrutamento / Setor / Porte -->
      <div class="row my-2">
        <!-- URL Amigável -->
        <div class="col-lg-3">
          <label for="hl_url_amigavel">URL Amigável <span class="text-danger">*</span></label>
          <input
            id="hl_url_amigavel"
            ref="hl_url_amigavel"
            type="text"
            maxlength="30"
            class="form-control"
            name="Nome Fantasia"
            placeholder="Nome Fantasia"
            v-model.trim="empresa.hl_url_amigavel"
            @blur="validaOnBlur('hl_url_amigavel')"
          />
        </div>
        <!-- Tipo de Recrutamento -->
        <div class="col-lg-3">
          <label for="fl_tipo_recrutamento">Tipo de Recrutamento </label>
          <el-select
            ref="fl_tipo_recrutamento"
            name="Tipo de Recrutamento"
            class="select-primary"
            id="fl_tipo_recrutamento"
            v-model="empresa.fl_tipo_recrutamento"
          >
            <el-option
              v-for="tipo in selectRecrutamento"
              :key="tipo.value"
              :value="tipo.value"
              :label="tipo.label"
            ></el-option>
          </el-select>
        </div>
        <!-- Setor Empresarial -->
        <div class="col-lg-3">
          <label for="setor">Setor Empresarial </label>
          <el-select
            ref="setor"
            name="Setor Empresarial"
            class="select-primary"
            id="setor"
            v-model="empresa.fl_setor"
          >
            <el-option
              v-for="tipo in selectSetor"
              :key="tipo.value"
              :value="tipo.value"
              :label="tipo.label"
            ></el-option>
          </el-select>
        </div>
        <!-- Porta da Empresa -->
        <div class="col-lg-3">
          <label for="porte">Porte da Empresa </label>
          <el-select
            ref="porte"
            name="Porte da Empresa"
            class="select-primary"
            id="porte"
            v-model="empresa.fl_porte"
          >
            <el-option
              v-for="tipo in selectPorte"
              :key="tipo.value"
              :value="tipo.value"
              :label="tipo.label"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- Telefone / Email / Site -->
      <div class="row my-2">
        <!-- Telefone de contato -->
        <div class="col-3">
          <label for="nu_telefone">Telefone de contato</label>
          <input
            id="nu_telefone"
            ref="nu_telefone"
            type="text"
            maxlength="11"
            class="form-control"
            name="Telefone de contato"
            placeholder="Telefone de contato"
            v-model.number="empresa.nu_telefone"
          />
        </div>
        <!-- E-mail de principal -->
        <div class="col-3">
          <label for="hl_email">E-mail de principal</label>
          <input
            id="hl_email"
            ref="hl_email"
            type="email"
            maxlength="100"
            class="form-control"
            name="E-mail de principal"
            placeholder="E-mail de principal"
            v-model="empresa.hl_email"
          />
        </div>
        <!-- Site da Empresa -->
        <div class="col-6">
          <label for="hl_site">Site da empresa</label>
          <input
            id="hl_site"
            ref="hl_site"
            type="url"
            maxlength="100"
            class="form-control"
            name="Site da empresa"
            placeholder="https://...."
            v-model="empresa.hl_site"
          />
        </div>
      </div>
      <!-- URL do logo -->
      <div class="row my-2">
        <!-- URL do logo -->
        <div class="col-lg-6">
          <label for="hl_logo">URL do Logo</label>
          <input
            id="hl_logo"
            ref="hl_logo"
            type="url"
            maxlength="100"
            class="form-control"
            name="Logo da empresa"
            placeholder="https://...."
            v-model="empresa.hl_logo"
          />
        </div>
      </div>
      <!-- Imagem do Logo -->
      <div class="row my-2">
        <div class="col-lg-4">
          <label>Logo da empresa</label><br>
          <span class="text-muted" v-if="empresa.hl_logo === '' || empresa.hl_logo === null"><em>Não há nenhum logo definido.</em></span>
          <template v-else>
            <img :src="empresa.hl_logo" class="img-fluid my-2" alt="Logo"><br>
            <span class="my-2">
              <base-button type="warning" size="sm" @click="removerLogo">Remover</base-button>
            </span>
          </template>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-lg-1">
          <base-button type="primary" size="sm" @click="atualizaDados">Atualizar</base-button>
        </div>
      </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Loading, Table, TableColumn, Select, Option, Tooltip } from "element-ui";

Vue.use(Loading.directive);

export default {
  name: "DadosCadastrais",
  props: {
    propEmpresa: { type: Object }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Tooltip
  },
  data() {
    return {
      loading: false,
      empresa: {},
      cpfcnpj: [
        { value: '0', label: 'CNPJ' },
        { value: '1', label: 'CPF' }
      ],
      selectRecrutamento: [
        { value: '0', label: 'Interno' },
        { value: '1', label: 'Externo' }
      ],
      selectSetor: [
        { value: '1', label: 'Academia de Esportes / Artes Marciais'},
        { value: '2', label: 'Açúcar e Álcool'},
        { value: '3', label: 'Administração e Participação'},
        { value: '4', label: 'Agências de Turismo / Viagem'},
        { value: '5', label: 'Agricultura / Pecuária / Silvicultura'},
        { value: '6', label: 'Alimentos'},
        { value: '7', label: 'Arquitetura / Paisagismo / Urbanismo'},
        { value: '8', label: 'Assessoria de Imprensa'},
        { value: '9', label: 'Automação'},
        { value: '10', label: 'Automotivo'},
        { value: '11', label: 'Bancário / Financeiro'},
        { value: '12', label: 'Bebidas'},
        { value: '13', label: 'Bens de Capital'},
        { value: '14', label: 'Bens de Consumo (Outros)'},
        { value: '15', label: 'Borracha'},
        { value: '16', label: 'Café'},
        { value: '17', label: 'Calçados'},
        { value: '18', label: 'Comércio Atacadista'},
        { value: '19', label: 'Comércio Varejista'},
        { value: '20', label: 'Comunicação'},
        { value: '21', label: 'Concessionárias / Auto Peças'},
        { value: '22', label: 'Construção Civil'},
        { value: '23', label: 'Consultoria Geral'},
        { value: '24', label: 'Contabilidade/ Auditoria'},
        { value: '25', label: 'Corretagem (Imóveis)'},
        { value: '26', label: 'Corretagem (Seguros)'},
        { value: '27', label: 'Corretagem de Títulos e Valores Imobiliários'},
        { value: '28', label: 'Cosméticos'},
        { value: '29', label: 'Diversão/ Entretenimento'},
        { value: '30', label: 'Educação/ Idiomas'},
        { value: '31', label: 'Eletrônica/ Eletroeletrônica/ Eletrodomésticos'},
        { value: '32', label: 'Embalagens'},
        { value: '33', label: 'Energia/ Eletricidade'},
        { value: '34', label: 'Engenharia'},
        { value: '35', label: 'Equipamentos industriais'},
        { value: '36', label: 'Equipamentos médicos / precisão'},
        { value: '37', label: 'Estética/ Academias'},
        { value: '38', label: 'Eventos'},
        { value: '39', label: 'Farmacêutica/ Veterinária'},
        { value: '40', label: 'Financeiras'},
        { value: '41', label: 'Gráfica/ Editoras'},
        { value: '42', label: 'Imortação/ Exportação'},
        { value: '43', label: 'Incorporadora'},
        { value: '44', label: 'Indústrias'},
        { value: '45', label: 'Informática'},
        { value: '46', label: 'Internet/ Sites'},
        { value: '47', label: 'Jornais'},
        { value: '48', label: 'Jurídica'},
        { value: '49', label: 'Logística / Armazéns'},
        { value: '50', label: 'Madeiras'},
        { value: '51', label: 'Materiais de Construção'},
        { value: '52', label: 'Material de Escritório'},
        { value: '53', label: 'Mecânica/ Manutenção'},
        { value: '54', label: 'Metalúrgica / Siderúrgica'},
        { value: '55', label: 'Mineração'},
        { value: '56', label: 'Móveis e Artefatos de decoração'},
        { value: '57', label: 'Órgãos públicos'},
        { value: '58', label: 'Outros'},
        { value: '59', label: 'Papel e Derivados'},
        { value: '60', label: 'Petroquímico/ Petróleo'},
        { value: '61', label: 'Plásticos'},
        { value: '62', label: 'Prestadora de Serviços'},
        { value: '63', label: 'Publicidade / Propaganda'},
        { value: '64', label: 'Recursos Humanos'},
        { value: '65', label: 'Relações Públicas'},
        { value: '66', label: 'Representação Comercial'},
        { value: '67', label: 'Restaurante/ Industrial/ Fast Food'},
        { value: '68', label: 'Saúde'},
        { value: '69', label: 'Segurança Patrimonial'},
        { value: '70', label: 'Seguros/ Previdência Privada'},
        { value: '71', label: 'Sindicatos / Associações / ONGs'},
        { value: '72', label: 'Supermercado / Hipermercado'},
        { value: '73', label: 'Telecomunicações'},
        { value: '74', label: 'Telemarketing/ Call Center'},
        { value: '75', label: 'Têxtil/ Couro'},
        { value: '76', label: 'Transportes'},
        { value: '77', label: 'Turismo/ Hotelaria'}
      ],
      selectPorte: [
        { value: '1', label: 'Grande (acima de 500 funcionários)' },
        { value: '2', label: 'Médio (entre 100 e 499 funcionários)' },
        { value: '3', label: 'Pequeno (entre 20 e 99 funcionários)' },
        { value: '4', label: 'Micro empresa (até 19 funcionários)' },
      ],
    }
  },
  methods: {
    initValues() {
      //...
    },
    async removerLogo() {
       const data = {
        id_empresa: this.empresa.id_empresa
      }
      await this.$http.post(`${adminApiUrl}/empresa/removerlogo`, data)
        .then(res => {
          alert("Logo removido com sucesso!");
        })
        .catch(err => {
          alert("Não foi possível remover o Logo.");
          console.log(err);
        });
      this.empresa.hl_logo = null;
    },
    async atualizaDados() {
      const data = {
        id_empresa: this.empresa.id_empresa,
        nm_razao: this.empresa.nm_razao,
        nm_fantasia: this.empresa.nm_fantasia,
        fl_cpf_cnpj: this.empresa.fl_cpf_cnpj,
        nu_cadastro: this.empresa.nu_cadastro,
        fl_tipo_recrutamento: this.empresa.fl_tipo_recrutamento,
        fl_setor: this.empresa.fl_setor,
        fl_porte: this.empresa.fl_porte,
        hl_email: this.empresa.hl_email,
        nu_telefone: this.empresa.nu_telefone,
        hl_site: this.empresa.hl_site,
        hl_logo: this.empresa.hl_logo,
        hl_url_amigavel: this.empresa.hl_url_amigavel
      }
      //console.log('data -> ', data)
      await this.$http
        .post('empresa/atualizadados', data)
        .then(() => {
          this.notificacao('Dados atualizados com sucesso!', 'success');
        })
        .catch(err => {
          console.log('Erro na API -> ', err)
          this.notificacao('Erro na atualização!', 'warning');
        });
    },
    notificacao(msg, tipo) {
      this.$notify({
        type: tipo,
        message: msg,
        icon: "tim-icons icon-bell-55",
      });
    }
  },
  mounted() {
    setTimeout(() => {
      this.empresa = this.propEmpresa;
    }, 500);
  },
  destroyed() {
    //.
  },
}
</script>

<style>
.card-empresas {
  margin: 10px;
  padding: 10px;
}
</style>
