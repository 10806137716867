import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
//import firebase from 'firebase/app'
//import "firebase/auth"
import "./plugins/axios";

// router setup
import router from "./routes/starterRouter";
//import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n
});
