<template>
  <div>
    <div class="row"><route-bread-crumb /></div>
    <div class="row">
      <div class="col">
        <card card-body-classes="table-full-width">
          <div slot="header"><h4 class="card-title">Lista de Empresas</h4></div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <div class="form-check form-check-inline">
              <base-input v-model="searchQuery"
                prepend-icon="fas fa-search"
                placeholder="Procurar...">
              </base-input>
            </div>
          </div>
          <el-table :data="queriedData">
            <!-- ID Empresa -->
            <el-table-column min-width="50" prop="id_empresa" label="#" align="center">
            </el-table-column>
            <!-- Nome Fantasia e Razão Social -->
            <el-table-column min-width="250" label="Empresa">
              <div slot-scope="{ row }">
                <strong>
                  <router-link :to="`/empresas/detalheempresa/${row.id_empresa}`">
                    {{row.nm_fantasia}}</router-link>
                  </strong><br>
                <small>{{row.nm_razao}}</small>
              </div>
            </el-table-column>
            <!-- Numero de cadastro -->
            <el-table-column min-width="100" label="Tipo">
              <div slot-scope="{ row }">
                <strong>{{row.fl_cpf_cnpj === '0' ? 'CNPJ' : 'CPF'}}</strong><br>
                <small>{{row.nu_cadastro}}</small>
              </div>
            </el-table-column>
            <!-- <el-table-column min-width="120" label="Setor/Porte">
              <div slot-scope="{ row }">
                <small>{{retornaSetor(row.fl_setor)}}</small><br>
                {{retornaPorte(row.fl_porte)}}
              </div>
            </el-table-column> -->
            <!-- Plano atual -->
            <el-table-column min-width="120" label="Plano">
              <div slot-scope="{ row }">
                <strong>{{retornaNomePlano(row.plano.nm_plano)}}</strong>
              </div>
            </el-table-column>
            <!-- Datas do plano -->
            <el-table-column min-width="120" label="Datas">
              <div slot-scope="{ row }">
                <small>
                Inicio: {{row.plano.dt_inicio | formataDiaHora }}<br>
                Fim: {{row.plano.dt_fim | formataDiaHora }}<br>
                </small>
              </div>
            </el-table-column>
             <!-- Qtd de usuários -->
            <el-table-column min-width="120" label="Usuários">
              <div slot-scope="{ row }">
                RH: <strong>{{row.plano.qtdRh}}</strong><br>
                Areas: {{row.plano.qtdArea}}
              </div>
            </el-table-column>
            <!-- <el-table-column
              min-width="150"
              prop="created_at"
              label="Cadastro em"
              :formatter="formatTimestamp">
            </el-table-column> -->
          </el-table>
          <!-- Rodapé do Card da Lista -->
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Mostrando {{ from + 1 }} de {{ to }} em {{ total }} testes.<br>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-6">
        <pre>{{empresas}}</pre>
      </div>
      <div class="col-6"><pre>{{planos}}</pre></div>
    </div> -->
  </div>
</template>

<script>
import { adminApiUrl } from "@/global";
import empresasPaginacao from './empresasPaginacao';
import moment from "moment";
import { Table, TableColumn, Select, Option } from 'element-ui';
import { RouteBreadCrumb, BasePagination, Badge } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';

export default {
  name: "ListaEmpresas",
  mixins: [empresasPaginacao],
  components: {
    BasePagination,
    Badge,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RouteBreadCrumb,
  },
  data() {
    return {
      empresas: [],
      planos: [],
      selectRecrutamento: [
        { value: '0', label: 'Interno' },
        { value: '1', label: 'Externo' }
      ],
      selectSetor: [
        { value: '1', label: 'Academia de Esportes / Artes Marciais'},
        { value: '2', label: 'Açúcar e Álcool'},
        { value: '3', label: 'Administração e Participação'},
        { value: '4', label: 'Agências de Turismo / Viagem'},
        { value: '5', label: 'Agricultura / Pecuária / Silvicultura'},
        { value: '6', label: 'Alimentos'},
        { value: '7', label: 'Arquitetura / Paisagismo / Urbanismo'},
        { value: '8', label: 'Assessoria de Imprensa'},
        { value: '9', label: 'Automação'},
        { value: '10', label: 'Automotivo'},
        { value: '11', label: 'Bancário / Financeiro'},
        { value: '12', label: 'Bebidas'},
        { value: '13', label: 'Bens de Capital'},
        { value: '14', label: 'Bens de Consumo (Outros)'},
        { value: '15', label: 'Borracha'},
        { value: '16', label: 'Café'},
        { value: '17', label: 'Calçados'},
        { value: '18', label: 'Comércio Atacadista'},
        { value: '19', label: 'Comércio Varejista'},
        { value: '20', label: 'Comunicação'},
        { value: '21', label: 'Concessionárias / Auto Peças'},
        { value: '22', label: 'Construção Civil'},
        { value: '23', label: 'Consultoria Geral'},
        { value: '24', label: 'Contabilidade/ Auditoria'},
        { value: '25', label: 'Corretagem (Imóveis)'},
        { value: '26', label: 'Corretagem (Seguros)'},
        { value: '27', label: 'Corretagem de Títulos e Valores Imobiliários'},
        { value: '28', label: 'Cosméticos'},
        { value: '29', label: 'Diversão/ Entretenimento'},
        { value: '30', label: 'Educação/ Idiomas'},
        { value: '31', label: 'Eletrônica/ Eletroeletrônica/ Eletrodomésticos'},
        { value: '32', label: 'Embalagens'},
        { value: '33', label: 'Energia/ Eletricidade'},
        { value: '34', label: 'Engenharia'},
        { value: '35', label: 'Equipamentos industriais'},
        { value: '36', label: 'Equipamentos médicos / precisão'},
        { value: '37', label: 'Estética/ Academias'},
        { value: '38', label: 'Eventos'},
        { value: '39', label: 'Farmacêutica/ Veterinária'},
        { value: '40', label: 'Financeiras'},
        { value: '41', label: 'Gráfica/ Editoras'},
        { value: '42', label: 'Imortação/ Exportação'},
        { value: '43', label: 'Incorporadora'},
        { value: '44', label: 'Indústrias'},
        { value: '45', label: 'Informática'},
        { value: '46', label: 'Internet/ Sites'},
        { value: '47', label: 'Jornais'},
        { value: '48', label: 'Jurídica'},
        { value: '49', label: 'Logística / Armazéns'},
        { value: '50', label: 'Madeiras'},
        { value: '51', label: 'Materiais de Construção'},
        { value: '52', label: 'Material de Escritório'},
        { value: '53', label: 'Mecânica/ Manutenção'},
        { value: '54', label: 'Metalúrgica / Siderúrgica'},
        { value: '55', label: 'Mineração'},
        { value: '56', label: 'Móveis e Artefatos de decoração'},
        { value: '57', label: 'Órgãos públicos'},
        { value: '58', label: 'Outros'},
        { value: '59', label: 'Papel e Derivados'},
        { value: '60', label: 'Petroquímico/ Petróleo'},
        { value: '61', label: 'Plásticos'},
        { value: '62', label: 'Prestadora de Serviços'},
        { value: '63', label: 'Publicidade / Propaganda'},
        { value: '64', label: 'Recursos Humanos'},
        { value: '65', label: 'Relações Públicas'},
        { value: '66', label: 'Representação Comercial'},
        { value: '67', label: 'Restaurante/ Industrial/ Fast Food'},
        { value: '68', label: 'Saúde'},
        { value: '69', label: 'Segurança Patrimonial'},
        { value: '70', label: 'Seguros/ Previdência Privada'},
        { value: '71', label: 'Sindicatos / Associações / ONGs'},
        { value: '72', label: 'Supermercado / Hipermercado'},
        { value: '73', label: 'Telecomunicações'},
        { value: '74', label: 'Telemarketing/ Call Center'},
        { value: '75', label: 'Têxtil/ Couro'},
        { value: '76', label: 'Transportes'},
        { value: '77', label: 'Turismo/ Hotelaria'}
      ],
      selectPorte: [
        { value: '1', label: 'Grande (+ 500)' },
        { value: '2', label: 'Médio (100 e 499)' },
        { value: '3', label: 'Pequeno ( 20 e 99)' },
        { value: '4', label: 'Micro (até 19)' },
      ],
      nomePlanos: [
        { label: "Free", value: "aa2d6e4f578eb0cfaba23beef76c2194" },
        { label: "Básico", value: "4069743c5c82111a7a66113eb450e95d" },
        { label: "Profissional", value: "e5f66152b69ccd368b3decaa070198b8" },
        { label: "Enterprise", value: "09300df44b9d20cc219b25abddc3346e" }
      ]
    }
  },
  methods: {
    async getEmpresas() {
      await this.$http
        .get(`${adminApiUrl}/empresa/lista`)
        .then(res => {
          this.empresas = res.data.empresas;
          this.planos = res.data.planos;
          Object.keys(this.empresas).forEach(element => {
            var plano = this.planos.find(i => i.id_empresa === this.empresas[element].id_empresa);
            var planoEmpresa = {
              nm_plano: plano.json_detalhes.nm_plano,
              dt_inicio: plano.json_detalhes.dt_inicio,
              dt_fim: plano.json_detalhes.dt_fim,
              qtdRh: plano.json_detalhes.usuarios.qtdRh,
              qtdArea: plano.json_detalhes.usuarios.qtdArea
            };
            this.$set(this.empresas[element], 'plano', planoEmpresa);
          });
          //console.log('retorno do axios: ', this.candidatos);
        })
        .catch(err => {
          console.log(err.message)
        });
    },
    formatTimestamp: function(row, column) {
      var date = row[column.property]
      if (date == undefined) {
        return "";
      }
      return moment(date).format("DD/MM/YYYY hh:mm");
    },
    retornaSetor(value) {
      let tipo = this.selectSetor.find(i => i.value === value);
      return tipo.label;
    },
    retornaPorte(value) {
      let tipo = this.selectPorte.find(i => i.value === value);
      return tipo.label;
    },
    retornaNomePlano(value) {
      let nome = this.nomePlanos.find(i => i.value === value);
      return nome.label;
    }
  },
  filters: {
    formataDiaHora:function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
  },
  mounted() {
    this.getEmpresas();
    //this.addPlanoEmpresa();
  }
}
</script>

<style>

</style>
