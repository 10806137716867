<template>
  <div>
    <div class="row"><route-bread-crumb /></div>
    <div class="row">
      <div class="col-12">
        <card v-if="loadStatus">
          <template slot="header">
            <div class="row mb-3">
              <div class="col-2 align-self-center" v-if="empresa.hl_logo !== '' && empresa.hl_logo !== null">
                <!-- Logo padrão ->> https://storagematchjobdefault.blob.core.windows.net/matchjob/matchjobempresas.png -->
                <img :src="empresa.hl_logo" class="img-fluid" alt="Logo">
              </div>
              <div class="col align-self-center">
                <h4 class="empresa-h4">
                  <span class="text-muted">Nome Fantasia: </span>{{empresa.nm_fantasia}}
                </h4>
                <h4 class="empresa-h4">
                  <span class="text-muted">Razão Social: </span>{{empresa.nm_razao}}
                </h4>
                <h4 class="empresa-h4">
                  <span class="text-muted">{{empresa.fl_cpf_cnpj === "0" ? "CNPJ" : "CPF"}}: </span>
                  {{empresa.nu_cadastro}}
                </h4>
              </div>
            </div>
          </template>
          <tabs type="primary">
            <tab-pane label="Dados">
              <DadosCadastrais
                :propEmpresa="empresa"
              />
            </tab-pane>
            <tab-pane label="Plano">
              <PlanoEmpresa
                :key="makeId()"
                :propPlano="plano"
              />
            </tab-pane>
            <tab-pane label="Contrato">
              <ContratoEmpresa
                :key="makeId()"
                :propContrato="contrato"
              />
            </tab-pane>
            <tab-pane label="Usuários">
              <UsuariosEmpresa
                :key="makeId()"
                :propUsuarios="usuarios"
                :propPlano="plano"
              />
            </tab-pane>
            <tab-pane label="Endereços">
              <EnderecosEmpresa
                :key="makeId()"
                :propEnderecos="enderecos"
              />
            </tab-pane>
            <tab-pane label="Vagas">
              <VagasEmpresas
                :key="makeId()"
                :propVagas="vagas"
                :propUsuarios="usuarios"
                :propPlano="plano"
              />
            </tab-pane>
            <tab-pane label="PS">
              <ProcessoSeletivo
                :key="makeId()"
                :propConfigFases="empresa.json_config_fase_ps"
                :propProcessosSeletivos="empresa.json_processo_selecao"
                :propUsuarios="usuarios"
              />
            </tab-pane>
            <tab-pane label="Pag Carreiras">
              <PaginaCarreiras
                :key="makeId()"
                :propPagina="empresa.json_config_pg_vagas"
              />
            </tab-pane>
            <tab-pane label="Mensagens">
              <MensagensEmpresa
                :key="makeId()"
                :propMensagens="mensagens"
              />
            </tab-pane>
            <tab-pane label="Raw Data">
              <div class="row">
                <div class="col-lg-12">
                  <base-button size="sm" @click="showRawData('empresa')">Dados da Empresa</base-button>
                  <base-button size="sm" @click="showRawData('plano')">Plano</base-button>
                  <base-button size="sm" @click="showRawData('processo')">Processo de Seleção</base-button>
                  <base-button size="sm" @click="showRawData('config')">Config Fases</base-button>
                  <base-button size="sm" @click="showRawData('pagina')">Pagina de Carreiras</base-button>
                  <base-button size="sm" @click="showRawData('integracoes')">Integrações</base-button>
                  <base-button size="sm" @click="showRawData('enderecos')">Endereços</base-button>
                  <base-button size="sm" @click="showRawData('usuarios')">Usuários</base-button>
                  <base-button size="sm" @click="showRawData('vagas')">Vagas</base-button>
                </div>
              </div>
              <div class="row" v-if="rawData.empresa">
                <div class="col-lg-12">
                  <h3>Empresas</h3>
                  <json-viewer :value="rawData.empresaData"></json-viewer>
                  <!-- <pre>{{ rawData.empresaData }}</pre> -->
                  <span class="text-muted">
                    <strong>Campos removidos da exibição:</strong><br>
                    json_processo_selecao<br>
                    json_config_fase_ps<br>
                    json_config_pg_vagas<br>
                    json_integracoes
                  </span>
                </div>
              </div>
              <div class="row" v-if="rawData.plano">
                <div class="col-lg-12">
                  <h3>Plano</h3>
                  <json-viewer :value="plano"></json-viewer>
                </div>
              </div>
              <div class="row" v-if="rawData.ps">
                <div class="col-lg-12">
                  <h3>Processo de Seleção</h3>
                  <json-viewer :value="empresa.json_processo_selecao"></json-viewer>
                </div>
              </div>
              <div class="row" v-if="rawData.configFases">
                <div class="col-lg-12">
                  <h3>Config Fases</h3>
                  <json-viewer :value="empresa.json_config_fase_ps"></json-viewer>
                </div>
              </div>
              <div class="row" v-if="rawData.pg">
                <div class="col-lg-12">
                  <h3>Pagina de Carreiras</h3>
                  <json-viewer :value="empresa.json_config_pg_vagas"></json-viewer>
                </div>
              </div>
              <div class="row" v-if="rawData.integracoes">
                <div class="col-lg-12">
                  <h3>Integrações</h3>
                  <json-viewer :value="empresa.json_integracoes"></json-viewer>
                </div>
              </div>
              <div class="row" v-if="rawData.enderecos">
                <div class="col-lg-12">
                  <h3>Endereços</h3>
                  <json-viewer :value="enderecos"></json-viewer>
                </div>
              </div>
              <div class="row" v-if="rawData.usuarios">
                <div class="col-lg-12">
                  <h3>Usuários</h3>
                  <json-viewer :value="usuarios"></json-viewer>
                </div>
              </div>
              <div class="row" v-if="rawData.vagas">
                <div class="col-lg-12">
                  <h3>Vagas</h3>
                  <json-viewer :value="vagas"></json-viewer>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </card>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-4">
        empresa.json_config_fase_ps ->>> <pre>{{empresa.json_config_fase_ps}}</pre>
      </div>
      <div class="col-4">
        empresa.json_processo_selecao ->><pre>{{empresa.json_processo_selecao}}</pre>
      </div>
      <div class="col-4">
        empresa.json_config_pg_vagas ->>><pre>{{empresa.json_config_pg_vagas}}</pre>
      </div>
    </div>
    <pre>{{enderecos}}</pre>
    <pre>{{usuarios}}</pre>
    plano -> <pre>{{plano}}</pre>
    <pre>{{vagas}}</pre> -->
  </div>
</template>

<script>
import { adminApiUrl } from "@/global";
import { TabPane, Tabs, Badge, Collapse, CollapseItem, BaseSwitch, Modal } from "src/components";
import moment from "moment";
import { Table, TableColumn, Select, Option, Tooltip } from "element-ui";
import { RouteBreadCrumb } from "src/components";
import swal from "sweetalert2";

import JsonViewer from 'vue-json-viewer'

import DadosCadastrais from './components/DadosCadastrais.vue';
import PlanoEmpresa from './components/PlanoEmpresa.vue';
import ContratoEmpresa from './components/ContratoEmpresa.vue';
import UsuariosEmpresa from './components/UsuariosEmpresa.vue';
import VagasEmpresas from './components/VagasEmpresas.vue';
import ProcessoSeletivo from './components/ProcessoSeletivo.vue';
import MensagensEmpresa from './components/MensagensEmpresa.vue';
import PaginaCarreiras from './components/PaginaCarreiras.vue';
import EnderecosEmpresa from './components/EnderecosEmpresa.vue';

export default {
  name: "DetalheEmpresa",
  props: ["id"],
  components: {
    TabPane,
    Tabs,
    Badge,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Collapse,
    CollapseItem,
    Tooltip,
    BaseSwitch,
    Modal,
    JsonViewer,
    DadosCadastrais,
    PlanoEmpresa,
    ContratoEmpresa,
    UsuariosEmpresa,
    VagasEmpresas,
    ProcessoSeletivo,
    MensagensEmpresa,
    PaginaCarreiras,
    EnderecosEmpresa
  },
  data() {
    return {
      loadStatus: false,
      empresa: {},
      enderecos: [],
      usuarios: [],
      plano: {},
      contrato: {},
      vagas: [],
      mensagens: [],
      rawData: {
        empresa: false,
        empresaData: {},
        plano: false,
        ps: false,
        configFases: false,
        pg: false,
        integracoes: false,
        enderecos: false,
        usuarios: false,
        vagas: false,
        mensagens: false
      }
    }
  },
  methods: {
    async getEmpresa() {
      const data = { id_empresa: this.$route.params.id };
      await this.$http
        .post('empresa/detalhes', data)
        .then((res) => {
          this.empresa = res.data.empresa;
          this.enderecos = res.data.enderecos;
          this.usuarios = res.data.usuarios;
          this.plano = res.data.plano;
          this.vagas = res.data.vagas;
          this.contrato = res.data.contrato;
          this.mensagens = res.data.mensagens;
          setTimeout(() => {
            this.loadStatus = true;
          }, 1000);
          //console.log("retorno do axios: ", this.candidato);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    showRawData(value) {
      if (value === 'empresa') {
        this.rawData.empresaData = structuredClone(this.empresa);
        this.$delete(this.rawData.empresaData, 'json_processo_selecao');
        this.$delete(this.rawData.empresaData, 'json_config_fase_ps');
        this.$delete(this.rawData.empresaData, 'json_config_pg_vagas');
        this.$delete(this.rawData.empresaData, 'json_integracoes');
        this.rawData.empresa = true;
        this.rawData.plano = false;
        this.rawData.ps = false;
        this.rawData.configFases = false;
        this.rawData.pg = false;
        this.rawData.integracoes = false;
        this.rawData.enderecos = false;
        this.rawData.usuarios = false;
        this.rawData.vagas = false;
      } else if (value === 'plano') {
        this.rawData.empresa = false;
        this.rawData.plano = true;
        this.rawData.ps = false;
        this.rawData.configFases = false;
        this.rawData.pg = false;
        this.rawData.integracoes = false;
        this.rawData.enderecos = false;
        this.rawData.usuarios = false;
        this.rawData.vagas = false;
      } else if (value === 'processo') {
        this.rawData.empresa = false;
        this.rawData.plano = false;
        this.rawData.ps = true;
        this.rawData.configFases = false;
        this.rawData.pg = false;
        this.rawData.integracoes = false;
        this.rawData.enderecos = false;
        this.rawData.usuarios = false;
        this.rawData.vagas = false;
      } else if (value === 'config') {
        this.rawData.empresa = false;
        this.rawData.plano = false;
        this.rawData.ps = false;
        this.rawData.configFases = true;
        this.rawData.pg = false;
        this.rawData.integracoes = false;
        this.rawData.enderecos = false;
        this.rawData.usuarios = false;
        this.rawData.vagas = false;
      } else if (value === 'pagina') {
        this.rawData.empresa = false;
        this.rawData.plano = false;
        this.rawData.ps = false;
        this.rawData.configFases = false;
        this.rawData.pg = true;
        this.rawData.integracoes = false;
        this.rawData.enderecos = false;
        this.rawData.usuarios = false;
        this.rawData.vagas = false;
      } else if (value === 'integracoes') {
        this.rawData.empresa = false;
        this.rawData.plano = false;
        this.rawData.ps = false;
        this.rawData.configFases = false;
        this.rawData.pg = false;
        this.rawData.integracoes = true;
        this.rawData.enderecos = false;
        this.rawData.usuarios = false;
        this.rawData.vagas = false;
      } else if (value === 'enderecos') {
        this.rawData.empresa = false;
        this.rawData.plano = false;
        this.rawData.ps = false;
        this.rawData.configFases = false;
        this.rawData.pg = false;
        this.rawData.integracoes = false;
        this.rawData.enderecos = true;
        this.rawData.usuarios = false;
        this.rawData.vagas = false;
      } else if (value === 'usuarios') {
        this.rawData.empresa = false;
        this.rawData.plano = false;
        this.rawData.ps = false;
        this.rawData.configFases = false;
        this.rawData.pg = false;
        this.rawData.integracoes = false;
        this.rawData.enderecos = false;
        this.rawData.usuarios = true;
        this.rawData.vagas = false;
      } else if (value === 'vagas') {
        this.rawData.empresa = false;
        this.rawData.plano = false;
        this.rawData.ps = false;
        this.rawData.configFases = false;
        this.rawData.pg = false;
        this.rawData.integracoes = false;
        this.rawData.enderecos = false;
        this.rawData.usuarios = false;
        this.rawData.vagas = true;
      }
    },
    makeId() {
      var result = '';
      var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < 8; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
  },
  mounted() {
    this.getEmpresa();
  }
}
</script>

<style>
.empresa-h4 {
  margin: 0;
}
</style>
