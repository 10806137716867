<template>
  <div class="card-empresas">
    <div class="row my-2">
      <div class="col">
        <base-button type="warning" class="mx-2" size="sm" :disabled="flbutConfigFases" @click="atualizaConfigFases">Salvar Config Padrão</base-button>
        <base-button type="warning" class="mx-2" size="sm" :disabled="flbutProcessos" @click="atualizaProcessosSeletivos">Salvar Processos</base-button>
        <base-button type="primary" class="mx-2" size="sm" @click="flLoaded = true">Carregar</base-button>
        <base-button type="warning" class="mx-2" size="sm" @click="validaJson">Check JSON</base-button>
        <base-button type="default" class="mx-2" size="sm" @click="viewJson('config')">View JSON Config</base-button>
        <base-button type="default" class="mx-2" size="sm" @click="viewJson('processos')">View JSON PS</base-button>
        <base-button type="info" class="mx-2" size="sm" @click="addConfigFasesPadrao">Add Config Padrão</base-button>
        <base-button type="info" class="mx-2" size="sm" @click="addProcessosPadrao">Add PS Padrão</base-button>
      </div>
    </div>
    <div class="row my-2" v-if="flErros">
      <div class="col-lg-12">
        <base-alert type="danger">
          <strong>Erros:</strong><br>
          <template v-if="errosConfigFases.geral.length > 0">
            Geral: <ul><li v-for="(item, index) in errosConfigFases.geral" :key="index">{{ item }}</li></ul>
          </template>
          <template v-if="errosConfigFases.triagem.length > 0">
            Triagem: <ul><li v-for="(item, index) in errosConfigFases.triagem" :key="index">{{ item }}</li></ul>
          </template>
          <template v-if="errosConfigFases.dinamica.length > 0">
            Dinamica: <ul><li v-for="(item, index) in errosConfigFases.dinamica" :key="index">{{ item }}</li></ul>
          </template>
          <template v-if="errosConfigFases.negociacao.length > 0">
            Negociação: <ul><li v-for="(item, index) in errosConfigFases.negociacao" :key="index">{{ item }}</li></ul>
          </template>
          <template v-if="errosConfigFases.contratacao.length > 0">
            Contratação: <ul><li v-for="(item, index) in errosConfigFases.contratacao" :key="index">{{ item }}</li></ul>
          </template>
          <template v-if="errosConfigFases.entrevistas.length > 0">
            Entrevistas: <ul><li v-for="(item, index) in errosConfigFases.entrevistas" :key="index">{{ item }}</li></ul>
          </template>
          <template v-if="errosConfigFases.enviaTestes.length > 0">
            Envio de Testes: <ul><li v-for="(item, index) in errosConfigFases.enviaTestes" :key="index">{{ item }}</li></ul>
          </template>
          <template v-if="errosConfigFases.testePerfil.length > 0">
            Teste de perfil: <ul><li v-for="(item, index) in errosConfigFases.testePerfil" :key="index">{{ item }}</li></ul>
          </template>
          <template v-if="errosConfigFases.publicacaoVaga.length > 0">
            Publicação da Vaga: <ul><li v-for="(item, index) in errosConfigFases.publicacaoVaga" :key="index">{{ item }}</li></ul>
          </template>
          <template v-if="errosConfigFases.validacaoDados.length > 0">
            Validação de Dados: <ul><li v-for="(item, index) in errosConfigFases.validacaoDados" :key="index">{{ item }}</li></ul>
          </template>
          <template v-if="errosConfigFases.reValidacaoMatch.length > 0">
            Re-validação de Dados: <ul><li v-for="(item, index) in errosConfigFases.reValidacaoMatch" :key="index">{{ item }}</li></ul>
          </template>
        </base-alert>
      </div>
    </div>
    <collapse :multiple-active="true" v-if="flLoaded">
      <collapse-item title="Configuração padrão">
        <collapse :multiple-active="true">
          <collapse-item title="Publicação da Vaga">
            <div class="row my-1">
              <!-- Nome da Fase -->
              <div class="col-3">
                <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
                <input
                  id="nomepublicacaoVaga"
                  ref="nomepublicacaoVaga"
                  type="text"
                  class="form-control"
                  name="Nome da Fase"
                  v-model="propConfigFases.publicacaoVaga.text"
                />
              </div>
              <!-- Flag Fase ativa -->
              <div class="col-2 text-center">
                <p>Fase Ativa<br>
                  <base-switch
                    v-model="propConfigFases.publicacaoVaga.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Obrigatória -->
              <div class="col-2 text-center">
                <p>Obrigatória<br>
                  <base-switch
                    v-model="propConfigFases.publicacaoVaga.required"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Requer Aprovação -->
              <div class="col-2 text-center">
                <p>Requer Aprovação<br>
                  <base-switch
                    v-model="propConfigFases.publicacaoVaga.requerAprovacao"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Solicitante aprova -->
              <div class="col-2 text-center">
                <p>Solicitante Aprova<br>
                  <base-switch
                    v-model="propConfigFases.publicacaoVaga.solicitanteAprova"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-3">
                <label for="tipoRH">Tipo da Aprovação do RH:</label>
                <el-select
                  ref="tipoRH"
                  name="Tipo da Aprovação do RH:"
                  class="select-primary"
                  id="tipoRH"
                  v-model="propConfigFases.publicacaoVaga.aprovacaoTipo.tipoRh"
                >
                  <el-option value="area" label="Por Área"></el-option>
                  <el-option value="usuario" label="Por Usuário"></el-option>
                </el-select>
              </div>
              <div class="col-3">
                <label for="areaRh">Área de Aprovação do RH:</label>
                <el-select
                  ref="areaRh"
                  name="Área de Aprovação do RH:"
                  class="select-primary"
                  id="areaRh"
                  :disabled="propConfigFases.publicacaoVaga.aprovacaoTipo.tipoRh === 'usuario'"
                  v-model="propConfigFases.publicacaoVaga.aprovacaoTipo.tipoAcesso"
                >
                  <el-option
                    v-for="tipo in tipoAcesso"
                    :key="tipo.value"
                    :label="tipo.text"
                    :value="tipo.value">
                  </el-option>
                </el-select>
              </div>
              <div class="col-3">
                <label for="usuarioRh">Usuário do RH:</label>
                <el-select
                  ref="usuarioRh"
                  name="Usuário do RH:"
                  class="select-primary"
                  id="usuarioRh"
                  :disabled="propConfigFases.publicacaoVaga.aprovacaoTipo.tipoRh === 'area'"
                  v-model="propConfigFases.publicacaoVaga.aprovacaoTipo.usuario"
                >
                  <el-option
                    v-for="user in propUsuarios"
                    :key="user.id_empresa_user"
                    :label="user.nm_nome"
                    :value="user.id_empresa_user">
                  </el-option>
                </el-select>
              </div>
            </div>
          </collapse-item>
          <collapse-item title="Triagem">
            <div class="row my-1">
              <!-- Nome da Fase -->
              <div class="col-3">
                <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
                <input
                  id="nomepublicacaoVaga"
                  ref="nomepublicacaoVaga"
                  type="text"
                  class="form-control"
                  name="Nome da Fase"
                  v-model="propConfigFases.triagem.text"
                />
              </div>
              <!-- Flag Fase ativa -->
              <div class="col-2 text-center">
                <p>Fase Ativa<br>
                  <base-switch
                    v-model="propConfigFases.triagem.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Obrigatória -->
              <div class="col-2 text-center">
                <p>Obrigatória<br>
                  <base-switch
                    v-model="propConfigFases.triagem.required"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
            <div class="row my-1">
              <!-- Flag Idiomas -->
              <div class="col-2 text-center">
                <p>Idiomas<br>
                  <base-switch
                    v-model="propConfigFases.triagem.idiomas.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Certificações -->
              <div class="col-2 text-center">
                <p>Certificações<br>
                  <base-switch
                    v-model="propConfigFases.triagem.certificacao.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Competências -->
              <div class="col-2 text-center">
                <p>Competências<br>
                  <base-switch
                    v-model="propConfigFases.triagem.competencias.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Formações -->
              <div class="col-2 text-center">
                <p>Formações<br>
                  <base-switch
                    v-model="propConfigFases.triagem.formacao.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Localização -->
              <div class="col-2 text-center">
                <p>Localização<br>
                  <base-switch
                    v-model="propConfigFases.triagem.localizacao.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Tempo de Experiência -->
              <div class="col-2 text-center">
                <p>Tempo de Experiência<br>
                  <base-switch
                    v-model="propConfigFases.triagem.tempoExperiencia.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
            <div class="row my-1">
              <!-- Flag Pretensão Salarial -->
              <div class="col-2 text-center">
                <p>Pretensão Salarial<br>
                  <base-switch
                    v-model="propConfigFases.triagem.pretensaoSalarial.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <div class="col-3">
                <label>Faixa de Aceitação</label>
                <el-select
                  required
                  class="select-primary"
                  v-model="propConfigFases.triagem.pretensaoSalarial.faixa"
                  placeholder="Faixa de Aceitação"
                  :disabled="!propConfigFases.triagem.pretensaoSalarial.ativo"
                >
                  <el-option
                    v-for="tipo in faixaSalarial"
                    :key="tipo.value"
                    :label="tipo.label"
                    :value="tipo.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </collapse-item>
          <collapse-item title="Testes de Perfil">
            <div class="row my-1">
              <!-- Nome da Fase -->
              <div class="col-3">
                <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
                <input
                  id="nomepublicacaoVaga"
                  ref="nomepublicacaoVaga"
                  type="text"
                  class="form-control"
                  name="Nome da Fase"
                  v-model="propConfigFases.testePerfil.text"
                />
              </div>
              <!-- Flag Fase ativa -->
              <div class="col-2 text-center">
                <p>Fase Ativa<br>
                  <base-switch
                    v-model="propConfigFases.testePerfil.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Obrigatória -->
              <div class="col-2 text-center">
                <p>Obrigatória<br>
                  <base-switch
                    v-model="propConfigFases.testePerfil.required"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- ID do Test de Perfil -->
              <div class="col-2">
                <label for="idtesteperfil">ID do Teste de Perfil <span class="text-danger">*</span></label>
                <input
                  id="idtesteperfil"
                  ref="idtesteperfil"
                  type="text"
                  class="form-control"
                  name="ID do Test de Perfil"
                  v-model="propConfigFases.testePerfil.id_teste"
                />
              </div>
              <!-- Origem do Test de Perfil -->
              <div class="col-2">
                <label for="origemtesteperfil">Origem do Teste <span class="text-danger">*</span></label>
                <input
                  id="origemtesteperfil"
                  ref="origemtesteperfil"
                  type="text"
                  class="form-control"
                  name="ID do Test de Perfil"
                  v-model="propConfigFases.testePerfil.fl_origem_teste"
                />
              </div>
            </div>
          </collapse-item>
          <collapse-item title="Envio de Testes">
            <div class="row my-1">
              <!-- Nome da Fase -->
              <div class="col-3">
                <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
                <input
                  id="nomepublicacaoVaga"
                  ref="nomepublicacaoVaga"
                  type="text"
                  class="form-control"
                  name="Nome da Fase"
                  v-model="propConfigFases.enviaTestes.text"
                />
              </div>
              <!-- Flag Fase ativa -->
              <div class="col-2 text-center">
                <p>Fase Ativa<br>
                  <base-switch
                    v-model="propConfigFases.enviaTestes.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Obrigatória -->
              <div class="col-2 text-center">
                <p>Obrigatória<br>
                  <base-switch
                    v-model="propConfigFases.enviaTestes.required"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Testes Externos -->
              <div class="col-2 text-center">
                <p>Testes Externos<br>
                  <base-switch
                    v-model="propConfigFases.enviaTestes.testesExternos"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Testes Definidos -->
              <div class="col-3">
                <template v-if="propConfigFases.enviaTestes.testesPadrao.length < 1">
                  Nenhum Teste definido
                </template>
                <template v-else>
                  <span v-for="teste in propConfigFases.enviaTestes.testesPadrao" :key="teste.id_teste">{{teste.nm_nome}}<br></span>
                </template>
              </div>
            </div>
          </collapse-item>
          <collapse-item title="Entrevistas">
            <div class="row my-1">
              <!-- Nome da Fase -->
              <div class="col-3">
                <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
                <input
                  id="nomepublicacaoVaga"
                  ref="nomepublicacaoVaga"
                  type="text"
                  class="form-control"
                  name="Nome da Fase"
                  v-model="propConfigFases.entrevistas.text"
                />
              </div>
              <!-- Flag Fase ativa -->
              <div class="col-2 text-center">
                <p>Fase Ativa<br>
                  <base-switch
                    v-model="propConfigFases.entrevistas.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Obrigatória -->
              <div class="col-2 text-center">
                <p>Obrigatória<br>
                  <base-switch
                    v-model="propConfigFases.entrevistas.required"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
          </collapse-item>
          <collapse-item title="Dinâmicas">
            <div class="row my-1">
              <!-- Nome da Fase -->
              <div class="col-3">
                <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
                <input
                  id="nomepublicacaoVaga"
                  ref="nomepublicacaoVaga"
                  type="text"
                  class="form-control"
                  name="Nome da Fase"
                  v-model="propConfigFases.dinamica.text"
                />
              </div>
              <!-- Flag Fase ativa -->
              <div class="col-2 text-center">
                <p>Fase Ativa<br>
                  <base-switch
                    v-model="propConfigFases.dinamica.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Obrigatória -->
              <div class="col-2 text-center">
                <p>Obrigatória<br>
                  <base-switch
                    v-model="propConfigFases.dinamica.required"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
          </collapse-item>
          <collapse-item title="Validação de Dados">
            <div class="row my-1">
              <!-- Nome da Fase -->
              <div class="col-3">
                <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
                <input
                  id="nomepublicacaoVaga"
                  ref="nomepublicacaoVaga"
                  type="text"
                  class="form-control"
                  name="Nome da Fase"
                  v-model="propConfigFases.validacaoDados.text"
                />
              </div>
              <!-- Flag Fase ativa -->
              <div class="col-2 text-center">
                <p>Fase Ativa<br>
                  <base-switch
                    v-model="propConfigFases.validacaoDados.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Obrigatória -->
              <div class="col-2 text-center">
                <p>Obrigatória<br>
                  <base-switch
                    v-model="propConfigFases.validacaoDados.required"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
          </collapse-item>
          <collapse-item title="Negociação">
            <div class="row my-1">
              <!-- Nome da Fase -->
              <div class="col-3">
                <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
                <input
                  id="nomepublicacaoVaga"
                  ref="nomepublicacaoVaga"
                  type="text"
                  class="form-control"
                  name="Nome da Fase"
                  v-model="propConfigFases.negociacao.text"
                />
              </div>
              <!-- Flag Fase ativa -->
              <div class="col-2 text-center">
                <p>Fase Ativa<br>
                  <base-switch
                    v-model="propConfigFases.negociacao.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Obrigatória -->
              <div class="col-2 text-center">
                <p>Obrigatória<br>
                  <base-switch
                    v-model="propConfigFases.negociacao.required"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
          </collapse-item>
          <collapse-item title="Contratação">
            <div class="row my-1">
              <!-- Nome da Fase -->
              <div class="col-3">
                <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
                <input
                  id="nomepublicacaoVaga"
                  ref="nomepublicacaoVaga"
                  type="text"
                  class="form-control"
                  name="Nome da Fase"
                  v-model="propConfigFases.contratacao.text"
                />
              </div>
              <!-- Flag Fase ativa -->
              <div class="col-2 text-center">
                <p>Fase Ativa<br>
                  <base-switch
                    v-model="propConfigFases.contratacao.ativo"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Flag Obrigatória -->
              <div class="col-2 text-center">
                <p>Obrigatória<br>
                  <base-switch
                    v-model="propConfigFases.contratacao.required"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
          </collapse-item>
        </collapse>
      </collapse-item>
      <collapse-item title="Processos Existentes">
        <collapse :multiple-active="true">
          <collapse-item v-for="processo in propProcessosSeletivos.processo" :title="`Processo #${processo.id} - ${processo.nm_nome}`" :key="processo.id">
            <div class="row my-1">
              <div class="col-lg-3 align-self-center">
                <p class="font-weight-normal mb-0"><small>Nome:</small><br><strong>{{processo.nm_nome}}</strong> <el-tooltip
                  content="Workflow padrão"
                  placement="top"
                ><i class="text-warning fas fa-certificate" v-if="processo.fl_default"> </i>
                </el-tooltip></p>
              </div>
              <div class="col-lg-2 align-self-center text-center">
                <p class="font-weight-normal mb-0"><small>Fases configuradas:</small><br><strong>{{processo.fases.length}}</strong></p>
              </div>
              <div class="col-lg-3 align-self-center text-center">
                <p class="font-weight-normal mb-0"><small>Competências Avaliadas:</small><br><strong>{{processo.competencias.length}}</strong></p>
              </div>
              <!-- Data -->
              <div class="col-lg-2 align-self-center text-center">
                <p class="font-weight-light mb-0">
                  <small>
                    <!-- Criado em: <strong>{{processo.created_at | formatDateTime}}</strong><br> -->
                    Última atualização:<br><strong>{{processo.updated_at | formatDateTime}}</strong>
                  </small>
                </p>
              </div>
              <!-- Coluna dos botões de ação -->
              <div class="col-lg-2 align-self-center text-right">
                <base-button type="default" class="mx-2" size="sm" @click="viewJsonProsseco(processo.id)">View JSON</base-button>
              </div>
            </div>
            <collapse :multiple-active="true">
              <collapse-item v-for="fase in processo.fases" :title="`${fase.id}ª Fase - ${fase.value}`" :key="fase.id">
                <PublicacaoVaga
                  v-if="fase.tipo === 'publicacaoVaga'"
                  :propPublicacaoVaga="fase"
                  :propUsuarios="propUsuarios"
                />
                <Triagem
                  v-if="fase.tipo === 'triagem'"
                  :propTriagem="fase"
                />
                <TestePerfil
                  v-if="fase.tipo === 'testePerfil'"
                  :propTestePerfil="fase"
                />
                <EnviaTestes
                  v-if="fase.tipo === 'enviaTestes'"
                  :propEnviaTestes="fase"
                />
                <Entrevistas
                  v-if="fase.tipo === 'entrevistas'"
                  :propEntrevistas="fase"
                />
              </collapse-item>
            </collapse>
          </collapse-item>
        </collapse>
      </collapse-item>
    </collapse>
    <!-- JSON do ConfigFases -->
    <template v-if="flViewJsonConfig">
      <div class="row"><div class="col-lg-1">
        <base-button @click="flViewJsonConfig = false" type="primary" icon size="sm" class="btn-link"><i class="tim-icons icon-simple-remove"></i></base-button>
      </div></div>
      <div class="row">
        <div class="col-lg-12">
          <JsonEditorVue
            v-model="propConfigFases"
            v-bind="{/* local props & attrs */}"
          />
        </div>
      </div>
    </template>
    <!-- JSON dos Processos Seletivos -->
    <template v-if="flViewJsonPs">
      <div class="row"><div class="col-lg-1">
        <base-button @click="flViewJsonPs = false" type="primary" icon size="sm" class="btn-link"><i class="tim-icons icon-simple-remove"></i></base-button>
      </div></div>
      <div class="row">
        <div class="col-lg-12">
          <JsonEditorVue
            v-model="propProcessosSeletivos"
            v-bind="{/* local props & attrs */}"
          />
        </div>
      </div>
    </template>
    <!-- JSON do Processo selecionado -->
    <template v-if="flProcessoSelecionado">
      <div class="row"><div class="col-lg-1">
        <base-button @click="flProcessoSelecionado = false" type="primary" icon size="sm" class="btn-link"><i class="tim-icons icon-simple-remove"></i></base-button>
      </div></div>
      <div class="row">
        <div class="col-lg-12">
          <JsonEditorVue
            v-model="processoSelecionado"
            v-bind="{/* local props & attrs */}"
          />
        </div>
      </div>
    </template>
    <!-- <div class="row">
      <div class="col-lg-6">
        <pre>processoSelecionado: {{ processoSelecionado }}</pre>
      </div>
      <div class="col-lg-6">
        <pre>propProcessosSeletivos: {{ propProcessosSeletivos }}</pre>
      </div>
      <div class="col-lg-6">
        <pre>propConfigFases: {{ propConfigFases }}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue'
import VCA from '@vue/composition-api'
import JsonEditorVue from 'json-editor-vue'
import { adminApiUrl } from "@/global";
import { Badge, Collapse, CollapseItem, BaseSwitch, Modal } from "src/components";
import { Select, Option } from 'element-ui';
import moment from "moment";

import PublicacaoVaga from './FasesProcesso/PublicacaoVaga.vue';
import Triagem from './FasesProcesso/Triagem.vue';
import TestePerfil from './FasesProcesso/TestePerfil.vue';
import EnviaTestes from './FasesProcesso/EnviaTestes.vue';
import Entrevistas from './FasesProcesso/Entrevistas.vue';

Vue.use(VCA)

export default {
  name: "ProcessoSeletivo",
  props: {
    propConfigFases: { type: Object },
    propProcessosSeletivos: { type: Object },
    propUsuarios: { type: Array }
  },
  components: {
    Badge,
    Collapse,
    CollapseItem,
    BaseSwitch,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    JsonEditorVue,
    PublicacaoVaga,
    Triagem,
    TestePerfil,
    EnviaTestes,
    Entrevistas
  },
  data() {
    return {
      configPadrao: {
        triagem: {
          text: "Triagem de Currículos",
          ativo: true,
          required: true,
          idiomas: {
            ativo: true
          },
          formacao: {
            ativo: true
          },
          localizacao: {
            ativo: false
          },
          certificacao: {
            ativo: true
          },
          competencias: {
            ativo: true
          },
          tempoExperiencia: {
            ativo: true
          },
          pretensaoSalarial: {
            ativo: true,
            faixa: "3"
          }
        },
        dinamica: {
          text: "Dinâmica",
          ativo: true,
          required: false
        },
        negociacao: {
          text: "Negociação",
          ativo: true,
          required: false
        },
        contratacao: {
          text: "Contratação",
          ativo: true,
          required: true
        },
        entrevistas: {
          text: "Entrevistas",
          ativo: true,
          required: false
        },
        enviaTestes: {
          text: "Envio de Testes",
          ativo: true,
          required: false,
          testesPadrao: [],
          testesExternos: false
        },
        testePerfil: {
          text: "Teste de Perfil",
          ativo: false,
          id_teste: "",
          required: false,
          fl_origem_teste: "1"
        },
        publicacaoVaga: {
          text: "Publicação da Vaga",
          ativo: true,
          required: true,
          aprovacaoTipo: {
            tipoRh: "area",
            usuario: "",
            tipoAcesso: "3"
          },
          requerAprovacao: true,
          solicitanteAprova: false
        },
        validacaoDados: {
          text: "Validação de Dados",
          ativo: false,
          required: false
        },
        reValidacaoMatch: {
          text: "Validação de Dados",
          ativo: false,
          required: false
        }
      },
      psPadrao: {
        processo: [
          {
            id: 1,
            fases: [
              {
                id: 1,
                tab: true,
                tipo: "publicacaoVaga",
                value: "Publicação da Vaga",
                configs: {
                  text: "Publicação da Vaga",
                  ativo: true,
                  required: true,
                  aprovacaoTipo: {
                    tipoRh: "area",
                    usuario: "",
                    tipoAcesso: "3"
                  },
                  requerAprovacao: false,
                  solicitanteAprova: false
                },
                required: true
              },
              {
                id: 2,
                tab: false,
                tipo: "triagem",
                value: "Triagem de Currículos",
                configs: {
                  text: "Triagem de Currículos",
                  ativo: true,
                  idiomas: {
                    ativo: true
                  },
                  formacao: {
                    ativo: true
                  },
                  required: true,
                  localizacao: {
                    ativo: true
                  },
                  certificacao: {
                    ativo: true
                  },
                  competencias: {
                    ativo: true
                  },
                  tempoExperiencia: {
                    ativo: true
                  },
                  pretensaoSalarial: {
                    ativo: true,
                    faixa: "3"
                  }
                },
                required: true
              },
              {
                id: 3,
                tab: false,
                tipo: "entrevistas",
                value: "Entrevista com o RH",
                configs: {
                  entrevistaRH: true,
                  entrevistaSolicitante: false
                },
                required: false
              },
              {
                id: 4,
                tab: false,
                tipo: "entrevistas",
                value: "Entrevista com o Gestor",
                configs: {
                  entrevistaRH: false,
                  entrevistaSolicitante: false
                },
                required: false
              },
              {
                id: 5,
                tab: false,
                tipo: "negociacao",
                value: "Negociação",
                required: false
              },
              {
                id: 6,
                tab: false,
                tipo: "contratacao",
                value: "Contratação",
                required: true
              }
            ],
            fl_tipo: "simples",
            nm_nome: "Processo Padrão - Sem aprovação",
            created_at: "2022-07-07 20:29",
            fl_default: true,
            updated_at: "2022-07-14 13:21",
            competencias: [
              "Conhecimento Técnico",
              "Formações",
              "Certificações",
              "Idiomas",
              "Experiência"
            ]
          }
        ]
      },
      tipoAcesso: [
        { value: '2', text: "Gerência de RH" },
        { value: '3', text: "Coordenação de RH" },
        { value: '4', text: "Recrutadora de RH" }
      ],
      faixaSalarial: [
        { value: "1", label: "Menor ou Igual" },
        { value: "2", label: "5% (a mais ou a menos)" },
        { value: "3", label: "10% (a mais ou a menos)" },
        { value: "4", label: "15% (a mais ou a menos)" },
        { value: "5", label: "20% (a mais ou a menos)" },
        { value: "6", label: "25% (a mais ou a menos)" },
      ],
      flWatcherConfigFases: false,
      flWatcherProcessos: false,
      flbutConfigFases: true,
      flbutProcessos: true,
      flLoaded: false,
      flViewJsonConfig: false,
      flViewJsonPs: false,
      errosConfigFases: {
        geral: [],
        triagem: [],
        dinamica: [],
        negociacao: [],
        contratacao: [],
        entrevistas: [],
        enviaTestes: [],
        testePerfil: [],
        publicacaoVaga: [],
        validacaoDados: [],
        reValidacaoMatch: []
      },
      flErros: false,
      processoSelecionado: null,
      flProcessoSelecionado: false,
    }
  },
  methods: {
    async atualizaConfigFases() {
      const data = {
        id_empresa: this.$route.params.id,
        json_config_fase_ps: structuredClone(this.propConfigFases)
      }
      await this.$http
        .post(`${adminApiUrl}/empresa/atualizaconfigfases`, data)
        .then(() => {
          this.notificacao("Configurações atualizadas!", 'success')
          this.flSave = false;
        }
        ).catch(err => {
          this.notificacao("Falha na atualização!", 'dangar')
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    async atualizaProcessosSeletivos() {
      const data = {
        id_empresa: this.$route.params.id,
        json_processo_selecao: structuredClone(this.propProcessosSeletivos)
      }
      await this.$http
        .post(`${adminApiUrl}/empresa/atualizaprocessos `, data)
        .then(() => {
          this.notificacao("Configurações atualizadas!", 'success')
          this.flSave = false;
        }
        ).catch(err => {
          this.notificacao("Falha na atualização!", 'dangar')
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    async addProcessosPadrao() {
      if(confirm('Tem certeza que deseja adicionar?')) {
        const data = {
          id_empresa: this.$route.params.id,
          json_processo_selecao: this.psPadrao
        }
        await this.$http
          .post(`${adminApiUrl}/empresa/atualizaprocessos `, data)
          .then(() => {
            this.notificacao("Configurações atualizadas!", 'success')
            this.flSave = false;
          }
          ).catch(err => {
            this.notificacao("Falha na atualização!", 'dangar')
            console.log('Erro na API -> ', err);
            return false;
          });
      }
    },
    async addConfigFasesPadrao() {
      if (confirm('Tem certeza que deseja adicionar?')) {
        const data = {
          id_empresa: this.$route.params.id,
          json_config_fase_ps: this.configPadrao
        }
        await this.$http
          .post(`${adminApiUrl}/empresa/atualizaconfigfases`, data)
          .then(() => {
            this.notificacao("Configurações atualizadas!", 'success')
            this.flSave = false;
          }
          ).catch(err => {
            this.notificacao("Falha na atualização!", 'dangar')
            console.log('Erro na API -> ', err);
            return false;
          });
      }
    },
    viewJson(value) {
      if (value === 'config') {
        this.flLoaded = false;
        this.flViewJsonConfig = true;
        this.flViewJsonPs = false;
        this.flProcessoSelecionado = false;
      } else if (value === 'processos') {
        this.flLoaded = false;
        this.flViewJsonConfig = false;
        this.flViewJsonPs = true;
        this.flProcessoSelecionado = false;
      }
    },
    validaJsonConfigFasesGeral() {
      this.limpaErrosConfigFases();
      const geral = Object.keys(this.propConfigFases)
      if (geral.length < 10) {
        this.notificacao('Erro encontrado no JSON Geral do ConfigFases', 'warning');
        let geralKeys = ['triagem', 'dinamica', 'negociacao', 'contratacao', 'entrevistas', 'enviaTestes', 'testePerfil', 'publicacaoVaga', 'validacaoDados', 'reValidacaoMatch'];
        for(var i = 0; i < geralKeys.length; i++) {
          let idx = geral.find(e => e === geralKeys[i]);
          if (idx === undefined) {
            this.errosConfigFases.geral.push(`${geralKeys[i]} -> Chave faltante`)
          }
        }
      } else if (geral.length > 10) {
        this.notificacao('Erro encontrado no JSON Geral do ConfigFases', 'warning');
        this.errosConfigFases.geral.push('Excesso de chaves encontradas no geral da ConfigFases')
      } else {
        if (typeof this.propConfigFases.triagem === 'object') {
          const triagem = Object.keys(this.propConfigFases.triagem)
          if (triagem.length < 10) {
            let triagemKeys = ['text', 'ativo', 'idiomas', 'formacao', 'required', 'localizacao', 'certificacao', 'competencias', 'tempoExperiencia', 'pretensaoSalarial'];
            for(var i = 0; i < triagemKeys.length; i++) {
              let idx = triagem.find(e => e === triagemKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.triagem.push(`${triagemKeys[i]} -> Chave faltante`)
              }
            }
          } else if (triagem.length > 10) {
            this.errosConfigFases.triagem.push('Excesso de chaves encontradas na triagem da ConfigFases')
          } else {
            let triagemKeys = ['text', 'ativo', 'idiomas', 'formacao', 'required', 'localizacao', 'certificacao', 'competencias', 'tempoExperiencia', 'pretensaoSalarial'];
            for(var i = 0; i < triagemKeys.length; i++) {
              let idx = triagem.find(e => e === triagemKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.triagem.push(`${triagemKeys[i]} -> Chave faltante`)
                for(var o = 0; o < triagem.length; o++) {
                  let outra = triagemKeys.find(b => b === triagem[o])
                  if(outra === undefined) {
                    this.errosConfigFases.triagem.push(`${triagem[o]} -> Chave incorreta`)
                  }
                }
              }
            }
          }
        } else if (this.propConfigFases.triagem === undefined) {
          this.errosConfigFases.triagem.push('Chave triagem da ConfigFases não localizada')
        } else {
          this.errosConfigFases.triagem.push('Chave triagem da ConfigFases com tipo incorreto')
        }
        if (this.propConfigFases.triagem.idiomas.ativo === undefined) {
          this.errosConfigFases.triagem.push('Falta chave -> triagem.idiomas.ativo -> ConfigFases')
        }
        if (this.propConfigFases.triagem.formacao.ativo === undefined) {
          this.errosConfigFases.triagem.push('Falta chave -> triagem.formacao.ativo -> ConfigFases')
        }
        if (this.propConfigFases.triagem.localizacao.ativo === undefined) {
          this.errosConfigFases.triagem.push('Falta chave -> triagem.localizacao.ativo -> ConfigFases')
        }
        if (this.propConfigFases.triagem.certificacao.ativo === undefined) {
          this.errosConfigFases.triagem.push('Falta chave -> triagem.certificacao.ativo -> ConfigFases')
        }
        if (this.propConfigFases.triagem.competencias.ativo === undefined) {
          this.errosConfigFases.triagem.push('Falta chave -> triagem.competencias.ativo -> ConfigFases')
        }
        if (this.propConfigFases.triagem.tempoExperiencia.ativo === undefined) {
          this.errosConfigFases.triagem.push('Falta chave -> triagem.tempoExperiencia.ativo -> ConfigFases')
        }
        if (this.propConfigFases.triagem.pretensaoSalarial.ativo === undefined) {
          this.errosConfigFases.triagem.push('Falta chave -> triagem.pretensaoSalarial.ativo -> ConfigFases')
        }
        if (typeof this.propConfigFases.dinamica === 'object') {
          const dinamica = Object.keys(this.propConfigFases.dinamica)
          if (dinamica.length < 3) {
            let dinamicaKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < dinamicaKeys.length; i++) {
              let idx = dinamica.find(e => e === dinamicaKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.dinamica.push(`${dinamicaKeys[i]} -> Chave faltante`)
              }
            }
          } else if (dinamica.length > 3) {
            this.errosConfigFases.dinamica.push('Excesso de chaves encontradas na dinamica da ConfigFases')
          } else {
            let dinamicaKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < dinamicaKeys.length; i++) {
              let idx = dinamica.find(e => e === dinamicaKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.dinamica.push(`${dinamicaKeys[i]} -> Chave faltante`)
                for(var o = 0; o < dinamica.length; o++) {
                  let outra = dinamicaKeys.find(b => b === dinamica[o])
                  if(outra === undefined) {
                    this.errosConfigFases.dinamica.push(`${dinamica[o]} -> Chave incorreta`)
                  }
                }
              }
            }
          }
        } else if (this.propConfigFases.dinamica === undefined) {
          this.errosConfigFases.dinamica.push('Chave dinamica da ConfigFases não localizada')
        } else {
          this.errosConfigFases.dinamica.push('Chave dinamica da ConfigFases com tipo incorreto')
        }
        if (typeof this.propConfigFases.negociacao === 'object') {
          const negociacao = Object.keys(this.propConfigFases.negociacao)
          if (negociacao.length < 3) {
            let negociacaoKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < negociacaoKeys.length; i++) {
              let idx = negociacao.find(e => e === negociacaoKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.negociacao.push(`${negociacaoKeys[i]} -> Chave faltante`)
              }
            }
          } else if (negociacao.length > 3) {
            this.errosConfigFases.negociacao.push('Excesso de chaves encontradas na negociacao da ConfigFases')
          } else {
            let negociacaoKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < negociacaoKeys.length; i++) {
              let idx = negociacao.find(e => e === negociacaoKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.negociacao.push(`${negociacaoKeys[i]} -> Chave faltante`)
                for(var o = 0; o < negociacao.length; o++) {
                  let outra = negociacaoKeys.find(b => b === negociacao[o])
                  if(outra === undefined) {
                    this.errosConfigFases.negociacao.push(`${negociacao[o]} -> Chave incorreta`)
                  }
                }
              }
            }
          }
        } else if (this.propConfigFases.negociacao === undefined) {
          this.errosConfigFases.negociacao.push('Chave negociacao da ConfigFases não localizada')
        } else {
          this.errosConfigFases.negociacao.push('Chave negociacao da ConfigFases com tipo incorreto')
        }
        if (typeof this.propConfigFases.contratacao === 'object') {
          const contratacao = Object.keys(this.propConfigFases.contratacao)
          if (contratacao.length < 3) {
            let contratacaoKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < contratacaoKeys.length; i++) {
              let idx = contratacao.find(e => e === contratacaoKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.contratacao.push(`${contratacaoKeys[i]} -> Chave faltante`)
              }
            }
          } else if (contratacao.length > 3) {
            this.errosConfigFases.contratacao.push('Excesso de chaves encontradas na contratacao da ConfigFases')
          } else {
            let contratacaoKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < contratacaoKeys.length; i++) {
              let idx = contratacao.find(e => e === contratacaoKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.contratacao.push(`${contratacaoKeys[i]} -> Chave faltante`)
                for(var o = 0; o < contratacao.length; o++) {
                  let outra = contratacaoKeys.find(b => b === contratacao[o])
                  if(outra === undefined) {
                    this.errosConfigFases.contratacao.push(`${contratacao[o]} -> Chave incorreta`)
                  }
                }
              }
            }
          }
        } else if (this.propConfigFases.contratacao === undefined) {
          this.errosConfigFases.contratacao.push('Chave contratacao da ConfigFases não localizada')
        } else {
          this.errosConfigFases.contratacao.push('Chave contratacao da ConfigFases com tipo incorreto')
        }
        if (typeof this.propConfigFases.entrevistas === 'object') {
          const entrevistas = Object.keys(this.propConfigFases.entrevistas)
          if (entrevistas.length < 3) {
            let entrevistasKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < entrevistasKeys.length; i++) {
              let idx = entrevistas.find(e => e === entrevistasKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.entrevistas.push(`${entrevistasKeys[i]} -> Chave faltante`)
              }
            }
          } else if (entrevistas.length > 3) {
            this.errosConfigFases.entrevistas.push('Excesso de chaves encontradas na entrevistas da ConfigFases')
          } else {
            let entrevistasKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < entrevistasKeys.length; i++) {
              let idx = entrevistas.find(e => e === entrevistasKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.entrevistas.push(`${entrevistasKeys[i]} -> Chave faltante`)
                for(var o = 0; o < entrevistas.length; o++) {
                  let outra = entrevistasKeys.find(b => b === entrevistas[o])
                  if(outra === undefined) {
                    this.errosConfigFases.entrevistas.push(`${entrevistas[o]} -> Chave incorreta`)
                  }
                }
              }
            }
          }
        } else if (this.propConfigFases.entrevistas === undefined) {
          this.errosConfigFases.entrevistas.push('Chave entrevistas da ConfigFases não localizada')
        } else {
          this.errosConfigFases.entrevistas.push('Chave entrevistas da ConfigFases com tipo incorreto')
        }
        if (typeof this.propConfigFases.enviaTestes === 'object') {
          const enviaTestes = Object.keys(this.propConfigFases.enviaTestes)
          if (enviaTestes.length < 5) {
            let enviaTestesKeys = ['text', 'ativo', 'required', 'testesPadrao', 'testesExternos'];
            for(var i = 0; i < enviaTestesKeys.length; i++) {
              let idx = enviaTestes.find(e => e === enviaTestesKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.enviaTestes.push(`${enviaTestesKeys[i]} -> Chave faltante`)
              }
            }
          } else if (enviaTestes.length > 5) {
            this.errosConfigFases.enviaTestes.push('Excesso de chaves encontradas na enviaTestes da ConfigFases')
          } else {
            let enviaTestesKeys = ['text', 'ativo', 'required', 'testesPadrao', 'testesExternos'];
            for(var i = 0; i < enviaTestesKeys.length; i++) {
              let idx = enviaTestes.find(e => e === enviaTestesKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.enviaTestes.push(`${enviaTestesKeys[i]} -> Chave faltante`)
                for(var o = 0; o < enviaTestes.length; o++) {
                  let outra = enviaTestesKeys.find(b => b === enviaTestes[o])
                  if(outra === undefined) {
                    this.errosConfigFases.enviaTestes.push(`${enviaTestes[o]} -> Chave incorreta`)
                  }
                }
              }
            }
          }
        } else if (this.propConfigFases.enviaTestes === undefined) {
          this.errosConfigFases.enviaTestes.push('Chave enviaTestes da ConfigFases não localizada')
        } else {
          this.errosConfigFases.enviaTestes.push('Chave enviaTestes da ConfigFases com tipo incorreto')
        }
        if (typeof this.propConfigFases.testePerfil === 'object') {
          const testePerfil = Object.keys(this.propConfigFases.testePerfil)
          if (testePerfil.length < 5) {
            let testePerfilKeys = ['text', 'ativo', 'id_teste', 'required', 'fl_origem_teste'];
            for(var i = 0; i < testePerfilKeys.length; i++) {
              let idx = testePerfil.find(e => e === testePerfilKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.testePerfil.push(`${testePerfilKeys[i]} -> Chave faltante`)
              }
            }
          } else if (testePerfil.length < 5) {
            this.errosConfigFases.testePerfil.push('Excesso de chaves encontradas na testePerfil da ConfigFases')
          } else {
            let testePerfilKeys = ['text', 'ativo', 'id_teste', 'required', 'fl_origem_teste'];
            for(var i = 0; i < testePerfilKeys.length; i++) {
              let idx = testePerfil.find(e => e === testePerfilKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.testePerfil.push(`${testePerfilKeys[i]} -> Chave faltante`)
                for(var o = 0; o < testePerfil.length; o++) {
                  let outra = testePerfilKeys.find(b => b === testePerfil[o])
                  if(outra === undefined) {
                    this.errosConfigFases.testePerfil.push(`${testePerfil[o]} -> Chave incorreta`)
                  }
                }
              }
            }
          }
        } else if (this.propConfigFases.testePerfil === undefined) {
          this.errosConfigFases.testePerfil.push('Chave testePerfil da ConfigFases não localizada')
        } else {
          this.errosConfigFases.testePerfil.push('Chave testePerfil da ConfigFases com tipo incorreto')
        }
        if (typeof this.propConfigFases.publicacaoVaga === 'object') {
          const publicacaoVaga = Object.keys(this.propConfigFases.publicacaoVaga)
          if (publicacaoVaga.length < 6) {
            let publicacaoVagaKeys = ['text', 'ativo', 'required', 'aprovacaoTipo', 'requerAprovacao', 'solicitanteAprova'];
            for(var i = 0; i < publicacaoVagaKeys.length; i++) {
              let idx = publicacaoVaga.find(e => e === publicacaoVagaKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.publicacaoVaga.push(`${publicacaoVagaKeys[i]} -> Chave faltante`)
              }
            }
          } else if (publicacaoVaga.length > 6) {
            this.errosConfigFases.publicacaoVaga.push('Excesso de chaves encontradas na publicacaoVaga da ConfigFases')
          } else {
            let publicacaoVagaKeys = ['text', 'ativo', 'required', 'aprovacaoTipo', 'requerAprovacao', 'solicitanteAprova'];
            for(var i = 0; i < publicacaoVagaKeys.length; i++) {
              let idx = publicacaoVaga.find(e => e === publicacaoVagaKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.publicacaoVaga.push(`${publicacaoVagaKeys[i]} -> Chave faltante`)
                for(var o = 0; o < publicacaoVaga.length; o++) {
                  let outra = publicacaoVagaKeys.find(b => b === publicacaoVaga[o])
                  if(outra === undefined) {
                    this.errosConfigFases.publicacaoVaga.push(`${publicacaoVaga[o]} -> Chave incorreta`)
                  }
                }
              }
            }
          }
        } else if (this.propConfigFases.publicacaoVaga === undefined) {
          this.errosConfigFases.publicacaoVaga.push('Chave publicacaoVaga da ConfigFases não localizada')
        } else {
          this.errosConfigFases.publicacaoVaga.push('Chave publicacaoVaga da ConfigFases com tipo incorreto')
        }
        if (typeof this.propConfigFases.validacaoDados === 'object') {
          const validacaoDados = Object.keys(this.propConfigFases.validacaoDados)
          if (validacaoDados.length < 3) {
            let validacaoDadosKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < validacaoDadosKeys.length; i++) {
              let idx = validacaoDados.find(e => e === validacaoDadosKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.validacaoDados.push(`${validacaoDadosKeys[i]} -> Chave faltante`)
              }
            }
          } else if (validacaoDados.length > 3) {
            this.errosConfigFases.validacaoDados.push('Excesso de chaves encontradas na validacaoDados da ConfigFases')
          } else {
            let validacaoDadosKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < validacaoDadosKeys.length; i++) {
              let idx = validacaoDados.find(e => e === validacaoDadosKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.validacaoDados.push(`${validacaoDadosKeys[i]} -> Chave faltante`)
                for(var o = 0; o < validacaoDados.length; o++) {
                  let outra = validacaoDadosKeys.find(b => b === validacaoDados[o])
                  if(outra === undefined) {
                    this.errosConfigFases.validacaoDados.push(`${validacaoDados[o]} -> Chave incorreta`)
                  }
                }
              }
            }
          }
        } else if (this.propConfigFases.validacaoDados === undefined) {
          this.errosConfigFases.validacaoDados.push('Chave validacaoDados da ConfigFases não localizada')
        } else {
          this.errosConfigFases.validacaoDados.push('Chave validacaoDados da ConfigFases com tipo incorreto')
        }
        if (typeof this.propConfigFases.reValidacaoMatch === 'object') {
          const reValidacaoMatch = Object.keys(this.propConfigFases.reValidacaoMatch)
          //console.log('reValidacaoMatch -> ',reValidacaoMatch)
          if (reValidacaoMatch.length < 3) {
            let reValidacaoMatchKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < reValidacaoMatchKeys.length; i++) {
              let idx = reValidacaoMatch.find(e => e === reValidacaoMatchKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.reValidacaoMatch.push(`${reValidacaoMatchKeys[i]} -> Chave faltante`)
              }
            }
          } else if (reValidacaoMatch.length > 3) {
            this.errosConfigFases.reValidacaoMatch.push('Excesso de chaves encontradas na reValidacaoMatch da ConfigFases')
          } else {
            let reValidacaoMatchKeys = ['text', 'ativo', 'required'];
            for(var i = 0; i < reValidacaoMatchKeys.length; i++) {
              let idx = reValidacaoMatch.find(e => e === reValidacaoMatchKeys[i]);
              if (idx === undefined) {
                this.errosConfigFases.reValidacaoMatch.push(`${reValidacaoMatchKeys[i]} -> Chave faltante`)
                for(var o = 0; o < reValidacaoMatch.length; o++) {
                  let outra = reValidacaoMatchKeys.find(b => b === reValidacaoMatch[o])
                  if(outra === undefined) {
                    this.errosConfigFases.reValidacaoMatch.push(`${reValidacaoMatch[o]} -> Chave incorreta`)
                  }
                }
              }
            }
          }
        } else if (this.propConfigFases.reValidacaoMatch === undefined) {
          this.errosConfigFases.reValidacaoMatch.push('Chave reValidacaoMatch da ConfigFases não localizada')
        } else {
          this.errosConfigFases.reValidacaoMatch.push('Chave reValidacaoMatch da ConfigFases com tipo incorreto')
        }
      }
    },
    validaJson() {
      //const geral = Object.keys(this.propConfigFases)
      //console.log('geral -> ', geral)
      this.validaJsonConfigFasesGeral();
    },
    viewJsonProsseco(value) {
      this.processoSelecionado = structuredClone(this.propProcessosSeletivos.processo.find(i => i.id === value));
      this.flLoaded = false;
      this.flViewJsonConfig = false;
      this.flViewJsonPs = false;
      this.flProcessoSelecionado = true;
    },
    limpaErrosConfigFases() {
      this.errosConfigFases.geral = []
      this.errosConfigFases.triagem = []
      this.errosConfigFases.dinamica = []
      this.errosConfigFases.negociacao = []
      this.errosConfigFases.contratacao = []
      this.errosConfigFases.entrevistas = []
      this.errosConfigFases.enviaTestes = []
      this.errosConfigFases.testePerfil = []
      this.errosConfigFases.publicacaoVaga = []
      this.errosConfigFases.validacaoDados = []
      this.errosConfigFases.reValidacaoMatch = []
    },
    //Função de notificação
    notificacao(msg, tipo) {
      this.$notify({
        type: tipo,
        message: msg,
        icon: "tim-icons icon-bell-55",
      });
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.flWatcherConfigFases = true;
      this.flWatcherProcessos = true;
    }, 1000);
  },
  watch:{
    propConfigFases: {
      handler(value) {
        if (this.flWatcherConfigFases) {
          this.flbutConfigFases = false;
        }
      },
      deep: true
    },
    propProcessosSeletivos: {
      handler(value) {
        if (this.flWatcherProcessos) {
          this.flbutProcessos = false;
        }
      },
      deep: true
    },
    errosConfigFases: {
      handler(value) {
        if (this.flWatcherConfigFases) {
          //console.log('carregou watch de erros...')
          this.flErros = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style>

</style>
