<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      short-title="ADM"
      title="Admin MatchJob"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'tim-icons icon-chart-pie-36',
            path: '/home/dashboard'
          }"
        >
        </sidebar-item>
        <!-- Candidatos -->
        <sidebar-item
          :link="{
            name: 'Candidatos',
            icon: 'tim-icons icon-single-02' }"
        >
          <sidebar-item
            :link="{ name: 'Dashboard', path: '/candidatos/dashboard' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Listagem', path: '/candidatos/listacandidatos' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Mensagens', path: '/candidatos/mensagens' }"
          ></sidebar-item>
          <!-- <sidebar-item
            :link="{ name: 'Noticias', path: '/candidatos/noticias' }"
          ></sidebar-item> -->
        </sidebar-item>
        <!-- Empresas -->
        <sidebar-item
          :link="{
            name: 'Empresas',
            icon: 'far fa-building' }"
        >
          <sidebar-item
            :link="{ name: 'Listagem', path: '/empresas/listaempresas' }"
          ></sidebar-item>
          <!-- <sidebar-item
            :link="{ name: 'Adicionar', path: '/pages' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Mensagens', path: '/candidatos/mensagens' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Noticias', path: '/candidatos/noticias' }"
          ></sidebar-item> -->
        </sidebar-item>

        <!-- Empresas -->
        <sidebar-item
          :link="{
            name: 'Mensagens',
            icon: 'fas fa-envelope-open-text',
            path: '/mensagens/dashboard'
          }"
        >
        </sidebar-item>
        <!-- Opções -->
        <sidebar-item
          :link="{
            name: 'Opções',
            icon: 'tim-icons icon-settings' }"
        >
          <sidebar-item
            :link="{ name: 'Listagem', path: '/opcoes/lista' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Cargos', path: '/opcoes/cargos' }"
          ></sidebar-item>
          <!-- <sidebar-item
            :link="{ name: 'Certificações', path: '/opcoes/certificacoes' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Competências', path: '/candidatos/mensagens' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Departamentos', path: '/opcoes/departamentos' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Formações', path: '/opcoes/formacoes' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Idiomas', path: '/opcoes/idiomas' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Inst. Ensino', path: '/opcoes/instensino' }"
          ></sidebar-item> -->
        </sidebar-item>

        <!-- Administração do Sistema -->
        <sidebar-item
          :link="{
            name: 'SysAdmin',
            icon: 'tim-icons icon-settings-gear-63' }"
        >
          <sidebar-item
            :link="{ name: 'Listagem', path: '/admin/usuarios' }"
          ></sidebar-item>
          <!-- <sidebar-item
            :link="{ name: 'oAuth Admin', path: '/sysadmin/oauth' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Login Logger', path: '/sysadmin/loginlogger' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Log E-mails', path: '/sysadmin/logemails' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Opções Gerais', path: '/sysadmin/opcoesgerais' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Retorno iAgente', path: '/sysadmin/retornoiagente' }"
          ></sidebar-item> -->
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './SampleNavbar.vue';
import ContentFooter from './SampleFooter.vue';
import SidebarFixedToggleButton from '../Layout/SidebarFixedToggleButton.vue';
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition
  },
  data() {
    return {
      sidebarBackground: 'primary' //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  mounted() {
    this.initScrollbar();
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
