<template>
  <div>
    <div class="row my-1">
      <!-- Nome da Fase -->
      <div class="col-3">
        <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
        <input
          id="nomepublicacaoVaga"
          ref="nomepublicacaoVaga"
          type="text"
          class="form-control"
          name="Nome da Fase"
          v-model="propEnviaTestes.value"
        />
      </div>
      <!-- Flag Obrigatória -->
      <div class="col-2 text-center">
        <label for="tipoTeste">Tipo do Teste <span class="text-danger">*</span></label>
        <el-select
          ref="tipoTeste"
          name="Tipo do Teste"
          class="select-primary"
          id="tipoTeste"
          v-model="propEnviaTestes.tipoTeste"
        >
          <el-option label="Interno" value="interno"></el-option>
          <el-option label="Externo" value="externo"></el-option>
        </el-select>
      </div>
      <!-- Flag Testes Externos -->
      <div class="col-2 text-center">
        <p>Testes Externos<br>
          <base-switch
            v-model="propEnviaTestes.testesExternos"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Testes Definidos -->
      <div class="col-3">
        <template v-if="propEnviaTestes.testesPadrao.length < 1">
          Nenhum Teste definido
        </template>
        <template v-else>
          <span v-for="teste in propEnviaTestes.testesPadrao" :key="teste.id_teste">{{teste.nm_nome}}<br></span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Badge, BaseSwitch, Modal } from "src/components";
import { Select, Option } from 'element-ui';
export default {
  name: "EnviaTestes",
  props: {
    propEnviaTestes: { type: Object }
  },
  components: {
    Badge,
    BaseSwitch,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
    }
  }
}
</script>

<style>

</style>
