<template>
  <div>
    <div class="row"><route-bread-crumb /></div>
    <div class="row">
      <div class="col-12">
        <h1>Listagem Opções</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-button type="primary" class="mr-1" size="sm">Cargos</base-button>
        <base-button type="primary" class="mr-1" size="sm">Certificações</base-button>
        <base-button type="primary" class="mr-1" size="sm">Competências</base-button>
        <base-button type="primary" class="mr-1" size="sm">Departamentos</base-button>
        <base-button type="primary" class="mr-1" size="sm">Formações</base-button>
        <base-button type="primary" class="mr-1" size="sm">Idiomas</base-button>
        <base-button type="primary" class="mr-1" size="sm">Inst. Ensino</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { adminApiUrl } from "@/global";
import { TabPane, Tabs, Badge, Collapse, CollapseItem, BaseSwitch, Modal } from "src/components";
import moment from "moment";
import { Table, TableColumn, Select, Option, Tooltip } from "element-ui";
import { RouteBreadCrumb } from "src/components";
export default {
  name: "ListagemOpcoes",
  components: {
    RouteBreadCrumb,
  }
}
</script>

<style>

</style>
