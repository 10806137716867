<template>
  <div>
    <div class="row my-1">
      <!-- Nome da Fase -->
      <div class="col-3">
        <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
        <input
          id="nomepublicacaoVaga"
          ref="nomepublicacaoVaga"
          type="text"
          class="form-control"
          name="Nome da Fase"
          v-model="propEntrevistas.value"
        />
      </div>
      <!-- Flag Fase ativa -->
      <div class="col-2 text-center">
        <p>Entrevista com RH<br>
          <base-switch
            v-model="propEntrevistas.configs.entrevistaRH"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Obrigatória -->
      <div class="col-2 text-center">
        <p>Entrevista com Solicitante<br>
          <base-switch
            v-model="propEntrevistas.configs.entrevistaSolicitante"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Badge, BaseSwitch, Modal } from "src/components";
import { Select, Option } from 'element-ui';
export default {
  name: "Entrevistas",
  props: {
    propEntrevistas: { type: Object }
  },
  components: {
    Badge,
    BaseSwitch,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
    }
  }
}
</script>

<style>

</style>
