var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('route-bread-crumb')],1),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"card-body-classes":"table-full-width"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Lista de Candidatos")])]),_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"},[_c('el-select',{staticClass:"select-primary mb-3 pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('base-input',[_c('el-input',{staticClass:"mb-3 search-input",attrs:{"type":"search","clearable":"","prefix-icon":"el-icon-search","placeholder":"Buscar...","aria-controls":"datatables"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('el-table',{attrs:{"data":_vm.queriedData}},[_c('el-table-column',{attrs:{"min-width":"50","label":"#","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":row.hl_foto,"alt":"Table image"}})])}}])}),_c('el-table-column',{attrs:{"min-width":"50","prop":"id_candidato","label":"ID"}}),_c('el-table-column',{attrs:{"min-width":"200","prop":"nm_nome","label":"Nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('strong',[_vm._v(_vm._s(row.nm_nome))]),_c('br'),_vm._v(" "+_vm._s(row.hl_email)+" ")])}}])}),_c('el-table-column',{attrs:{"label":"Status","min-width":"150","prop":"fl_status","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('badge',{attrs:{"type":_vm.badgeStatusType(row.fl_status)}},[_vm._v(_vm._s(_vm.badgeStatus(row.fl_status)))])],1)}}])}),_c('el-table-column',{attrs:{"label":"CV Pro","min-width":"150","prop":"cvpro","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('badge',{attrs:{"type":_vm.badgeCvproType(row.cvpro)}},[_vm._v(_vm._s(_vm.formatCvpro(row.cvpro)))])],1)}}])}),_c('el-table-column',{attrs:{"label":"Último Acesso","min-width":"150","prop":"dt_ultimo_logon","formatter":_vm.formatTimestamp}}),_c('el-table-column',{attrs:{"label":"Criado em","min-width":"150","prop":"created_at","formatter":_vm.formatTimestamp}}),_c('el-table-column',{attrs:{"label":"Ações","min-width":"100","header-align":"right","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-right table-actions"},[_c('el-tooltip',{attrs:{"content":"Detalhes","effect":"light","open-delay":300,"placement":"top"}},[_c('base-button',{staticClass:"btn-link",attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.detalhesCandidato(row.id_candidato)}}},[_c('i',{staticClass:"tim-icons icon-badge"})])],1),_c('el-tooltip',{attrs:{"content":"Bloquear","effect":"light","open-delay":300,"placement":"top"}},[_c('base-button',{staticClass:"btn-link",attrs:{"type":row.fl_status < 3 ? 'danger' : 'neutral',"disabled":row.fl_status < 3 ? false : true,"icon":"","size":"sm"},nativeOn:{"click":function($event){return _vm.handleDelete(row.id_candidato, row)}}},[_c('i',{staticClass:"tim-icons icon-lock-circle"})])],1)],1)}}])})],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries ")])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }