<template>
  <div>
    <!-- Novo contrato -->
    <template>
      <div class="row my-2">
        <div class="col-lg-12">
          <card>
            <template slot="header">
              <div class="row">
                <div class="col-lg-12">
                  <h4>Contratos Existentes</h4>
                </div>
              </div>
            </template>
            <div class="row flex-wrap my-1" v-for="(item, index) in propContrato" :key="index">
              <!-- ID -->
              <div class="col-lg-10 itemContrato p-2">
                <badge :type="badgeStatusType(item.fl_status)">{{ retornaStatusContrato(item.fl_status) }}</badge><br>
                ID: <strong>{{ item.id_empresa_contrato }}</strong> - Numero do Contrato: <strong>{{ item.id_contrato }}</strong> -
                Tipo de Contrato: <strong>{{ retornaTipoContrato(item.fl_tipo_contrato) }}</strong> -
                Tipo de Pagamento: <strong>{{ item.fl_tipo_pagamento === '1' ? 'Mensal' : 'Anual' }}</strong><br>
                Modo de Pagamento: <strong>{{ retornaModoPagamento(item.fl_modo_pagamento) }}</strong><br>
                Data de Inicio: <strong>{{ item.dt_inicio | formataData }}</strong><br>
                Data de Término: <strong>{{ item.dt_fim | formataData }}</strong><br>
                Link do Contrato: <strong>{{ item.hl_link_contrato }}</strong>
              </div>
              <div class="col-lg-2 itemContrato p-2">
                <base-button type="warning" size="sm" @click="handleEditar(item.id_empresa_contrato)">Editar</base-button>
                <base-button type="danger" size="sm" @click="handleApagar(item.id_empresa_contrato)">Apagar</base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </template>
    <!-- Novo contrato -->
    <template>
      <div class="row my-2" ref="formulario">
        <div class="col-lg-12">
          <card>
            <template slot="header">
              <div class="row">
                <div class="col-lg-12">
                  <h4>Novo Contrato</h4>
                </div>
              </div>
            </template>
            <div class="row flex-wrap">
              <!-- Numero do Contrato -->
              <div class="col-lg-2">
                <label for="idcontrato">Numero do Contrato</label>
                <input
                  id="idcontrato"
                  ref="idcontrato"
                  type="text"
                  class="form-control"
                  name="Razão Social"
                  v-model="novoContrato.id_contrato"
                />
              </div>
              <!-- Tipo de Contrato -->
              <div class="col-lg-2">
                <label for="tipoContrato">Tipo de Contrato</label> <el-select
                  ref="tipoContrato"
                  name="Tipo de Contrato"
                  class="select-primary"
                  id="tipoContrato"
                  v-model="novoContrato.fl_tipo_contrato"
                >
                  <el-option
                    v-for="tipo in tipoContrato"
                    :key="tipo.id"
                    :value="tipo.id"
                    :label="tipo.text"
                  ></el-option>
                </el-select>
              </div>
              <!-- Tipo de Pagamento -->
              <div class="col-lg-2">
                <label for="tipoPagamento">Tipo de Pagamento</label> <el-select
                  ref="tipoPagamento"
                  name="Tipo de Contrato"
                  class="select-primary"
                  id="tipoPagamento"
                  v-model="novoContrato.fl_tipo_pagamento"
                >
                  <el-option value="1" label="Mensal"></el-option>
                  <el-option value="2" label="Anual"></el-option>
                </el-select>
              </div>
              <!-- Modo de Pagamento -->
              <div class="col-lg-2">
                <label for="modoPagamento">Modo de Pagamento</label> <el-select
                  ref="modoPagamento"
                  name="Tipo de Contrato"
                  class="select-primary"
                  id="modoPagamento"
                  v-model="novoContrato.fl_modo_pagamento"
                >
                  <el-option
                    v-for="tipo in modoPagamento"
                    :key="tipo.id"
                    :value="tipo.id"
                    :label="tipo.text"
                  ></el-option>
                </el-select>
              </div>
              <!-- Data de Inicio -->
              <div class="col-lg-2">
                <div class="form-group">
                <label for="dt_inicio">Data de Inicio</label>
                <el-date-picker
                  type="date"
                  v-model="novoContrato.dt_inicio"
                  format="dd/MM/yyyy"
                />
                </div>
              </div>
              <!-- Data Fim -->
              <div class="col-lg-2">
                <div class="form-group">
                <label for="dt_fim">Data de Término</label>
                <el-date-picker
                  type="date"
                  v-model="novoContrato.dt_fim"
                  format="dd/MM/yyyy"
                />
                </div>
              </div>
            </div>
            <div class="row flex-wrap">
              <!-- Status do Contrato -->
              <div class="col-lg-2">
                <label for="statusContrato">Status do Contrato</label> <el-select
                  ref="statusContrato"
                  name="Status do Contrato"
                  class="select-primary"
                  id="statusContrato"
                  v-model="novoContrato.fl_status"
                >
                  <el-option
                    v-for="tipo in statusContrato"
                    :key="tipo.id"
                    :value="tipo.id"
                    :label="tipo.text"
                  ></el-option>
                </el-select>
              </div>
              <!-- Link do Contrato -->
              <div class="col-lg-6">
                <label for="linkcontrato">Link do Contrato</label>
                <input
                  id="linkcontrato"
                  ref="linkcontrato"
                  type="text"
                  class="form-control"
                  name="Razão Social"
                  v-model="novoContrato.hl_link_contrato"
                />
              </div>
            </div>
            <div class="row mt-2 flex-wrap">
              <div class="col-lg-2">
                <base-button type="primary" @click="salvarContrato">Salvar</base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </template>
    <!-- <div class="row">
      <div class="col">
        <pre>{{ propContrato }}</pre>
      </div>
      <div class="col">
        <pre>{{ novoContrato }}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue'
import VCA from '@vue/composition-api'
import { adminApiUrl } from "@/global";
import { Badge, BaseSwitch } from "src/components";
import { Loading, Table, TableColumn, Select, Option, Tooltip, DatePicker } from "element-ui";
import JsonEditorVue from 'json-editor-vue'
import moment from "moment";

Vue.use(VCA)

export default {
  name: "ContratoEmpresa",
  props: {
    propContrato: { type: Array }
  },
  components: {
    Badge,
    BaseSwitch,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    JsonEditorVue
  },
  data() {
    return {
      novoContrato: {
        id_empresa: "",
        id_contrato: "",
        fl_tipo_contrato: "",
        fl_tipo_pagamento: "",
        fl_modo_pagamento: "",
        hl_link_contrato: "",
        dt_inicio: "",
        dt_fim: "",
        fl_status: "",
        json_observacoes: null,
      },
      tipoContrato: [
        { id: 1, text: 'Free' },
        { id: 2, text: 'Mensal'},
        { id: 3, text: 'Anual' },
        { id: 4, text: 'Anual - 3 anos' }
      ],
      modoPagamento: [
        { id: 1, text: 'Boleto' },
        { id: 2, text: 'Cartão de Crédito' },
        { id: 3, text: 'Transferência / PIX' }
      ],
      statusContrato: [
        { id: 1, text: 'Ativo' },
        { id: 2, text: 'Congelado' },
        { id: 3, text: 'Cancelado' },
        { id: 4, text: 'Encerrado' },
        { id: 5, text: 'Substituído' }
      ]
    }
  },
  methods: {
    async salvarContrato() {
      this.novoContrato.id_empresa = this.$route.params.id;
      await this.$http
        .post(`${adminApiUrl}/empresa/gravacontrato`, this.novoContrato)
        .then(() => {
          this.notificacao('Contrato salvo!', 'success')
        }
      ).catch(err => {
          this.notificacao('Erro salvando o contrato!', 'danger')
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    handleEditar(id) {
      const contrato = this.propContrato.find(c => c.id_empresa_contrato === id)
      this.novoContrato.id_empresa = contrato.id_empresa;
      this.novoContrato.id_contrato = contrato.id_contrato;
      this.novoContrato.fl_tipo_contrato = contrato.fl_tipo_contrato;
      this.novoContrato.fl_tipo_pagamento = contrato.fl_tipo_pagamento;
      this.novoContrato.fl_modo_pagamento = contrato.fl_modo_pagamento;
      this.novoContrato.hl_link_contrato = contrato.hl_link_contrato;
      this.novoContrato.dt_inicio = contrato.dt_inicio;
      this.novoContrato.dt_fim = contrato.dt_fim;
      this.novoContrato.fl_status = contrato.fl_status;
      this.novoContrato.json_observacoes = contrato.json_observacoes;
      this.goto('formulario');
    },
    async handleApagar(id) {
      if (confirm('Tem certeza que deseja apagar o contrato??')) {
        const data = { id_empresa_contrato: id }
        await this.$http
          .post(`${adminApiUrl}/empresa/apagarcontrato`, data)
          .then(() => {
            const idx = this.propContrato.findIndex(c => c.id_empresa_contrato === id)
            this.propContrato.splice(idx, 1);
            this.notificacao('Contrato Excluido!', 'success')
          })
          .catch(err => {
            this.notificacao('Erro excluindo o contrato!', 'danger')
            console.log('Erro na API -> ', err);
            return false;
          });
      }
    },
    retornaTipoContrato(value) {
      let item = this.tipoContrato.find(i => i.id === parseInt(value));
      return item.text;
    },
    retornaModoPagamento(value) {
      let item = this.modoPagamento.find(i => i.id === parseInt(value));
      return item.text;
    },
    retornaStatusContrato(value) {
      let item = this.statusContrato.find(i => i.id === parseInt(value));
      return item.text;
    },
    badgeStatusType(value) {
      if (value === '1') {
        return "success";
      } else if (value === '2') {
        return "default";
      } else if (value === '3') {
        return "danger";
      } else if (value === '4') {
        return "default";
      } else if (value === '5') {
        return "info";
      } else {
        return "default";
      }
    },
    //Função de notificação
    notificacao(msg, tipo) {
      this.$notify({
        type: tipo,
        message: msg,
        icon: "tim-icons icon-bell-55",
      });
    },
    goto(refName) {
      console.log('refName -> ', refName)
      var element = this.$refs[refName];
      console.log('element -> ', typeof element)
      if (Array.isArray(element)){
        element[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      } else {
        element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        //element.scrollTo({behavior: "smooth", block: "center", inline: "center"});
      }
    },
  },
  filters: {
    formataData:function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
  },
}
</script>

<style>
.itemContrato {
  background-color: white;
  color: black;
}
</style>
