<template>
  <div class="content">
    <div class="row"><route-bread-crumb /></div>
    <div class="row">
      <div class="col-12">
        <h1>Usuários Admins</h1>
      </div>
    </div>
    <tabs type="primary">
      <tab-pane label="Lista de Usuarios">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Nome</th>
              <th scope="col">E-mail</th>
              <th scope="col">Criado em</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(user, index) in usuarios">
              <tr :key="index">
                <th scope="row">{{ user.id_usuario }}</th>
                <td>{{ user.nm_nome }}</td>
                <td>{{ user.hl_email }}</td>
                <td>{{ user.created_at }}</td>
                <td>
                  <base-button type="primary" icon class="mx-1"><i class="fas fa-user-edit"></i></base-button>
                  <base-button type="warning" icon class="mx-1"><i class="fas fa-ban"></i></base-button>
                  <base-button type="danger" icon class="mx-1" @click="deleteUser(user.id_usuario)"><i class="far fa-trash-alt"></i></base-button>
                </td>
              </tr>
              <!-- <div :key="index">
                <div class="row">
                  <div class="col-12">

                  </div>
                </div>
              </div> -->
            </template>
          </tbody>
        </table>
      </tab-pane>
      <tab-pane label="Novo Usuário">
        <card>
          <form>
            <div class="form-row">
              <base-input
                class="col-md-4"
                type="text"
                label="Nome"
                v-model="newUser.nm_nome"
                placeholder="Nome"
              />
              <base-input
                class="col-md-4"
                type="email"
                label="E-mail"
                v-model="newUser.hl_email"
                placeholder="nome@dominio.com"
              />
              <base-input
                class="col-md-4"
                type="password"
                label="Senha"
                v-model="newUser.pw_senha"
              />
            </div>
            <div class="form-row">
              <base-button type="primary" @click="addNovoAdmin">Cadastrar</base-button>
            </div>
          </form>
        </card>
      </tab-pane>
      <tab-pane label="Raw Data">
        <pre>{{ usuarios }}</pre>
      </tab-pane>
    </tabs>
  </div>
</template>

<script>
import { adminApiUrl } from "@/global";
import { RouteBreadCrumb, TabPane, Tabs } from "src/components";

export default {
  name: "ListaAdmins",
  components: {
    RouteBreadCrumb,
    TabPane,
    Tabs
  },
  data() {
    return {
      usuarios: [],
      newUser: {
        nm_nome: "",
        hl_email: "",
        pw_senha: ""
      }
    }
  },
  methods: {
    getUsuarios() {
      this.$http
        .get('admins/lista')
        .then(res => {
          // console.log(res)
          this.usuarios = res.data;
        })
        .catch((err) => {
          console.log('err -> ', err.config);
        });
    },
    addNovoAdmin() {
      this.$http
        .post('admins/novo', this.newUser)
        .then(() => {
          this.$notify({
            type: "success",
            message: "Usuario cadastrado com sucesso!!",
            icon: "tim-icons icon-bell-55",
          });
          setTimeout(() => {
            this.getUsuarios();
          }, 300);
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            type: "warning",
            message: "Não foi possível cadastrar o usuário.",
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    deleteUser(id) {
      var data = { id: id }
      this.$http
        .post('admins/apagar', data)
        .then(() => {
          this.$notify({
            type: "success",
            message: "Usuario apagado com sucesso!!",
            icon: "tim-icons icon-bell-55",
          });
          setTimeout(() => {
            this.getUsuarios();
          }, 300);
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            type: "warning",
            message: "Não foi possível apagar o usuário.",
            icon: "tim-icons icon-bell-55",
          });
        });
    }
  },
  mounted() {
    this.getUsuarios();
  }
}
</script>

<style>

</style>
