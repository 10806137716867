<template>
  <div>
    <FadeTransition :duration="200" mode="out-in">
      <card v-if="flagEditUser">
        <!-- ID / Nome / E-mail / Status -->
        <div class="row my-2">
          <!-- Campo ID -->
          <div class="col-1">
            <label for="id">ID <span class="text-danger">*</span></label>
            <input
              id="id"
              ref="id"
              type="text"
              class="form-control"
              name="ID"
              disabled
              v-model="usuario.id_empresa_user"
            />
          </div>
          <!-- Campo Nome -->
          <div class="col-4">
            <label for="nome">Nome <span class="text-danger">*</span></label>
            <input
              id="nome"
              ref="nome"
              type="text"
              class="form-control"
              name="Razão Social"
              v-model="usuario.nm_nome"
            />
          </div>
          <!-- Campo E-mail -->
          <div class="col-3">
            <label for="hl_email">E-mail  <span class="text-danger">*</span></label>
            <input
              id="hl_email"
              ref="hl_email"
              type="text"
              class="form-control"
              name="E-mail"
              v-model="usuario.hl_email"
            />
          </div>
          <!-- Campo Status -->
          <div class="col-3">
            <label for="fl_status">Status </label>
            <el-select
              ref="fl_status"
              name="Status"
              class="select-primary"
              id="fl_status"
              v-model="usuario.fl_status"
            >
              <el-option
                v-for="status in statusUsuario"
                :key="status.id"
                :value="status.id"
                :label="status.value">

              </el-option>
            </el-select>
          </div>
        </div>
        <!-- CPF / Cargo / Departamento / Tipo de Acesso -->
        <div class="row my-2">
          <!-- CPF -->
          <div class="col-2">
            <label for="nu_cpf">CPF</label>
            <input
              id="nu_cpf"
              ref="nu_cpf"
              type="text"
              maxlength="30"
              class="form-control"
              name="CPF"
              placeholder="CPF"
              v-model.trim="usuario.nu_cpf"
            />
          </div>
          <!-- Cargo -->
          <div class="col-3">
            <label for="nm_cargo">Cargo</label>
            <input
              id="nm_cargo"
              ref="nm_cargo"
              type="text"
              maxlength="30"
              class="form-control"
              name="Cargo"
              placeholder="Cargo"
              v-model.trim="usuario.nm_cargo"
            />
          </div>
          <!-- Departamento -->
          <div class="col-3">
            <label for="nm_depto">Departamento</label>
            <input
              id="nm_depto"
              ref="nm_depto"
              type="text"
              maxlength="30"
              class="form-control"
              name="Departamento"
              placeholder="Departamento"
              v-model.trim="usuario.nm_depto"
            />
          </div>
          <!-- Campo Tipo de Acesso -->
          <div class="col-3">
            <label for="fl_tipo_acesso">Tipo de Acesso </label>
            <el-select
              ref="fl_tipo_acesso"
              name="Tipo de Acesso"
              class="select-primary"
              id="fl_tipo_acesso"
              v-model="usuario.fl_tipo_acesso"
            >
              <el-option
                v-for="tipo in tipoAcesso"
                :key="tipo.id"
                :value="tipo.id"
                :label="tipo.value">

              </el-option>
            </el-select>
          </div>
        </div>
        <!-- Data Nasc / Sexo / Telefone / Celular / Admin -->
        <div class="row my-2">
          <!-- Campo Data de Nascimento -->
          <div class="col-2">
            <label for="dt_nasc">Data de Nascimento</label>
            <input
              id="dt_nasc"
              ref="dt_nasc"
              type="date"
              class="form-control"
              name="Data de Nascimento"
              v-model="usuario.dt_nasc"
            />
          </div>
          <!-- Campo Sexo -->
          <div class="col-2">
            <label for="sexo">Sexo </label>
            <el-select
              ref="sexo"
              name="Sexo"
              class="select-primary"
              id="sexo"
              v-model="usuario.fl_sexo"
            >
              <el-option value="1" label="Masculino"></el-option>
              <el-option value="2" label="Feminino"></el-option>
              <el-option value="3" label="Não informado"></el-option>
            </el-select>
          </div>
          <!-- Telefone -->
          <div class="col-2">
            <label for="nu_telefone">Telefone</label>
            <input
              id="nu_telefone"
              ref="nu_telefone"
              type="text"
              class="form-control"
              name="Telefone"
              placeholder="Telefone"
              v-model="usuario.nu_telefone"
            />
          </div>
          <!-- Celular -->
          <div class="col-2">
            <label for="nu_celular">Celular</label>
            <input
              id="nu_celular"
              ref="nu_celular"
              type="text"
              class="form-control"
              name="Celular"
              placeholder="Celular"
              v-model="usuario.nu_celular"
            />
          </div>
          <!-- Campo Admin -->
          <div class="col-3">
            <label for="fl_admin">Admin </label>
            <el-select
              ref="fl_admin"
              name="Admin"
              class="select-primary"
              id="fl_admin"
              v-model="usuario.fl_admin"
            >
              <el-option value="0" label="Não"></el-option>
              <el-option value="1" label="Sim"></el-option>
            </el-select>
          </div>
        </div>
        <!-- Senha -->
        <div class="row my-2">
          <div class="col-lg-3">
            <label for="pw_senha">Senha</label>
            <input
              id="pw_senha"
              ref="pw_senha"
              type="text"
              class="form-control"
              name="Senha"
              placeholder="Senha"
              v-model="usuario.pw_senha"
            />
          </div>
        </div>
        <!-- Linha da Foto -->
        <div class="row my-2">
          <div class="col-2 align-self-center">
            <img :src="usuario.hl_foto" class="img-fluid" alt="Logo">
          </div>
          <div class="col-9">
            <label for="hl_foto">URL da foto</label>
            <input
              id="hl_foto"
              ref="hl_foto"
              type="text"
              class="form-control"
              name="URL da foto"
              placeholder="URL da foto"
              v-model="usuario.hl_foto"
            />
          </div>
        </div>
        <div class="row my-2">
          <div class="col-3">
            Criado em: <strong>{{ usuario.created_at | formataDiaHora }}</strong>
          </div>
          <div class="col-3">
            Atualizado em: <strong>{{ usuario.updated_at | formataDiaHora }}</strong>
          </div>
          <div class="col-3">
            Deletado em: <span class="text-danger"><strong>{{ usuario.deleted_at | formataDiaHora }}</strong></span>
          </div>
        </div>
        <base-button type="warning" size="sm" @click="fecharEditarUsuario">Fechar</base-button>
        <base-button type="success" size="sm" @click="salvaUsuario">Salvar</base-button>
      </card>
    </FadeTransition>
    <!-- Linha de quantidade de Usuarios e Adicionar -->
    <div class="row mb-2">
      <div class="col-lg-auto">
        Usuarios de RH: <strong>{{ filtraUsuariosRh('rh') }}</strong> - Contratados: <strong>{{ propPlano.json_detalhes.usuarios.qtdRh }}</strong><br>
        Usuarios de Normais: <strong>{{ filtraUsuariosRh('comum') }}</strong> - Contratados: <strong>{{ propPlano.json_detalhes.usuarios.qtdArea }}</strong>
      </div>
      <div class="col-lg">
        <base-button type="primary" size="sm" @click="novoUsuario">Adicionar</base-button>
      </div>
    </div>
    <!-- Linha de busca e listagem -->
    <div class="row">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <!-- novoUsuario -->
        <div class="form-check form-check-inline">
          <base-input v-model="searchQuery"
            prepend-icon="fas fa-search"
            placeholder="Procurar...">
          </base-input>
        </div>
      </div>
    </div>
    <!-- Tabela -->
    <el-table :data="queriedData">
      <el-table-column width="80" label="#" align="center">
        <div class="avatar rounded-circle" slot-scope="{ row }">
          <img :src="row.hl_foto" alt="Table image" />
        </div>
      </el-table-column>
      <el-table-column min-width="250" label="Nome">
        <div slot-scope="{ row }">
          <strong>{{row.nm_nome}}</strong><br>
          <small><a >{{row.hl_email}}</a></small><br>
        </div>
      </el-table-column>
      <el-table-column min-width="150" label="Cargo">
        <div slot-scope="{ row }">
          <strong>{{row.nm_cargo}}</strong><br>
          <small>{{row.nm_depto}}</small>
        </div>
      </el-table-column>
      <el-table-column min-width="150" label="Tipo de Acesso">
        <div slot-scope="{ row }">
          <small>{{retornaTipoAcesso(row.fl_tipo_acesso)}}</small>
        </div>
      </el-table-column>
      <el-table-column min-width="80" label="Admin">
        <div slot-scope="{ row }">
          <badge :type="row.fl_admin === '0' ? 'default' : 'success'">{{row.fl_admin === '0' ? 'Não' : 'Sim' }}</badge>
        </div>
      </el-table-column>
      <el-table-column min-width="80" label="Status">
        <div slot-scope="{ row }">
          <small>{{retornaStatus(row.fl_status)}}</small>
        </div>
      </el-table-column>
      <el-table-column min-width="120" label="Ações">
        <div slot-scope="{ row }">
          <!-- But Editar -->
          <el-tooltip
            content="Editar"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click="editarUsuario(row.id_empresa_user)"
              type="primary"
              icon
              size="sm"
              class="btn-link"
            ><i class="tim-icons icon-badge"></i>
            </base-button>
          </el-tooltip>
          <!-- But Bloquear -->
          <el-tooltip
            content="Bloquear"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click="alteraStatus(row.id_empresa_user, row.fl_status)"
              :type="row.fl_status < 3 ? 'warning' : 'success'"
              icon
              size="sm"
              class="btn-link"
            ><i class="tim-icons icon-lock-circle"></i>
            </base-button>
          </el-tooltip>
          <!-- But Apagar -->
          <el-tooltip
            content="Apagar"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click="apagarUsuario(row)"
              type="danger"
              icon
              size="sm"
              class="btn-link"
            ><i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
    <!-- Linha da Paginação -->
    <div class="row">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <div class="">
          <p class="card-category">
            Mostrando {{ from + 1 }} de {{ to }} em {{ total }} usuarios.<br>
          </p>
        </div>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-6">
        <pre>{{propUsuarios}}</pre>
      </div>
      <div class="col-lg-6">
        <pre>{{usuario}}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
import { adminApiUrl } from "@/global";
import { FadeTransition } from 'vue2-transitions';
import UsuariosPaginacao from './UsuariosPaginacao'
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Badge } from 'src/components';
import moment from "moment";
import Fuse from 'fuse.js';
import swal from 'sweetalert2';

export default {
  name: "UsuariosEmpresa",
  props: {
    propUsuarios: { type: Array },
    propPlano: { type: Object }
  },
  mixins: [UsuariosPaginacao],
  components: {
    BasePagination,
    Badge,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    FadeTransition
  },
  data() {
    return {
      flagEditUser: false,
      flNovoUsuario: false,
      usuario: {
        id_empresa_user: null,
        id_empresa: 0,
        nm_nome: "",
        hl_email: "",
        pw_senha: "",
        nu_cpf: null,
        dt_nasc: null,
        fl_sexo: null,
        nu_telefone: null,
        nu_celular: null,
        nm_depto: null,
        nm_cargo: null,
        hl_foto: "https://storagematchjobdefault.blob.core.windows.net/matchjob/placeholder.jpg",
        dt_ultimo_logon: null,
        dt_ativo_ate: null,
        fl_status: "1",
        fl_tipo_acesso: "9",
        fl_admin: "0"
      },
      tipoAcesso: [
        { id: "1", value:	"Full Admin" },
        { id: "2", value:	"Aprova e Publica Vagas + Usuarios" },
        { id: "3", value:	"Aprova e Publica Vagas" },
        { id: "4", value:	"Publica Vagas" },
        { id: "5", value:	"Aprova/Solicita Vagas e Candidatos" },
        { id: "6", value:	"Aprova Candidatos e Vagas" },
        { id: "7", value:	"Aprova Candidatos" },
        { id: "8", value:	"Aprova Candidatos" },
        { id: "9", value:	"Acesso nulo" }
      ],
      statusUsuario: [
        { id: "1", value:	"Criado", descricao:	"Registro recém criado" },
        { id: "2", value:	"Ativo", descricao:	"Validado o e-mail" },
        { id: "3", value:	"Desativado", descricao:	"N/A" },
        { id: "4", value:	"Bloqueado", descricao:	"Bloqueado via sistema" },
        { id: "5", value:	"Inativado", descricao:	"E-mail inválido no retorno do iAgente" }

      ]
    }
  },
  methods: {
    async salvaUsuario() {
      this.usuario.id_empresa = this.$route.params.id;
      if (this.usuario.id_empresa_user === null) {
        this.$delete(this.usuario, 'id_empresa_user');
      } else {
        const idx = this.propUsuarios.find(u => u.id_empresa_user === this.usuario.id_empresa_user);
        if (idx.pw_senha === this.usuario.pw_senha) {
          this.$delete(this.usuario, 'pw_senha');
        }
      }
      this.$delete(this.usuario, 'created_at');
      this.$delete(this.usuario, 'updated_at');
      this.$delete(this.usuario, 'deleted_at');
      await this.$http
        .post(`${adminApiUrl}/usuarios/salvar`, this.usuario)
        .then(() => {
          this.notificacao('Usuário salvo!', 'success');
          this.fecharEditarUsuario();
        })
        .catch(err => {
          this.notificacao('Erro salvando o usuário!', 'danger');
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    async alteraStatus(id, status) {
      const flstatus = parseInt(status);
      var novoStatus = null;
      if (flstatus < 3) { novoStatus = 4; }
      else if (flstatus > 2) { novoStatus = 2; }
      const data = {
        id_empresa_user: id,
        fl_status: novoStatus
      }
      await this.$http
        .post(`${adminApiUrl}/usuarios/salvar`, data)
        .then(() => {
          this.notificacao('Usuário Atualizado!', 'success');
          const idx = this.propUsuarios.findIndex(u => u.id_empresa_user === this.usuario.id_empresa_user);
          this.propUsuarios[idx].fl_status = novoStatus;
        })
        .catch(err => {
          this.notificacao('Erro atualizando o usuário!', 'danger');
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    async apagarUsuario(row) {
      const data = {
        id_empresa_user: row.id_empresa_user
      }
      await this.$http
        .post(`${adminApiUrl}/usuarios/apagar`, data)
        .then(() => {
          this.notificacao('Usuário Excluido!', 'success');
          const idx = this.propUsuarios.findIndex(u => u.id_empresa_user === this.usuario.id_empresa_user);
          this.propUsuarios.splice(idx, 1);
        })
        .catch(err => {
          this.notificacao('Erro excluindo o usuário!', 'danger');
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    retornaTipoAcesso(value) {
      var tipo = this.tipoAcesso.find(i => i.id === value);
      return tipo.value;
    },
    retornaStatus(value) {
      var status = this.statusUsuario.find(i => i.id === value);
      return status.value;
    },
    novoUsuario() {
      this.resetaUsuario();
      this.flagEditUser = true;
      this.flNovoUsuario = true;
    },
    editarUsuario(id) {
      const user = this.propUsuarios.find(u => u.id_empresa_user === id);
      this.usuario = structuredClone(user);
      this.flagEditUser = true;
      this.flNovoUsuario = false;
    },
    fecharEditarUsuario() {
      this.resetaUsuario();
      this.flagEditUser = false;
      this.flNovoUsuario = false;
    },
    resetaUsuario() {
      this.usuario.id_empresa_user = null;
      this.usuario.id_empresa = 0;
      this.usuario.nm_nome = "";
      this.usuario.hl_email = "";
      this.usuario.pw_senha = "";
      this.usuario.nu_cpf = null;
      this.usuario.dt_nasc = null;
      this.usuario.fl_sexo = null;
      this.usuario.nu_telefone = null;
      this.usuario.nu_celular = null;
      this.usuario.nm_depto = null;
      this.usuario.nm_cargo = null;
      this.usuario.hl_foto = "https://storagematchjobdefault.blob.core.windows.net/matchjob/placeholder.jpg";
      this.usuario.dt_ultimo_logon = null;
      this.usuario.dt_ativo_ate = null;
      this.usuario.fl_status = "1";
      this.usuario.fl_tipo_acesso = "9";
      this.usuario.fl_admin = "0";
      this.$delete(this.usuario, 'created_at')
      this.$delete(this.usuario, 'updated_at')
      this.$delete(this.usuario, 'deleted_at')
    },
    filtraUsuariosRh(value) {
      if (value === 'rh') {
        const usersRH = this.propUsuarios.filter(r => r.fl_tipo_acesso > 0 && r.fl_tipo_acesso < 5);
        return usersRH.length;
      } else if (value === 'comum') {
        const usersComum = this.propUsuarios.filter(r => r.fl_tipo_acesso > 4);
        return usersComum.length;
      }

    },
    //Função de notificação
    notificacao(msg, tipo) {
      this.$notify({
        type: tipo,
        message: msg,
        icon: "tim-icons icon-bell-55",
      });
    },
  },
  filters: {
    formataDiaHora:function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
  },
}
</script>

<style>

</style>
