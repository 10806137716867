<template>
  <div>
    <div class="row mb-2">
      <div class="col-lg-2">
        <base-button type="primary" size="sm" @click="novo()">Adicionar</base-button>
      </div>
    </div>
    <card>
      <template slot="header">
        <div class="row">
          <div class="col-lg-12">
            <h4>Endereços</h4>
          </div>
        </div>
      </template>
      <div class="row mb-2" v-for="(item, index) in propEnderecos" :key="index">
        <div class="col-lg-9">
          <h5 class="mb-0">{{ item.nm_nome_endereco }} - {{ retornaTipoEndereco(item.fl_tipo_endereco) }}</h5>
          {{ item.nm_endereco }}, {{ item.nu_numero }} {{ item.nm_complemento ? ` - ${item.nm_complemento}` : '' }} - {{ item.nm_bairro }}<br>
          {{ item.nm_cidade }} - {{ item.nm_estado }} - CEP: {{ item.nu_cep }} - {{ item.nm_pais }}
        </div>
        <div class="col-lg-3">
          <base-button type="warning" class="mx-1" size="sm" @click="editar(item.id_empresa_ender)">Editar</base-button>
          <base-button type="danger" class="mx-1" size="sm" @click="excluir(item.id_empresa_ender)">Excluir</base-button>
        </div>
      </div>
    </card>
    <card v-if="flNovoEndereco">
      <div class="row">
        <!-- Nome do Local -->
        <div class="col-lg-2">
          <label for="nomeLocal">Nome do Local</label>
          <input
          id="nomeLocal"
          ref="nomeLocal"
          type="text"
          class="form-control"
          name="Nome do Local"
          v-model="novoEndereco.nm_nome_endereco"
          />
        </div>
        <!-- tipo -->
        <div class="col-lg-2">
          <label for="tipoEndereco">Tipo de Endereço</label>
          <el-select
            ref="tipoEndereco"
            name="Tipo de Endereço"
            class="select-primary"
            id="tipoEndereco"
            v-model="novoEndereco.fl_tipo_endereco"
          >
            <el-option
              v-for="tipo in fl_tipo_endereco"
              :key="tipo.id"
              :value="tipo.id"
              :label="tipo.text"
            ></el-option>
          </el-select>
        </div>
        <!-- CEP -->
        <div class="col-lg-2">
          <label for="cep">CEP</label>
          <input
          id="cep"
          ref="cep"
          type="text"
          class="form-control"
          name="CEP"
          v-model="novoEndereco.nu_cep"
          @blur="atualizaCep()"
          />
        </div>
        <!-- Endereço -->
        <div class="col-lg-4">
          <label for="endereco">Endereço</label>
          <input
          id="endereco"
          ref="endereco"
          type="text"
          class="form-control"
          name="Endereço"
          v-model="novoEndereco.nm_endereco"
          />
        </div>
        <!-- Numero -->
        <div class="col-lg-2">
          <label for="numero">Numero</label>
          <input
          id="numero"
          ref="numero"
          type="text"
          class="form-control"
          name="Numero"
          v-model="novoEndereco.nu_numero"
          />
        </div>
      </div>
      <div class="row">
        <!-- Complemento -->
        <div class="col-lg-3">
          <label for="enderComple">Complemento</label>
          <input
          id="enderComple"
          ref="enderComple"
          type="text"
          class="form-control"
          name="Complemento"
          v-model="novoEndereco.nm_complemento"
          />
        </div>
        <!-- Bairro -->
        <div class="col-lg-3">
          <label for="nomeBairro">Bairro</label>
          <input
          id="nomeBairro"
          ref="nomeBairro"
          type="text"
          class="form-control"
          name="Bairro"
          v-model="novoEndereco.nm_bairro"
          />
        </div>
        <!-- Cidade -->
        <div class="col-lg-3">
          <label for="nomeCidade">Cidade</label>
          <input
          id="nomeCidade"
          ref="nomeCidade"
          type="text"
          class="form-control"
          name="Cidade"
          v-model="novoEndereco.nm_cidade"
          />
        </div>
        <!-- Estado -->
        <div class="col-lg-1">
          <label for="siglaEstado">Estado</label>
          <input
          id="siglaEstado"
          ref="siglaEstado"
          type="text"
          class="form-control"
          name="Estado"
          v-model="novoEndereco.nm_estado"
          />
        </div>
        <!-- Pais -->
        <div class="col-lg-2">
          <label for="nomePais">Pais</label>
          <input
          id="nomePais"
          ref="nomePais"
          type="text"
          class="form-control"
          name="Pais"
          v-model="novoEndereco.nm_pais"
          />
        </div>
      </div>
      <!-- Botão -->
      <div class="row my-2">
        <div class="col-lg-3">
          <base-button type="warning" class="mx-1" size="sm" @click="fecharForm()">Cancelar</base-button>
          <base-button type="success" class="mx-1" size="sm" @click="salvar()">Salvar</base-button>
        </div>
      </div>
    </card>
    <!-- <pre>novoEndereco: {{ novoEndereco }}</pre>
    <pre>propEnderecos: {{ propEnderecos }}</pre> -->
  </div>
</template>

<script>
import axios from 'axios';
import { Loading, Table, TableColumn, Select, Option, Tooltip, DatePicker } from "element-ui";
import { adminApiUrl } from "@/global";

export default {
  name: "EnderecosEmpresa",
  props: {
    propEnderecos: { type: Array }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      fl_tipo_endereco: [
        { id: "1", text: 'Matriz' },
        { id: "2", text: 'Filial' },
        { id: "3", text: 'Local da Vaga' },
        { id: "4", text: 'Cobrança' }
      ],
      novoEndereco: {
        id_empresa: this.$route.params.id,
        fl_tipo_endereco: "",
        nm_nome_endereco: "",
        nm_pais: "Brasil",
        nu_cep: "",
        nm_estado: "",
        nm_cidade: "",
        nm_bairro: "",
        nm_endereco: "",
        nu_numero: "",
        nm_complemento: "",
      },
      flNovoEndereco: false
    }
  },
  methods: {
    retornaTipoEndereco(value) {
      const item = this.fl_tipo_endereco.find(e => e.id === value)
      return item.text;
    },
    atualizaCep() {
      //Auto-preenchimento dos campos a partir do CEP
      const viacep = axios.create();
      viacep.defaults.headers.common = {};
      viacep.defaults.headers.common.accept = "application/json";
      //Verifica se campo cep possui valor informado.
      if (this.novoEndereco.nu_cep != "") {
        //Deixa somente os digitos
        this.novoEndereco.nu_cep = this.novoEndereco.nu_cep.replace(/\D/g, "");
        //Expressão regular para validar somente numeros
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        if (validacep.test(this.novoEndereco.nu_cep)) {
          //Preenche os campos com "..." enquanto consulta webservice.
          this.novoEndereco.nm_endereco = "...";
          this.novoEndereco.nm_bairro = "...";
          this.novoEndereco.nm_cidade = "...";
          this.novoEndereco.nm_estado = "...";
          //Consulta o webservice viacep.com.br/
          viacep
            .get("//viacep.com.br/ws/" + this.novoEndereco.nu_cep + "/json/")
            .then((dados) => {
              //Atualiza os campos com os valores da consulta.
              this.novoEndereco.nm_endereco = dados.data.logradouro;
              this.novoEndereco.nm_bairro = dados.data.bairro;
              this.novoEndereco.nm_cidade = dados.data.localidade;
              this.novoEndereco.nm_estado = dados.data.uf;
              this.notificacao('CEP carregado!', 'success')
            });
        } else {
          //cep é inválido.
          //this.limpa_formulario_cep();
          this.notificacao('Formato de CEP inválido.', 'danger')
          alert("Formato de CEP inválido.");
        }
      } else {
        this.notificacao('Falha...', 'danger')
        //cep sem valor, limpa formulário.
        //this.limpa_formulario_cep();
      }
    },
    resetaNovoEndereco() {
      this.novoEndereco = {
        id_empresa: this.$route.params.id,
        fl_tipo_endereco: "",
        nm_nome_endereco: "",
        nm_pais: "Brasil",
        nu_cep: "",
        nm_estado: "",
        nm_cidade: "",
        nm_bairro: "",
        nm_endereco: "",
        nu_numero: "",
        nm_complemento: ""
      }
    },
    novo() {
      this.novoEndereco = {};
      this.resetaNovoEndereco();
      this.flNovoEndereco = true;
    },
    editar(value) {
      this.novoEndereco = structuredClone(this.propEnderecos.find(e => e.id_empresa_ender === value))
      this.flNovoEndereco = true;
    },
    fecharForm() {
      this.novoEndereco = {};
      this.resetaNovoEndereco();
      this.flNovoEndereco = false;
    },
    async salvar() {
      if (this.novoEndereco.id_empresa_ender !== undefined) {
        this.$delete(this.novoEndereco, 'created_at');
        this.$delete(this.novoEndereco, 'updated_at');
        this.$delete(this.novoEndereco, 'deleted_at');
      }
      await this.$http
        .post(`${adminApiUrl}/enderecos/salvar`, this.novoEndereco)
        .then(() => {
          this.notificacao('Endereço Atualizado!', 'success');
          this.fecharForm();
        })
        .catch(err => {
          this.notificacao('Erro salvando o Endereço!', 'danger');
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    async excluir(value) {
      if (confirm('Tem certeza que deseja excluir?')) {
        const data = { id_empresa_ender: value }
        await this.$http
          .post(`${adminApiUrl}/enderecos/excluir`, data)
          .then(() => {
            this.notificacao('Endereço Atualizado!', 'success');
            this.fecharForm();
          })
          .catch(err => {
            this.notificacao('Erro salvando o Endereço!', 'danger');
            console.log('Erro na API -> ', err);
            return false;
          });
      }
    },
    //Função de notificação
    notificacao(msg, tipo) {
      this.$notify({
        type: tipo,
        message: msg,
        icon: "tim-icons icon-bell-55",
      });
    }
  }
}
</script>

<style>

</style>
