<template>
  <div>
    <div class="row"><route-bread-crumb /></div>
    <card>
      <div slot="header"><h4 class="card-title">Table with Links</h4></div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="tableData">
            <el-table-column min-width="150" prop="nm_remetente" label="Nome">
            </el-table-column>
            <el-table-column min-width="200" prop="fl_assunto" label="Assunto">
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="datetime"
              align="center"
              label="Data"
            >
            </el-table-column>
            <el-table-column
              min-width="150"
              header-align="right"
              align="right"
              label="Ações"
            >
              <div
                slot-scope="{ $index }"
                class="text-right table-actions"
              >
                <el-tooltip
                  content="Refresh"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :type="$index > 2 ? 'success' : 'neutral'"
                    icon
                    size="sm"
                    class="btn-link"
                  >
                    <i class="tim-icons icon-refresh-01"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip
                  content="Delete"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :type="$index > 2 ? 'danger' : 'neutral'"
                    icon
                    size="sm"
                    class="btn-link"
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>
</template>
<script>

import StatsCard from 'src/components/Cards/StatsCard';
//import firebase from 'firebase/app'
//import { projectFirestore, timestamp } from '../../firebase/config_old'
import moment from "moment";
import { Table, TableColumn } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import { format, toDate } from 'date-fns'

export default {
  name: 'starter-page',
  components: {
    StatsCard,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    RouteBreadCrumb,
    BaseProgress,
  },
  data() {
    return {
      tableData: [],
      noticias: [],
      notificacoes: []
    };
  },
  methods: {
    /* async getMensagens() {
      const res = await projectFirestore.collection('msg-candidatos').get()
      this.tableData = res.docs.map(doc => {
        let dt = new Date(doc.data().datetime.seconds * 1000)
        let time = format(dt, 'dd/MM/yyyy - HH:mm')
        return { ...doc.data(), id: doc.id, datetime: time }
      })
    },
    async setCandidato() {
      const res = await projectFirestore.collection('candidatos').doc('edgard.michel@gmail.com').set({
        nome: "Edgard Arthur Michel",
        idMatchjob: 2,
        email: "edgard.michel@gmail.com",
        urlPhoto: "https://storagematchjobdefault.blob.core.windows.net/7823160matchjob/photo-profile-id-2-candidato.jpg"
      })
      console.log('Resultado da gravacao: ', res)
      const db = await projectFirestore.doc('candidatos/edgard.michel@gmail.com')
        .collection('notificacoes')
        .add({
        title: 'Exemplo de título de notificação',
        body: 'Exemplo de conteúdo de notificação',
        from: 'SAC MatchJob',
        link:  'https://notificacao.matchjob.com.br',
        icon: 'bell-55',
        color: 'primary',
        datetime: timestamp(),
        unread: true
      })
      console.log(db.id)
    }, */
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    //this.getMensagens();
  }
};
</script>
<style></style>
