import Vue from "vue";

export const userKey = "__knowledge_user";
export const adminApiUrl = "https://api.admin.matchjob.com.br";
//export const adminApiUrl = "http://api.admin.matchjob.local";
export const encryptKey = "J@NcRfUjXn2r5u8x/A?D(G+KbPdSgVkY";
export const cvAtual = "__cvAtual";

export function showError(e) {
  if (e && e.response && e.response.data) {
    Vue.toasted.global.defaultError({ msg: e.response.data });
  } else if (typeof e === "string") {
    Vue.toasted.global.defaultError({ msg: e });
  } else {
    Vue.toasted.global.defaultError();
  }
}

export default { adminApiUrl, showError, userKey };
