<template>
  <div>
    <div class="row mb">
      <div class="col-lg-2">
        <base-button type="success" size="sm" @click="salvarPgCarreiras">Salvar</base-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <JsonEditorVue
            v-model="propPagina"
            v-bind="{/* local props & attrs */}"
          />
      </div>
    </div>
    <!-- <pre>propPagina:{{ propPagina }}</pre> -->
  </div>
</template>

<script>
import Vue from 'vue'
import VCA from '@vue/composition-api'
import { adminApiUrl } from "@/global";
import JsonEditorVue from 'json-editor-vue'

Vue.use(VCA)

export default {
  name: "PaginaCarreiras",
  props: {
    propPagina: { type: Object }
  },
  components: {
    JsonEditorVue
  },
  data() {
    return {}
  },
  methods: {
    async salvarPgCarreiras() {
      const data = {
        id_empresa: this.$route.params.id,
        json_config_pg_vagas: this.propPagina
      }
      await this.$http
        .post(`${adminApiUrl}/empresa/atualizaconfigpg`, data)
        .then(() => {
          this.notificacao("Configurações atualizadas!", 'success')
          this.flSave = false;
        }
        ).catch(err => {
          this.notificacao("Falha na atualização!", 'dangar')
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    //Função de notificação
    notificacao(msg, tipo) {
      this.$notify({
        type: tipo,
        message: msg,
        icon: "tim-icons icon-bell-55",
      });
    }
  }
}
</script>

<style>

</style>
