<template>
  <card card-body-classes="table-full-width">
    <template v-for="(msg, index) in propMensagens">
      <div :key="index">
        <div class="row mt-2">
          <div class="col-5">
            <p>
              <template v-if="msg.fl_status === '1'">
                <i class="fas fa-envelope text-primary"></i>&nbsp;
              </template>
              <template v-else>
                <i class="fas fa-envelope-open"></i>&nbsp;
              </template>
              <span class="text-muted">Origem:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ retornaOrigem(msg.fl_origem) }}</span> - <span
                class="text-muted">Tipo:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ retornaTipoMensagem(msg.fl_tipo_mensagem) }}</span><br>
              <template v-if="msg.fl_assunto === '9'">
                <i class="text-success fas fa-check-circle"></i>
              </template> <span class="text-muted">Assunto:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ retornaAssunto(msg.fl_assunto) }}</span><br>
              <span class="text-muted">Para:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.nm_destinatario }}</span> <br>
              <span class="text-muted">De:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.nm_remetente }}</span> - <a :href="`mailto:${msg.hl_remetente}`">{{ msg.hl_remetente }}</a> <br>
              <span class="text-muted">Recebido em:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.created_at | formatDateTime }}</span> <span
                class="text-muted">Atualizado:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.updated_at | formatDateTime }}</span>
            </p>
          </div>
          <div class="col-4">
            <p>
              <template v-if="msg.fl_assunto === '9'">
                <span class="text-muted">Nome:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.json_mensagem.nome }}</span><br>
                <span class="text-muted">E-mail:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.json_mensagem.email }}</span><br>
                <span class="text-muted">Telefone:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.json_mensagem.telefone }}</span><br>
                <span class="text-muted">Empresa:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.json_mensagem.empresa }}</span><br>
                <span class="text-muted">Cargo:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.json_mensagem.cargo }}</span><br>
                <span class="text-muted">Vagas:</span> <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.json_mensagem.vagas ? msg.json_mensagem.vagas : msg.json_mensagem.funcionarios }}</span><br>
              </template>
              <template v-else>
                <span class="text-muted">Mensagem:</span><br>
                <span :class="msg.fl_status === '1' ? '' : 'text-muted'">{{ msg.tx_mensagem }}</span>
              </template>
            </p>
          </div>
          <div class="col-3">
            <template v-if="msg.fl_status === '1'">
              <span class="but-msg-read" @click="alteraStatus(msg.id_mensagem, '2')"><i class="far fa-envelope-open"></i> Marcar como Lida</span><br>
            </template>
            <template v-else>
              <span class="but-msg-read" @click="alteraStatus(msg.id_mensagem, '1')"><i class="far fa-envelope"></i> Marcar como Não Lida</span><br>
            </template>
            <span class="but-msg-delete" @click="deleteMsg(msg.id_mensagem)"><i class="fas fa-trash-alt"></i> Excluir</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <hr>
          </div>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import moment from "moment";

export default {
  name: "ShowMensagens",
  props: {
    propMensagens: { type: Array },
    funcAlterarStatus: { type: Function },
    funcDeletar: { type: Function }
  },
  data() {
    return {
      origem: [
        { id: "1", text: "Sistema (auto)" },
        { id: "2", text: "Dashboard" },
        { id: "3", text: "Site Publico" },
        { id: "4", text: "Recuperar Senha" },
        { id: "5", text: "Home do Site" },
        { id: "6", text: "SAC do Site" },
        { id: "7", text: "Ajuda do Site" },
      ],
      tipoMensagem: [
        { id: "1", text: "Sistema" },
        { id: "2", text: "Candidato -> MatchJob" },
        { id: "3", text: "Entrevista" },
        { id: "4", text: "Empresa -> MatchJob" },
        { id: "5", text: "Promoção" },
        { id: "6", text: "Publico -> MatchJob" },
      ],
      assuntoMensagem: [
        { id: "1", text: "Estou com Duvidas" },
        { id: "2", text: "Preciso relatar um problema" },
        { id: "3", text: "Quero enviar uma sugestão" },
        { id: "4", text: "Seja Bem-vindo à MatchJob" },
        { id: "5", text: "Novidades da MatchJob" },
        { id: "6", text: "Promoção da MatchJob" },
        { id: "7", text: "Outros Serviços da MatchJob" },
        { id: "8", text: "Recuperação de Conta" },
        { id: "9", text: "Novo Lead" },
      ]
    }
  },
  methods: {
    retornaOrigem(value) {
      var origem = this.origem.find(o => o.id === value)
      return origem.text;
    },
    retornaTipoMensagem(value) {
      var tipo = this.tipoMensagem.find(t => t.id === value)
      return tipo.text;
    },
    retornaAssunto(value) {
      var assunto = this.assuntoMensagem.find(a => a.id === value)
      return assunto.text;
    },
    alteraStatus(id, status) {
      var data = {
        id: id,
        status: status
      }
      this.$emit('alteraStatus', data);
      this.funcAlterarStatus()
    },
    deleteMsg(id) {
      this.$emit('deletar', id);
      this.funcDeletar();
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
        //return moment(String(value)).format("L");
      }
    }
  }
}
</script>

<style scoped>
.but-msg-read {
  cursor: pointer;
}
.but-msg-read:hover {
  cursor: pointer;
  color: white;
}
.but-msg-delete {
  cursor: pointer;
}
.but-msg-delete:hover {
  cursor: pointer;
  color: #fd5d93;
}
.font-unreaded {
  font-weight: bold;
}
</style>
