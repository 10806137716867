import Vue from 'vue';
import Router from 'vue-router';
import DashboardLayout from './../pages/Starter/SampleLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
import Dashboard from './../pages/Dashboard.vue';
import DashboardCandidato from './../pages/candidatos/DashboardCandidato.vue';
import MensagensCandidato from './../pages/candidatos/MensagensCandidato.vue';
import ListaCandidatos from './../pages/candidatos/ListaCandidatos.vue';
import DetalheCandidato from './../pages/candidatos/DetalheCandidato.vue';
import ListaEmpresas from './../pages/Empresas/ListaEmpresas.vue';
import DetalheEmpresa from './../pages/Empresas/DetalheEmpresa.vue';
import MensagemDashboard from './../pages/Mensagens/MensagemDashboard.vue';
import ListaAdmins from './../pages/Admins/ListaAdmins.vue';

const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');

//Opções
import ListagemOpcoes from './../pages/Opcoes/ListagemOpcoes.vue';
import Cargos from './../pages/Opcoes/Cargos.vue';
//import Starter from './../pages/Starter/SamplePage.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/login',
      component: AuthLayout,
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    },
    {
      path: '/home',
      name: 'home',
      redirect: '/home/dashboard',
      component: DashboardLayout,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          components: { default: Dashboard }
        }
      ]
    },
    {
      path: '/candidatos',
      name: 'Candidatos',
      redirect: '/candidatos/listacandidatos',
      component: DashboardLayout,
      children: [
        {
          path: 'dashboard',
          name: 'DashboardCandidato',
          components: { default: DashboardCandidato }
        },
        {
          path: 'mensagens',
          name: 'Mensagens',
          components: { default: MensagensCandidato }
        },
        {
          path: 'listacandidatos',
          name: 'Lista de Candidatos',
          components: { default: ListaCandidatos }
        },
        {
          path: 'detalhecandidato/:id',
          name: 'Detalhes do Candidato',
          components: { default: DetalheCandidato},
          props: true
        }
      ]
    },
    {
      path: '/empresas',
      name: 'Empresas',
      redirect: '/empresas/listaempresas',
      component: DashboardLayout,
      children: [
        {
          path: 'listaempresas',
          name: 'Lista de Empresas',
          components: { default: ListaEmpresas }
        },
        {
          path: 'detalheempresa/:id',
          name: 'Detalhes da Empresa',
          components: { default: DetalheEmpresa},
          props: true
        }
      ]
    },
    {
      path: '/mensagens',
      name: 'mensagens',
      component: DashboardLayout,
      redirect: '/mensagens/dashboard',
      children: [
        {
          path: 'dashboard',
          name: 'DashboardMensagens',
          components: { default: MensagemDashboard }
        }
      ]
    },
    {
      path: '/opcoes',
      name: 'Opcoes',
      redirect: '/opcoes/lista',
      component: DashboardLayout,
      children: [
        {
          path: 'lista',
          name: 'Listagem',
          components: { default: ListagemOpcoes }
        },
        {
          path: 'cargos',
          name: 'Cargos',
          components: { default: Cargos}
        }
      ]
    },
    {
      path: '/admin',
      name: 'Admin',
      redirect: '/admin/usuarios',
      component: DashboardLayout,
      children: [
        {
          path: 'usuarios',
          name: 'UsuariosAdmins',
          components: { default: ListaAdmins }
        }
      ]
    }
  ],
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});
