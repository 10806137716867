<template>
  <div class="content">
    <div class="row"><route-bread-crumb /></div>
    <div class="row">
      <div class="col-md-12">
        <card>
          <template slot="header">
            <div class="row">
              <div class="col-1">
                <div class="author">
                  <img
                    :src="candidato.hl_foto"
                    class="circle"
                    alt="Imagem de Perfil"
                  />
                </div>
              </div>
              <div class="col-11">
                <h5 class="card-category">Detalhes do Candidato</h5>
                <h3 class="card-title">
                  {{ candidato.nm_nome }} - # {{ candidato.id_candidato }}
                </h3>
              </div>
            </div>
          </template>

          <tabs type="primary">
            <tab-pane label="Geral">
              <card v-if="encerrado">
                <div class="row">
                  <div class="col-1 icon-encerramento">
                    <i class="tim-icons icone icon-bell-55"></i>
                  </div>
                  <div class="col-11">
                    <h3 class="card-title">Conta Encerrada!</h3>
                    <h5 class="card-subtitle">
                      <small class="text-muted">Data da Solicitação:</small>
                      {{ encerrado.created_at | formatDateTime }}
                    </h5>
                    <p class="card-text">
                      <strong>Motivo:</strong> {{ encerrado.tx_motivo }}
                    </p>
                  </div>
                </div>
              </card>
              <card>
                <div class="row"><!-- Linha  1 - ID / Status / Nome / Email / Membro desde -->
                  <div class="col-1">
                    <p>
                      ID: <strong>{{ candidato.id_candidato }}</strong>
                    </p>
                  </div>
                  <div class="col-2">
                    <p>Status: <badge :type="badgeStatusType(candidato.fl_status)">{{
                        badgeStatus(candidato.fl_status)
                      }}</badge>
                    </p>
                  </div>
                  <div class="col-3">
                    <p>
                      Nome: <strong>{{ candidato.nm_nome }}</strong>
                    </p>
                  </div>
                  <div class="col-3">
                    <p>
                      E-mail:
                      <a
                        class="card-link"
                        :href="`mailto:${candidato.hl_email}`"
                        >{{ candidato.hl_email }}</a
                      >
                    </p>
                  </div>
                  <div class="col-3">
                    <p>
                      Membro desde: {{ candidato.created_at | formatDateTime }}
                    </p>
                  </div>
                </div>
                <div class="row mt-4" v-if="cvpro"><!-- Linha 2 - CV Pro/V-IF -->
                  <div class="col-3">
                    <p>Status CV Pro: <badge :type="badgeCvproType(cvpro.fl_status)">{{
                         formatCvpro(cvpro.fl_status)
                      }}</badge></p>
                  </div>
                  <div class="col-3">
                    <p>Inicio: {{ cvpro.dt_ativacao | formatDate }}</p>
                  </div>
                  <div class="col-3">
                    <p>Término: {{ cvpro.dt_fim | formatDate }}</p>
                  </div>
                </div>
                <div class="row mt-4" v-else><!-- Linha 2 - CV Pro /V-ELSE  -->
                  <div class="col-3">
                    <p>Status CV Pro: <badge type="default">N/A</badge></p>
                  </div>
                  <div class="col-3">
                    <p>Inicio: <strong>N/A</strong></p>
                  </div>
                  <div class="col-3">
                    <p>Término: <strong>N/A</strong></p>
                  </div>
                </div>
                <div class="row mt-4" v-if="logins[0]"><!-- Linha 3 - Ultimos Logins/V-IF -->
                  <div class="col-2">
                    <p>
                      Qtd. Acessos: <strong>{{ qtdLogins }}</strong>
                    </p>
                  </div>
                  <div class="col-3">
                    <p>Último Acesso: <strong>{{ logins[0].data | formatDateTime }}</strong></p>
                  </div>
                  <div class="col-2">
                    <p>Tipo de Dispositivo: <strong>{{ logins[0].ismobile == 's' ? "Móvel" : "PC" }}</strong></p>
                  </div>
                  <div class="col-2" v-if="logins[0].ismobile == 's'">
                    <p>Dispositivo: <strong>{{ logins[0].mobile }}</strong></p>
                  </div>
                  <div class="col-2">
                    <p>Navegador: <strong>{{ logins[0].navegador }}</strong></p>
                  </div>
                  <div class="col-1">
                    <p>SO: <strong>{{ logins[0].so }}</strong></p>
                  </div>
                </div>
                <div class="row mt-4" v-else><!-- Linha 3 - Ultimos Logins/V-ELSE -->
                  <div class="col-3">
                    <p>
                      Qtd. Acessos: <strong>Nunca Entrou</strong>
                    </p>
                  </div>
                  <div class="col-2">
                    <p>Último Acesso: <strong>N/A</strong></p>
                  </div>
                  <div class="col-2">
                    <p>Tipo de Dispositivo: <strong>N/A</strong></p>
                  </div>
                  <div class="col-2">
                    <p>Dispositivo: <strong>N/A</strong></p>
                  </div>
                  <div class="col-2">
                    <p>Navegador: <strong>N/A</strong></p>
                  </div>
                  <div class="col-1">
                    <p>SO: <strong>N/A</strong></p>
                  </div>
                </div>
              </card>
              <card>
                <template slot="header">
                  <h4 class="card-title">Ações Rápidas</h4>
                </template>
                <div class="row">
                  <div class="col">
                    <base-button :type="butBloqueioType" round @click="handleBloqueio(butBloqueioType)">{{ butBloqueioText }}</base-button>
                  </div>
                  <div class="col">
                    <base-button type="primary" round @click="resetSenha">Resetar Senha</base-button>
                  </div>
                  <div class="col">
                    <base-button type="primary" round @click="modalSenha = true">Definir Senha</base-button>
                  </div>
                </div>
              </card>
              <modal :show.sync="modalSenha" body-classes="p-0"
                modal-classes="modal-dialog-centered modal-sm">
                <card type="secondary" body-classes="px-lg-5 py-lg-5"
                  class="border-0 mb-0">
                  <h6 slot="header" class="modal-title" id="modal-title-default">Definir nova senha</h6>
                  <form>
                    <base-input alternative
                      class="col-md-6 mb-3"
                      type="password"
                      label="Nova Senha"
                      v-model="novaSenha"
                      placeholder="Senha"
                      :required="true"
                    />
                    <base-input alternative
                      class="col-md-6 mb-3"
                      type="password"
                      label="Repetir Senha"
                      v-model="novaSenhaVerifica"
                      placeholder="Senha"
                      :required="true"
                    />
                  </form>
                  <template slot="footer">
                    <base-button type="primary" class="m-3" @click="defineSenha">Salvar</base-button>
                    <base-button type="secondary" class="m-3" @click="modalSenha = false">Cancelar
                    </base-button>
                  </template>
                </card>
              </modal>
            </tab-pane>

            <tab-pane label="Dados">
              <collapse :multiple-active="true" :active-index="0">
                <collapse-item title="Dados de Cadastro">
                  <card>
                    <form>
                      <div class="form-row">
                        <base-input
                          class="col-md-6"
                          type="text"
                          label="Nome"
                          v-model="candidato.nm_nome"
                          placeholder="Nome"
                        />
                        <base-input
                          class="col-md-6"
                          type="text"
                          label="CPF"
                          v-model="candidato.nu_cpf"
                          placeholder="CPF"
                        />
                      </div>
                      <div class="form-row">
                        <base-input
                          class="col-md-4"
                          type="email"
                          label="E-mail"
                          v-model="candidato.hl_email"
                          placeholder="E-mail"
                        />
                        <base-input
                          class="col-md-4"
                          type="text"
                          label="Celular"
                          v-model="candidato.nu_celular"
                          placeholder="Celular"
                        />
                        <base-input
                          class="col-md-4"
                          type="text"
                          label="Telefone"
                          v-model="candidato.nu_telefone"
                          placeholder="Telefone"
                        />
                      </div>
                      <div class="form-row">
                        <base-input
                          class="col-md-3"
                          type="date"
                          label="Data de Nascimento"
                          v-model="candidato.dt_nasc"
                          placeholder="Data de Nascimento"
                        />
                        <base-input class="col-md-3" label="Gênero">
                          <el-select
                            class="select-primary"
                            placeholder="Gênero"
                            v-model="candidato.fl_sexo"
                          >
                            <el-option
                              class="select-primary"
                              v-for="gender in selects.genders"
                              :key="gender.id"
                              :value="gender.id"
                              :label="gender.label"
                            ></el-option>
                          </el-select>
                        </base-input>
                        <base-input class="col-md-3" label="Estado Civil">
                          <el-select
                            class="select-primary"
                            placeholder="Estado Civil"
                            v-model="candidato.fl_estadocivil"
                          >
                            <el-option
                              class="select-primary"
                              v-for="estadocivil in selects.estadoscivil"
                              :key="estadocivil.label"
                              :value="estadocivil.id"
                              :label="estadocivil.label"
                            ></el-option>
                          </el-select>
                        </base-input>
                        <base-input class="col-md-3" label="Nacionalidade">
                          <el-select
                            class="select-primary"
                            placeholder="Nacionalidade"
                            v-model="candidato.nu_nacionalidade"
                          >
                            <el-option
                              class="select-primary"
                              v-for="nac in nacionalidade"
                              :key="nac.id_nacionalidade"
                              :value="nac.id_nacionalidade"
                              :label="nac.nm_pais"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <hr />
                      <base-button type="primary" @click="atualizaDados"
                        >Atualiza Dados</base-button
                      >
                    </form>
                  </card>
                </collapse-item>
                <collapse-item title="Endereço">
                  <card>
                    <form>
                      <div class="form-row">
                        <base-input
                          class="col-md-2"
                          type="text"
                          label="CEP"
                          id="cep"
                          name="cep"
                          v-mask="'#####-###'"
                          @blur.prevent="atualizaCep()"
                          v-model="candidato.nu_cep"
                          placeholder="CEP"
                        />
                        <base-input
                          class="col-md-8"
                          type="text"
                          label="Endereço"
                          v-model="candidato.nm_endereco"
                          placeholder="Endereço"
                        />
                        <base-input
                          class="col-md-2"
                          type="text"
                          label="Numero"
                          v-model="candidato.nu_numero"
                          placeholder="Numero"
                        />
                      </div>
                      <div class="form-row">
                        <base-input
                          class="col-md-4"
                          type="text"
                          label="Complemento"
                          v-model="candidato.nm_complemento"
                          placeholder="Complemento"
                        />
                        <base-input
                          class="col-md-3"
                          type="text"
                          label="Bairro"
                          v-model="candidato.nm_bairro"
                          placeholder="Bairro"
                        />
                        <base-input
                          class="col-md-3"
                          type="text"
                          label="Cidade"
                          v-model="candidato.nm_cidade"
                          placeholder="Cidade"
                        />
                        <base-input
                          class="col-md-2"
                          type="text"
                          label="Estado"
                          v-model="candidato.nm_estado"
                          placeholder="Estado"
                        />
                      </div>
                      <hr />
                      <base-button type="primary" @click="atualizaDados"
                        >Atualizar Endereço</base-button
                      >
                    </form>
                  </card>
                </collapse-item>
                <collapse-item title="Midias Sociais">
                  <card>
                    <form>
                      <div class="form-row">
                        <base-input
                          class="col-md-8"
                          type="text"
                          label="Site Pessoal"
                          v-model="candidato.hl_site_pessoal"
                          placeholder="Site Pessoal"
                        />
                        <div class="pt-3">
                          <el-tooltip content="Visitar" effect="light" :open-delay="200" placement="right">
                            <base-button class="btn btn-icon btn-round btn-primary" tag="a" :href="candidato.hl_site_pessoal" role="base-button" target="_blank">
                              <i class="fas fa-house-user"></i>
                            </base-button>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="form-row">
                        <base-input
                          class="col-md-8"
                          type="text"
                          label="Skype"
                          v-model="candidato.hl_skype"
                          placeholder="Skype"
                        />
                        <div class="pt-3">
                          <el-tooltip content="Não é possível visitar" :open-delay="200" placement="right">
                            <button class="btn btn-icon btn-round btn-primary">
                              <i class="fab fa-skype"> </i>
                            </button>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="form-row">
                        <base-input
                          class="col-md-8"
                          type="text"
                          label="YouTube"
                          v-model="candidato.hl_google"
                          placeholder="YouTube"
                        />
                        <div class="pt-3">
                          <el-tooltip content="Visitar" effect="light" :open-delay="200" placement="right">
                            <button class="btn btn-icon btn-round btn-youtube" tag="a" :href="candidato.hl_google" role="base-button" target="_blank">
                              <i class="fab fa-youtube"> </i>
                            </button>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="form-row">
                        <base-input
                          class="col-md-8"
                          type="text"
                          label="Facebook"
                          v-model="candidato.hl_facebook"
                          placeholder="Facebook"
                        />
                        <div class="pt-3">
                          <el-tooltip content="Visitar" effect="light" :open-delay="200" placement="right">
                            <button class="btn btn-icon btn-round btn-facebook" tag="a" :href="candidato.hl_facebook" role="base-button" target="_blank">
                              <i class="fab fa-facebook-f"> </i>
                            </button>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="form-row">
                        <base-input
                          class="col-md-8"
                          type="text"
                          label="LinkedIn"
                          v-model="candidato.hl_linkedin"
                          placeholder="LinkedIn"
                        />
                        <div class="pt-3">
                          <el-tooltip content="Visitar" effect="light" :open-delay="200" placement="right">
                            <button class="btn btn-icon btn-round btn-linkedin" tag="a" :href="candidato.hl_linkedin" role="base-button" target="_blank">
                              <i class="fab fa-linkedin"></i>
                            </button>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="form-row">
                        <base-input
                          class="col-md-8"
                          type="text"
                          label="Twitter"
                          v-model="candidato.hl_twitter"
                          placeholder="Twitter"
                        />
                        <div class="pt-3">
                          <el-tooltip content="Visitar" effect="light" :open-delay="200" placement="right">
                            <button class="btn btn-icon btn-round btn-twitter" tag="a" :href="candidato.hl_twitter" role="base-button" target="_blank">
                              <i class="fab fa-twitter"></i>
                            </button>
                          </el-tooltip>
                        </div>
                      </div>
                      <hr />
                      <base-button type="primary" @click="atualizaDados"
                        >Atualizar Midias</base-button
                      >
                    </form>
                  </card>
                </collapse-item>
              </collapse>
            </tab-pane>

            <tab-pane label="CV Pro">
              <card><!-- Status do CV Pro -->
                <div class="row mt-4" v-if="cvpro"><!-- Linha 2 - CV Pro/V-IF -->
                  <div class="col-3">
                    <p>Status CV Pro: <badge :type="badgeCvproType(cvpro.fl_status)">{{
                         formatCvpro(cvpro.fl_status)
                      }}</badge></p>
                  </div>
                  <div class="col-3">
                    <p>Inicio: {{ cvpro.dt_ativacao | formatDate }}</p>
                  </div>
                  <div class="col-3">
                    <p>Término: {{ cvpro.dt_fim | formatDate }}</p>
                  </div>
                </div>
                <div class="row mt-4" v-else><!-- Linha 2 - CV Pro /V-ELSE  -->
                  <div class="col-3">
                    <p>Status CV Pro: <badge type="default">N/A</badge></p>
                  </div>
                  <div class="col-3">
                    <p>Inicio: <strong>N/A</strong></p>
                  </div>
                  <div class="col-3">
                    <p>Término: <strong>N/A</strong></p>
                  </div>
                </div>
              </card>
              <card><!-- Alteração do CV Pro -->
                <template slot="header">
                  <h4 class="card-title">Alterar CV Pro</h4>
                </template>
                <form>
                  <div class="form-row" v-if="cvpro"><!-- CV Pro Existente -->
                    <base-input label="Status">
                      <select class="form-control" id="selectStatusCvpro" v-model="cvpro.fl_status">
                        <option value="1">Ativo</option>
                        <option value="2">Vencido</option>
                      </select>
                    </base-input>
                    <base-input
                      class="col-md-2"
                      type="date"
                      label="Data de Inicio"
                      v-model="cvpro.dt_ativacao"
                      placeholder="Data de Inicio"
                    />
                    <base-input
                      class="col-md-2"
                      type="date"
                      label="Data Fim"
                      v-model="cvpro.dt_fim"
                      placeholder="Data Fim"
                    />
                  </div>
                  <div class="form-row" v-else><!-- CV Pro Novo -->
                    <base-input label="Status">
                      <select class="form-control" id="selectStatusCvpro" v-model="novoCvpro.fl_status" placeholder="Selecione">
                        <option value="1" selected>Ativo</option>
                        <option value="2">Vencido</option>
                      </select>
                    </base-input>
                    <base-input
                      class="col-md-2"
                      type="date"
                      label="Data de Inicio"
                      v-model="novoCvpro.dt_ativacao"
                      placeholder="Data de Inicio"
                    />
                    <base-input
                      class="col-md-2"
                      type="date"
                      label="Data Fim"
                      v-model="novoCvpro.dt_fim"
                      placeholder="Data Fim"
                    />
                  </div>
                  <div class="form-row">
                    <base-button type="primary" native-type="Submit" @click="atualizaCvpro">Atualizar CV Pro</base-button>
                  </div>
                </form>
              </card>
            </tab-pane>

            <tab-pane label="Privacidade">
              <div class="row">
                <div class="col-6">
                  <card>
                    <template slot="header">
                      <h4 class="card-title">Opções de Privacidade</h4>
                    </template>
                    <div class="form-row">
                      <div class="col-8">
                        <span>Ocultar dados de contato<br><small>Empresas não terão acesso ao e-mail e telefone</small></span>
                      </div>
                      <div class="col-1 align-items-center">
                        <base-switch
                          v-model="candidato.privacidade.fl_ocultar_dados"
                          type="primary"
                          on-text="Sim"
                          off-text="Não"
                        ></base-switch>
                      </div>
                    </div>
                    <div class="form-row mt-2">
                      <div class="col-8">
                        <span>Ocultar currículo público<br><small>O link do currículo publico não será carregado</small></span>
                      </div>
                      <div class="col-1 align-items-center">
                        <base-switch
                          v-model="candidato.privacidade.fl_cv_publico"
                          type="primary"
                          on-text="Sim"
                          off-text="Não"
                        ></base-switch>
                      </div>
                    </div>
                    <div class="form-row mt-2">
                      <div class="col-8">
                        <span>Ocultar totalmente o perfil<br><small>O candidato não será apresentado em pesquisas</small></span>
                      </div>
                      <div class="col-1 align-items-center">
                        <base-switch
                          v-model="candidato.privacidade.fl_ocultar_perfil"
                          type="primary"
                          on-text="Sim"
                          off-text="Não"
                        ></base-switch>
                      </div>
                    </div>
                  </card>
                </div>
                <div class="col-6">
                  <card>
                    <template slot="header">
                      <h4 class="card-title">E-mails e Comunicação</h4>
                    </template>
                    <div class="form-row">
                      <div class="col-8">
                        <span>Receber avisos de vagas<br><small>Avisos de vagas gratuitos conforme perfil</small></span>
                      </div>
                      <div class="col-1 align-items-center">
                        <base-switch
                          v-model="candidato.privacidade.fl_avisos_grat"
                          type="primary"
                          on-text="Sim"
                          off-text="Não"
                        ></base-switch>
                      </div>
                    </div>
                    <div class="form-row mt-2">
                      <div class="col-8">
                        <span>Receber avisos de vagas patrocinados<br><small>Avisos de vagas patrocinadas ou urgentes</small></span>
                      </div>
                      <div class="col-1 align-items-center">
                        <base-switch
                          v-model="candidato.privacidade.fl_avisos_patroc"
                          type="primary"
                          on-text="Sim"
                          off-text="Não"
                        ></base-switch>
                      </div>
                    </div>
                    <div class="form-row mt-2">
                      <div class="col-8">
                        <span>Receber Newsletters da MatchJob<br><small>Todas as novidades da MatchJob</small></span>
                      </div>
                      <div class="col-1 align-items-center">
                        <base-switch
                          v-model="candidato.privacidade.fl_news"
                          type="primary"
                          on-text="Sim"
                          off-text="Não"
                        ></base-switch>
                      </div>
                    </div>
                    <div class="form-row mt-2">
                      <div class="col-8">
                        <span>E-mails de publicidade de empresas parceiras<br><small>Avisos de promoções e outros patrocinados</small></span>
                      </div>
                      <div class="col-1 align-items-center">
                        <base-switch
                          v-model="candidato.privacidade.fl_marketing_3"
                          type="primary"
                          on-text="Sim"
                          off-text="Não"
                        ></base-switch>
                      </div>
                    </div>
                    <div class="form-row mt-2">
                      <div class="col-8">
                        <span>Receber avisos de mensagens privadas<br><small>Avisos de recebimento de mensagens privadas</small></span>
                      </div>
                      <div class="col-1 align-items-center">
                        <base-switch
                          v-model="candidato.privacidade.fl_msg_priv"
                          type="primary"
                          on-text="Sim"
                          off-text="Não"
                        ></base-switch>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <base-button type="primary" native-type="Submit" @click="atualizaPrivacidade">Atualizar</base-button>
                </div>
              </div>
            </tab-pane>

            <tab-pane label="Currículos">
              <card>
                <div v-if="candidato.curriculos.length > 0">
                  <div class="row" v-for="cv in candidato.curriculos" :key="cv.id_curriculo">
                    <div class="col-2">
                      <p>Nome do CV: <strong>{{ cv.nm_curriculo}}</strong></p>
                    </div>
                    <div class="col-1">
                      <p>{{ cv.fl_principal === '1' ? "Principal" : "Secundário" }} <i class="tim-icons icon-check-bold"></i></p>
                    </div>
                    <div class="col-3">
                      <p>Última atualização: <strong>{{ cv.updated_at | formatDateTime }}</strong></p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row">
                    <div class="col-1 icon-encerramento">
                      <i class="tim-icons icone icon-bell-55"></i>
                    </div>
                    <div class="col align-items-center">
                      <p>Não há currículos cadastrados</p>
                    </div>
                  </div>
                </div>
                <!-- <pre>{{candidato.curriculos}}</pre> -->
                <template v-for="(cvs, index) in candidato.curriculos">
                  <div :key="index">
                    <div class="row">
                      <div class="col-12">
                        nm_cargo -> <code>{{cvs.json_curriculo.nm_cargo}}</code><br>
                        nm_area -> <code>{{cvs.json_curriculo.nm_area}}</code><br>
                        nm_nivel -> <code>{{cvs.json_curriculo.nm_nivel}}</code><br>
                        nm_cidade -> <code>{{cvs.json_curriculo.nm_cidade}}</code><br>
                        fl_jornada -> <code>{{cvs.json_curriculo.fl_jornada}}</code><br>
                        nu_salario -> <code>{{cvs.json_curriculo.nu_salario}}</code><br>
                        fl_tipo_contrato -> <code>{{cvs.json_curriculo.fl_tipo_contrato}}</code><br>
                        fl_pj -> <code>{{cvs.json_curriculo.fl_pj}}</code><br>
                        fl_primeiro_emprego -> <code>{{cvs.json_curriculo.fl_primeiro_emprego}}</code><br>
                        idiomas -> [
                          <div v-for="(idiomas, index) in cvs.json_curriculo.idiomas" :key="index">
                            <code>{{idiomas}}</code><br>
                          </div>]<br>
                        formacoes -> [
                          <div v-for="(formacao, index) in cvs.json_curriculo.formacoes" :key="index">
                            <code>{{formacao}}</code><br>
                          </div>]<br>
                        competencias -> [
                          <div v-for="(comp, index) in cvs.json_curriculo.competencias" :key="index">
                            <code>{{comp}}</code><br>
                          </div>]<br>
                        experiencias -> [
                          <div v-for="(exp, index) in cvs.json_curriculo.experiencias" :key="index">
                            <code>{{exp}}</code><br>
                          </div>]<br>
                        certificacoes -> [
                          <div v-for="(cert, index) in cvs.json_curriculo.certificacoes" :key="index">
                            <code>{{cert}}</code><br>
                          </div>]<br>
                        tx_resumo -> <code>{{cvs.json_curriculo.tx_resumo}}</code><br>
                        tx_info_extra -> <code>{{cvs.json_curriculo.tx_info_extra}}</code><br>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <pre>{{Object.keys(cvs.json_curriculo)}}</pre>
                      </div>
                      <div class="col-6">
                        <pre>{{Object.keys(cvs.json_analise_ia)}}</pre>
                      </div>
                    </div>
                  </div>
                </template>
                <pre>{{candidato}}</pre>
              </card>
            </tab-pane>

            <tab-pane label="Candidaturas">
              <card>
                <div v-if="candidato.candidaturas[0]">
                  <div class="row">
                    <div class="col">
                      <p>Quantidade de candidaturas: <strong>{{ candidato.candidaturas.length }}</strong></p>
                    </div>
                  </div>
                  <div>
                    <el-table :data="candidato.candidaturas">
                      <el-table-column
                        min-width="50"
                        prop="created_at"
                        label="Data"
                        :formatter="formatTimestamp">
                      </el-table-column>
                      <el-table-column
                        min-width="30"
                        prop="fl_visualizado"
                        label="Visualizado"
                        align="center"
                      >
                        <div slot-scope="{ row }">
                          <p>{{ row.fl_visualizado === "1" ? "Sim" : "Não"}}</p>
                        </div>
                      </el-table-column>
                      <el-table-column
                        min-width="50"
                        prop="id_curriculo"
                        label="Currículo"
                      >
                        <div slot-scope="{ row }">
                          <p>{{ nomeCurriculo(row.id_curriculo) }}</p>
                        </div>
                      </el-table-column>
                      <el-table-column
                        min-width="200"
                        prop="id_vaga"
                        label="Vaga"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div v-else>
                  <div class="row">
                    <div class="col">
                      <p>Nenhuma candidatura foi encontrada.</p>
                    </div>
                  </div>
                </div>
              </card>
            </tab-pane>

            <tab-pane label="Mensagens">
              Completely synergize resource taxing relationships via premier
              niche markets. Professionally cultivate one-to-one customer
              service with robust ideas. <br /><br />Dynamically innovate
              resource-leveling customer service for state of the art customer
              service.
            </tab-pane>
          </tabs>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { TabPane, Tabs, Badge, Collapse, CollapseItem, BaseSwitch, Modal } from "src/components";
//import { projectFirestore, timestamp } from "../../firebase/config";
import { adminApiUrl } from "@/global";
import moment from "moment";
import { Table, TableColumn, Select, Option, Tooltip } from "element-ui";
import { RouteBreadCrumb } from "src/components";
import { VueMaskDirective } from "v-mask";
import axios from "axios";
import swal from "sweetalert2";

export default {
  name: "detalhe-candidato",
  props: ["id"],
  directives: { mask: VueMaskDirective },
  components: {
    TabPane,
    Tabs,
    Badge,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Collapse,
    CollapseItem,
    Tooltip,
    BaseSwitch,
    Modal
  },
  data() {
    return {
      //id: this.$route.params.id,
      candidato: [],
      //curriculo: [],
      cvpro: [],
      encerrado: [],
      qtdLogins: "",
      logins: [],
      novoCvpro: [
        {fl_status: 1, dt_ativacao: "", dt_fim: "" }
      ],
      noticias: [],
      notificacoes: [],
      nacionalidade: [],
      selects: {
        genders: [
          { value: 1, label: "Masculino" },
          { value: 2, label: "Feminino" },
          { value: 3, label: "Prefiro não informar" },
        ],
        estadoscivil: [
          { value: 1, label: "Solteiro(a)" },
          { value: 2, label: "Casado(a)" },
          { value: 3, label: "Divorciado(a)" },
          { value: 4, label: "União Estável" },
          { value: 5, label: "Viúvo(a)" },
        ],
      },
      modalSenha: false,
      novaSenha: '',
      novaSenhaVerifica: '',
      butBloqueioType: "warning",
      butBloqueioText: "Bloquear"
    };
  },
  computed: {},
  methods: {
    getCandidato() {
      this.$http
        .get('candidato', {
          params: { id_candidato: this.$route.params.id },
        })
        .then((res) => {
          this.candidato = res.data;
          this.encerrado = res.data.encerramento[0];
          this.cvpro = res.data.cvpro[0];
          for (let priv in this.candidato.privacidade) {
            this.candidato.privacidade[priv] = this.setSwtichsValue(
              this.candidato.privacidade[priv]
            );
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    setSwtichsValue(value) {
      if (value === "1") {
        return true;
      } else {
        return false;
      }
    },
    getNacionalidade() {
      this.$http
        .get('sysinfos/nacionalidade')
        .then((res) => {
          this.nacionalidade = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    atualizaPrivacidade() {
      const data = {
        id_priv_candidato: this.candidato.privacidade.id_priv_candidato,
        id_candidato: this.candidato.id_candidato,
        fl_ocultar_perfil: this.candidato.privacidade.fl_ocultar_perfil,
        fl_ocultar_dados: this.candidato.privacidade.fl_ocultar_dados,
        fl_cv_publico: this.candidato.privacidade.fl_cv_publico,
        fl_avisos_grat: this.candidato.privacidade.fl_avisos_grat,
        fl_avisos_patroc: this.candidato.privacidade.fl_avisos_patroc,
        fl_news: this.candidato.privacidade.fl_news,
        fl_marketing_3: this.candidato.privacidade.fl_marketing_3,
        fl_msg_priv: this.candidato.privacidade.fl_msg_priv
      };
      this.$http
        .post('candidatos/atualizaprivacidade', data)
        .then(() => {
          this.$notify({
            type: "success",
            message: "Informações de privacidade atualizadas!",
            icon: "tim-icons icon-bell-55",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    atualizaCvpro() {
      let data = null;
      if (this.candidato.cvpro[0]) {
        data = {
          id_cv_pro: this.candidato.cvpro[0].id_cv_pro,
          id_candidato: this.candidato.id_candidato,
          fl_status: this.candidato.cvpro[0].fl_status,
          dt_ativacao: this.candidato.cvpro[0].dt_ativacao,
          dt_fim: this.candidato.cvpro[0].dt_fim
        };
      } else {
        data = {
          id_candidato: this.candidato.id_candidato,
          fl_status: this.novoCvpro.fl_status,
          dt_ativacao: this.novoCvpro.dt_ativacao,
          dt_fim: this.novoCvpro.dt_fim
        };
      }
      this.$http
        .post('cvpro/salvacvpro', data)
        .then(() => {
          this.$notify({
            type: "success",
            message: "CV Pro atualizado com sucesso!!",
            icon: "tim-icons icon-bell-55",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            type: "warning",
            message: "Não foi possível atualizar as informações.",
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    atualizaDados() {
      const data = {
        id_candidato: this.candidato.id_candidato,
        nm_nome: this.candidato.nm_nome,
        hl_email: this.candidato.hl_email,
        nu_cpf: this.candidato.nu_cpf,
        dt_nasc: this.candidato.dt_nasc,
        fl_sexo: this.candidato.fl_sexo,
        fl_estadocivil: this.candidato.fl_estadocivil,
        nu_nacionalidade: this.candidato.nu_nacionalidade,
        nu_celular: this.candidato.nu_celular,
        nu_numero: this.candidato.nu_numero,
        nu_cep: this.candidato.nu_cep,
        nm_estado: this.candidato.nm_estado,
        nm_cidade: this.candidato.nm_cidade,
        nm_bairro: this.candidato.nm_bairro,
        nm_endereco: this.candidato.nm_endereco,
        nm_complemento: this.candidato.nm_complemento,
        hl_foto: this.candidato.hl_foto,
        fl_empregado: this.candidato.fl_empregado,
        fl_deficiencia: this.candidato.fl_deficiencia,
        nm_deficiencia: this.candidato.nm_deficiencia,
        hl_facebook: this.candidato.hl_facebook,
        hl_google: this.candidato.hl_google,
        hl_linkedin: this.candidato.hl_linkedin,
        hl_site_pessoal: this.candidato.hl_site_pessoal,
        hl_skype: this.candidato.hl_skype,
        hl_twitter: this.candidato.hl_twitter,
        fl_status: this.candidato.fl_status
      };
      this.$http
        .post('candidato/updatecandidato', data)
        .then(() => {
         this.$notify({
            type: "success",
            message: "Dados atualizados com sucesso!!",
            icon: "tim-icons icon-bell-55",
          });
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            type: "success",
            message: "Não foi possível atualizar as informações.",
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    resetSenha() {
      const data = {
        hl_email: this.candidato.hl_email
      };
      this.$http
        .post('candidato/novasenha', data)
        .then(() => {
          this.$notify({
            type: "success",
            message: "Nova senha enviada por e-mail!",
            icon: "tim-icons icon-bell-55",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            type: "warning",
            message: "Não foi possível resetar a senha.",
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    defineSenha() {
      if (
        this.novaSenha.length >= 8 &&
        this.novaSenha === this.novaSenhaVerifica
      ) {
        const data = {
          id_candidato: this.candidato.id_candidato,
          senha: this.novaSenha,
          confirmasenha: this.novaSenhaVerifica
        };
        this.$http
          .post('candidato/atualizasenha', data)
          .then(() => {
            this.modalSenha = false;
            this.$notify({
              type: "success",
              message: "Nova senha definida!",
              icon: "tim-icons icon-bell-55",
            });
          });
      } else {
        this.$notify({
          type: "warning",
          message: "As senhas não conferem!",
          icon: "tim-icons icon-bell-55",
        });
      }
    },
    defineBotaoBloqueio(value) {
      if (value === "1" || value === "2") {
        this.butBloqueioType = "warning";
        this.butBloqueioText = "Bloqueado";
      } else if (value > 2) {
        this.butBloqueioType = "success";
        this.butBloqueioText = "Liberar";
      }
    },
    handleBloqueio(tipo) {
      let statusAlert = "warning"
      let block = true;
      if (tipo === "warning"){
        statusAlert = "Bloqueado";
        block = true;
      } else {
        statusAlert = "Liberado";
        block = false;
      }
      swal.fire({
        title: 'ATENÇÃO!',
        text: `Este candidato será ${statusAlert} para entrar na MatchJob`,
        icon: tipo,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: `Sim, ${statusAlert}!`,
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          if (this.bloquearCandidato(block)){
            swal.fire({
              title: `${statusAlert}!`,
              text: `O candidato: ${this.candidato.id_candidato} - ${this.candidato.nm_nome} está ${statusAlert}!`,
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          } else {
            swal.fire({
              title: 'Ops!!',
              text: 'Houve uma falha no servidor.',
              icon: 'error',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          }
        }
      });
    },
    async bloquearCandidato(status) {
      let block = ''
      if (status){
        block = "4";
      } else {
        block = "2";
      }
      const data = {
        id_candidato: this.candidato.id_candidato,
        fl_status: block
      }
      await this.$http
        .post('candidato/bloquearcandidato', data)
        .then(() => {
          this.candidato.fl_status = block;
          this.defineBotaoBloqueio(block);
          return true;
        })
        .catch(err => {
          console.log('Erro na API -> ', err)
          return false;
        });
    },
    /*async getPosts() {
      const db = projectFirestore.collection("candidatos").doc("4442562882");
      const res = await db.collection("notificacoes").get();
      console.log("Retorno do CPF: 4442562882 - ", res);
      this.noticias = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      console.log("As notificacoes: ", this.noticias);
      //const db = projectFirestore.collection('candidatos')
      //const res = await db.doc('21929186827').collection('notificacoes').get()
      //console.log(res)
      //const res = await projectFirestore.collection("candidatos").doc('21929186827').orderBy("datetime", "desc").get();
      //this.noticias = res.docs.map(doc => {
        //console.log(doc.data())
      //  return { ...doc.data(), id: doc.id }
      //})
      //console.log(this.noticias)
    },
    async getNotificacoes() {
      const res = await projectFirestore.collection("candidatos").get();
      const res = await db.collection('notificacoes').get()
      //const res = await projectFirestore.collection("candidatos").doc('21929186827').orderBy("datetime", "desc").get();

      console.log("Collection Candidatos: ", res);
      this.notificacoes = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    },
    async setCandidato() {
      const res = await projectFirestore
        .collection("candidatos")
        .doc("edgard.michel@gmail.com")
        .set({
          nome: "Edgard Arthur Michel",
          idMatchjob: 2,
          email: "edgard.michel@gmail.com",
          urlPhoto:
            "https://storagematchjobdefault.blob.core.windows.net/7823160matchjob/photo-profile-id-2-candidato.jpg",
        });
      console.log("Resultado da gravacao: ", res);
      const db = await projectFirestore
        .doc("candidatos/edgard.michel@gmail.com")
        .collection("notificacoes")
        .add({
          title: "Exemplo de título de notificação",
          body: "Exemplo de conteúdo de notificação",
          from: "SAC MatchJob",
          link: "https://notificacao.matchjob.com.br",
          icon: "bell-55",
          color: "primary",
          datetime: timestamp(),
          unread: true,
        });
      console.log(db.id);
    }, */
    atualizaCep() {
      //Auto-preenchimento dos campos a partir do CEP
      const viacep = axios.create();
      viacep.defaults.headers.common = {};
      viacep.defaults.headers.common.accept = "application/json";
      //Verifica se campo cep possui valor informado.
      if (this.candidato.nu_cep != "") {
        //Deixa somente os digitos
        this.candidato.nu_cep = this.candidato.nu_cep.replace(/\D/g, "");
        //Expressão regular para validar somente numeros
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        if (validacep.test(this.candidato.nu_cep)) {
          //Preenche os campos com "..." enquanto consulta webservice.
          this.candidato.nm_endereco = "...";
          this.candidato.nm_bairro = "...";
          this.candidato.nm_cidade = "...";
          this.candidato.nm_estado = "...";
          //Consulta o webservice viacep.com.br/
          viacep
            .get("//viacep.com.br/ws/" + this.candidato.nu_cep + "/json/")
            .then((dados) => {
              //Atualiza os campos com os valores da consulta.
              this.candidato.nm_endereco = dados.data.logradouro;
              this.candidato.nm_bairro = dados.data.bairro;
              this.candidato.nm_cidade = dados.data.localidade;
              this.candidato.nm_estado = dados.data.uf;
            });
        } else {
          //cep é inválido.
          this.limpa_formulario_cep();
          alert("Formato de CEP inválido.");
        }
      } else {
        //cep sem valor, limpa formulário.
        this.limpa_formulario_cep();
      }
    },
    limpa_formulario_cep() {
      this.candidato.nu_cep = "";
    },
    badgeStatus(value) {
      if (value === "1") {
        return "Criado";
      } else if (value === "2") {
        return "Ativo";
      } else if (value === "3") {
        return "Desativado";
      } else if (value === "4") {
        return "Bloqueado";
      } else if (value === "5") {
        return "Inativado";
      } else {
        return "Falha";
      }
    },
    badgeStatusType(value) {
      if (value === "1") {
        return "primary";
      } else if (value === "2") {
        return "success";
      } else if (value === "3") {
        return "danger";
      } else if (value === "4") {
        return "danger";
      } else if (value === "5") {
        return "warning";
      } else {
        return "default";
      }
    },
    formatCvpro: function(data1) {
      if(data1) {
        if (data1 === "1") {
          return "Ativo";
        } else if (data1 === "2") {
          return "Vencido";
        } else {
          return "N/A";
        }
      } else {
        return "N/A"
      }
    },
    badgeCvproType(value) {
      if (value === "1") {
        return "primary";
      } else if (value === "2") {
        return "warning";
      } else {
        return "default";
      }
    },
    formatTimestamp: function(row, column) {
      var date = row[column.property]
      if (date == undefined) {
        return "";
      }
      return moment(date).format("DD/MM/YYYY hh:mm");
    },
    nomeCurriculo(value) {
      let curriculo = this.candidato.curriculos.find(c => c.id_curriculo === value)
      return curriculo.nm_curriculo;
    }
  },
  filters: {
    formatDateTime: function (value) {
      moment.locale('pt-br');
      if (value) {
        return moment(String(value)).format("DD/MMM/YYYY - HH:mm");
        //return moment(String(value)).format("L");
      }
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
  },
  watch: {
    candidato() {
      this.$http
        .get(`${adminApiUrl}/candidatos/getlogins`, {
          params: { hl_email: this.candidato.hl_email },
        })
        .then((res) => {
          this.logins = res.data.logins;
          this.qtdLogins = res.data.contagem;
          this.defineBotaoBloqueio(this.candidato.fl_status);
        })
        .catch((err) => {
          console.log(err.message);
          this.$notify({
            type: "warning",
            message: "Não foi possível carregar as informações de login.",
            icon: "tim-icons icon-bell-55",
          });
        });
    }
  },
  mounted() {
    this.getCandidato();
    this.getNacionalidade();
    //this.getPosts();
    //this.getNotificacoes();
  },
};
</script>
<style scoped>
.icon-encerramento {
  text-align: center;
  padding: 30px 0 30px;
  border: 1px solid #e42828;
  border-radius: 10px;
  height: 100%;
}
.icone {
  color: #ec250d;
  font-size: 1.5em;
}
</style>
