<template>
  <div>
    <div class="row my-1">
      <!-- Nome da Fase -->
      <div class="col-3">
        <label for="nomepublicacaoVaga">Nome da fase <span class="text-danger">*</span></label>
        <input
          id="nomepublicacaoVaga"
          ref="nomepublicacaoVaga"
          type="text"
          class="form-control"
          name="Nome da Fase"
          v-model="propTriagem.configs.text"
        />
      </div>
      <!-- Flag Fase ativa -->
      <div class="col-2 text-center">
        <p>Fase Ativa<br>
          <base-switch
            v-model="propTriagem.configs.ativo"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Obrigatória -->
      <div class="col-2 text-center">
        <p>Obrigatória<br>
          <base-switch
            v-model="propTriagem.configs.required"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
    </div>
    <div class="row my-1">
      <!-- Flag Idiomas -->
      <div class="col-2 text-center">
        <p>Idiomas<br>
          <base-switch
            v-model="propTriagem.configs.idiomas.ativo"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Certificações -->
      <div class="col-2 text-center">
        <p>Certificações<br>
          <base-switch
            v-model="propTriagem.configs.certificacao.ativo"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Competências -->
      <div class="col-2 text-center">
        <p>Competências<br>
          <base-switch
            v-model="propTriagem.configs.competencias.ativo"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Formações -->
      <div class="col-2 text-center">
        <p>Formações<br>
          <base-switch
            v-model="propTriagem.configs.formacao.ativo"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Localização -->
      <div class="col-2 text-center">
        <p>Localização<br>
          <base-switch
            v-model="propTriagem.configs.localizacao.ativo"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <!-- Flag Tempo de Experiência -->
      <div class="col-2 text-center">
        <p>Tempo de Experiência<br>
          <base-switch
            v-model="propTriagem.configs.tempoExperiencia.ativo"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
    </div>
    <div class="row my-1">
      <!-- Flag Pretensão Salarial -->
      <div class="col-2 text-center">
        <p>Pretensão Salarial<br>
          <base-switch
            v-model="propTriagem.configs.pretensaoSalarial.ativo"
            type="primary"
            on-text="Sim"
            off-text="Não"
          ></base-switch>
        </p>
      </div>
      <div class="col-3">
        <label>Faixa de Aceitação</label>
        <el-select
          required
          class="select-primary"
          v-model="propTriagem.configs.pretensaoSalarial.faixa"
          placeholder="Faixa de Aceitação"
          :disabled="!propTriagem.configs.pretensaoSalarial.ativo"
        >
          <el-option
            v-for="tipo in faixaSalarial"
            :key="tipo.value"
            :label="tipo.label"
            :value="tipo.value">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { Badge, BaseSwitch, Modal } from "src/components";
import { Select, Option } from 'element-ui';
export default {
  name: "Triagem",
  props: {
    propTriagem: { type: Object }
  },
  components: {
    Badge,
    BaseSwitch,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      faixaSalarial: [
        { value: "1", label: "Menor ou Igual" },
        { value: "2", label: "5% (a mais ou a menos)" },
        { value: "3", label: "10% (a mais ou a menos)" },
        { value: "4", label: "15% (a mais ou a menos)" },
        { value: "5", label: "20% (a mais ou a menos)" },
        { value: "6", label: "25% (a mais ou a menos)" },
      ],
    }
  }
}
</script>

<style>

</style>
