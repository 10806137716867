<template>
  <div class="content">
    <div class="row"><route-bread-crumb /></div>
    <div class="row mt-5">
      <div class="col-12">
        <h1>Mensagens</h1>

      </div>
    </div>
    <tabs type="primary">
      <tab-pane :label="`Novos Leads (${novosLeads.length})`">
        <ShowMensagens
          :propMensagens="novosLeads"
          @alteraStatus="alteraStatusMsg = $event"
          @deletar="idDelete = $event"
          :funcAlterarStatus="alteraStatus"
          :funcDeletar="deleteMsg"
        />
      </tab-pane>
      <tab-pane :label="`Não Lidas (${naoLidas.length})`">
        <ShowMensagens
          :propMensagens="naoLidas"
          @alteraStatus="alteraStatusMsg = $event"
          @deletar="idDelete = $event"
          :funcAlterarStatus="alteraStatus"
          :funcDeletar="deleteMsg"
        />
      </tab-pane>
      <tab-pane :label="`Leads (${leads.length})`">
        <ShowMensagens
          :propMensagens="leads"
          @alteraStatus="alteraStatusMsg = $event"
          @deletar="idDelete = $event"
          :funcAlterarStatus="alteraStatus"
          :funcDeletar="deleteMsg"
        />
      </tab-pane>
      <tab-pane :label="`Todas (${mensagens.length})`">
        <ShowMensagens
          :propMensagens="mensagens"
          @alteraStatus="alteraStatusMsg = $event"
          @deletar="idDelete = $event"
          :funcAlterarStatus="alteraStatus"
          :funcDeletar="deleteMsg"
        />
      </tab-pane>
      <tab-pane label="Raw Data">
        <div class="row">
          <div class="col-12">
            <pre>{{ mensagens }}</pre>
          </div>
        </div>
      </tab-pane>
    </tabs>
  </div>
</template>

<script>
import { adminApiUrl } from "@/global";
import { RouteBreadCrumb, TabPane, Tabs } from "src/components";
import ShowMensagens from '../../components/Mensagens/ShowMensagens.vue'

export default {
  name: "MensagemDashboard",
  components: {
    RouteBreadCrumb,
    TabPane,
    Tabs,
    ShowMensagens
  },
  data() {
    return {
      mensagens: [],
      leads: [],
      novosLeads: [],
      naoLidas : [],
      idDelete: "",
      alteraStatusMsg: {},
    }
  },
  methods: {
    getMensagens() {
      this.$http
        .get('mensagens/lista')
        .then(res => {
          // console.log(res)
          this.mensagens = res.data.mensagens;
          setTimeout(() => {
            this.filtraNaoLidos();
            this.filtraLeads();
          }, 500);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    alteraStatus() {
      const data = {
        id: this.alteraStatusMsg.id,
        status: this.alteraStatusMsg.status
      }
      this.$http
        .post('mensagens/alterastatus', data)
        .then(() => {
          this.$notify({
            type: "success",
            message: "Mensagem atualizada com sucesso!!",
            icon: "tim-icons icon-bell-55",
          });
          setTimeout(() => {
            this.getMensagens();
          }, 300);
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            type: "warning",
            message: "Não foi possível atualizar a mensagem.",
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    deleteMsg() {
      const data = { id: this.idDelete };
      this.$http
        .post('mensagens/apagar', data)
        .then(() => {
          this.$notify({
            type: "success",
            message: "Mensagem apagada com sucesso!!",
            icon: "tim-icons icon-bell-55",
          });
          setTimeout(() => {
            this.getMensagens();
          }, 300);
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            type: "warning",
            message: "Não foi possível apagar a mensagem.",
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    filtraNaoLidos() {
      this.naoLidas = this.mensagens.filter((element) => element.fl_status === '1')
    },
    filtraLeads() {
      this.leads = this.mensagens.filter((element) => element.fl_assunto === '9');
      setTimeout(() => {
        this.novosLeads = this.leads.filter((element) => element.fl_status === '1')
      }, 300);
    },
  },
  mounted() {
    this.getMensagens();
  }
}
</script>

<style scoped>

</style>
