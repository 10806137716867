<template>
  <div v-if="propMensagens.length > 0">
    <div v-for="(msg, index) in propMensagens" :key="index">
      <div class="row box-mensagem" @click="propClique">
        <div class="col">
          <badge type="primary" v-if="msg.fl_status === '1'">NOVA</badge> <small class="text-muted">{{msg.created_at | formatDateTime}} - {{retornaTipoMensagem(msg.fl_tipo_mensagem)}}</small><br>
          <span>De: {{msg.nm_remetente}} <i class="fas fa-envelope"></i></span><br>
          {{retornaAssunto(msg.fl_assunto)}} - {{retornaOrigem(msg.fl_origem)}}<br>
          <small>{{msg.tx_mensagem}}</small>
        </div>
      </div>
    </div>
    <!-- <pre>{{propMensagens}}</pre> -->
  </div>
</template>

<script>
import moment from "moment";
import { Badge } from 'src/components';
export default {
  name: "ListaMensagens",
  props: {
    propMensagens: { type: Array },
    propClique: { type: Function }
  },
  components: {
    Badge
  },
  data() {
    return {
      origem: [
        { id: "1", text: "Sistema (auto)" },
        { id: "2", text: "Dashboard" },
        { id: "3", text: "Site Publico" },
        { id: "4", text: "Recuperar Senha" },
        { id: "5", text: "Home do Site" },
        { id: "6", text: "SAC do Site" },
        { id: "7", text: "Ajuda do Site" },
      ],
      tipoMensagem: [
        { id: "1", text: "Sistema" },
        { id: "2", text: "Candidato -> MatchJob" },
        { id: "3", text: "Entrevista" },
        { id: "4", text: "Empresa -> MatchJob" },
        { id: "5", text: "Promoção" },
        { id: "6", text: "Publico -> MatchJob" },
      ],
      assuntoMensagem: [
        { id: "1", text: "Estou com Duvidas" },
        { id: "2", text: "Preciso relatar um problema" },
        { id: "3", text: "Quero enviar uma sugestão" },
        { id: "4", text: "Seja Bem-vindo à MatchJob" },
        { id: "5", text: "Novidades da MatchJob" },
        { id: "6", text: "Promoção da MatchJob" },
        { id: "7", text: "Outros Serviços da MatchJob" },
        { id: "8", text: "Recuperação de Conta" },
        { id: "9", text: "Novo Lead" },
      ]
    }
  },
  methods: {
    retornaOrigem(value) {
      var origem = this.origem.find(o => o.id === value)
      return origem.text;
    },
    retornaTipoMensagem(value) {
      var tipo = this.tipoMensagem.find(t => t.id === value)
      return tipo.text;
    },
    retornaAssunto(value) {
      var assunto = this.assuntoMensagem.find(a => a.id === value)
      return assunto.text;
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
        //return moment(String(value)).format("L");
      }
    }
  }
}
</script>

<style scoped>
.box-mensagem {
  box-sizing: border-box;
  border: 1px solid #bebebe;
  background-color: rgb(238, 238, 238);
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
  cursor: pointer;
}
</style>
