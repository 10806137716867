<template>
  <div>
    <div class="row"><route-bread-crumb /></div>
    <div class="row my-2">
      <div class="col-12">
        <base-button type="primary" class="mr-1" size="sm" @click="flAdd = true">Novo Cargo</base-button>
        <base-button type="danger" class="mx-1" size="sm" @click="flAdd = true">Renovar Cache</base-button>
      </div>
    </div>
    <FadeTransition :duration="200" mode="out-in">
      <card v-if="flAdd">
        <div class="row my-2">
          <div class="col-4">
            <label for="cargo">Cargo <span class="text-danger">*</span></label>
            <input
              id="cargo"
              ref="cargo"
              type="text"
              class="form-control"
              name="Cargo"
              v-model="novoCargo"
            />
          </div>
          <div class="col-2 align-items-end align-self-end">
            <base-button type="primary" class="mr-1" size="sm" @click="gravaCargo">Add</base-button>
            <base-button type="warning" class="ml-1" size="sm" @click="flAdd = false">Cancel</base-button>
          </div>
        </div>
      </card>
    </FadeTransition>
    <!-- Linha de busca e listagem -->
    <div class="row">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <div class="form-check form-check-inline">
          <base-input v-model="searchQuery"
            prepend-icon="fas fa-search"
            placeholder="Procurar...">
          </base-input>
        </div>
      </div>
    </div>
    <el-table :data="queriedData">
      <el-table-column width="100" label="#" prop="id_sys_cargo" align="center">
      </el-table-column>
      <el-table-column width="500" label="Cargo" prop="nm_cargo">
      </el-table-column>
      <el-table-column width="200" label="Criado em" prop="created_at">
      </el-table-column>
      <el-table-column width="200" label="Atualizado em" prop="updated_at">
      </el-table-column>
      <el-table-column width="100" label="Ações">
        <div slot-scope="{ row }">
          <el-tooltip
            content="Deletar"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              type="danger"
              icon
              size="sm"
              class="btn-link"
              @click="deleteCargo(row.id_sys_cargo)"
            ><i class="fas fa-trash-alt"></i>
            </base-button>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
    <!-- Linha da Paginação -->
    <div class="row">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <div class="">
          <p class="card-category">
            Mostrando {{ from + 1 }} de {{ to }} em {{ total }} cargos.<br>
          </p>
        </div>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import CargosPaginacao from './CargosPaginacao'
import { adminApiUrl } from "@/global";
import { Table, TableColumn, Select, Option } from 'element-ui';
import { RouteBreadCrumb, BasePagination, Badge } from "src/components";
export default {
  name: "Cargos",
  mixins: [CargosPaginacao],
  components: {
    RouteBreadCrumb,
    BasePagination,
    Badge,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    FadeTransition
  },
  data() {
    return {
      cargos: [],
      flAdd: false,
      novoCargo: ""
    }
  },
  methods: {
    async getCargos() {
      await this.$http
        .get(`${adminApiUrl}/sysinfos/cargosdb`)
        .then((res) => {
          this.cargos = res.data.cargos;
          //console.log("retorno do axios: ", this.candidato);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    async gravaCargo() {
      const data = { nm_cargo: this.novoCargo };
      await this.$http
        .post(`${adminApiUrl}/sysinfos/novocargo`, data)
        .then(() => {
          this.novoCargo = "";
          this.flAdd = false;
          this.getCargos();
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    async deleteCargo(id) {
      const data = { id_sys_cargo: id };
      await this.$http
        .post(`${adminApiUrl}/sysinfos/deletecargo`, data)
        .then(() => {
          alert('Cargo deletado!');
          this.deleteRow(id);
        })
        .catch((err) => {
          console.log(err.message);
        });
        this.$nextTick(() => {
          this.getCargos();
        });
    },
    deleteRow(id) {
      console.log('chamou deleteRow -> ', id)
      let indexToDelete = this.cargos.findIndex(
        tableRow => tableRow.id_sys_cargo === id
      );
      console.log('index -> ', indexToDelete)
      if (indexToDelete >= 0) {
        this.cargos.splice(indexToDelete, 1);
      }
    },
  },
  async created() {
    await this.$http
      .get(`${adminApiUrl}/sysinfos/cargosdb`)
      .then((res) => {
        this.cargos = res.data.cargos;
        //console.log("retorno do axios: ", this.candidato);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
}
</script>

<style>

</style>
