import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCKCc37cfh4fRKSaMQti8ay_DXwxNQA1cg",
  authDomain: "matchjob-empresas.firebaseapp.com",
  projectId: "matchjob-empresas",
  storageBucket: "matchjob-empresas.appspot.com",
  messagingSenderId: "613730605664",
  appId: "1:613730605664:web:417479c57cff39afda0105",
  measurementId: "G-YWKDYYK6FS",
};

// init firebase
initializeApp(firebaseConfig);

// init firestore service
const db = getFirestore();

// export firestore
export { db };
