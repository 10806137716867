<template>
  <div class="content">
    <div class="row"><route-bread-crumb /></div>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div slot="header"><h4 class="card-title">Lista de Candidatos</h4></div>
          <div>
            <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Buscar..."
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData">
              <el-table-column min-width="50" label="#" align="center">
              <div class="photo" slot-scope="{ row }">
                <img :src="row.hl_foto" alt="Table image" />
              </div>
              </el-table-column>
              <el-table-column min-width="50" prop="id_candidato" label="ID">
              </el-table-column>
              <el-table-column min-width="200" prop="nm_nome" label="Nome">
                <div slot-scope="{row}">
                  <strong>{{row.nm_nome}}</strong><br>
                  {{row.hl_email}}
                </div>
              </el-table-column>
              <el-table-column label="Status"
                min-width="150"
                prop="fl_status"
                align="center"
              >
                <div slot-scope="{ row }">
                  <badge :type="badgeStatusType(row.fl_status)">{{ badgeStatus(row.fl_status) }}</badge>
                </div>
              </el-table-column>
              <el-table-column label="CV Pro"
                min-width="150"
                prop="cvpro"
                align="center"
              >
                <div slot-scope="{ row }">
                  <badge :type="badgeCvproType(row.cvpro)">{{ formatCvpro(row.cvpro) }}</badge>
                </div>
              </el-table-column>
              <el-table-column label="Último Acesso"
                min-width="150"
                prop="dt_ultimo_logon"
                :formatter="formatTimestamp">
              </el-table-column>
              <el-table-column label="Criado em"
                min-width="150"
                prop="created_at"
                :formatter="formatTimestamp">
              </el-table-column>
              <el-table-column label="Ações"
                min-width="100"
                header-align="right"
                align="right"
              >
                <div slot-scope="{ row }" class="text-right table-actions">
                  <el-tooltip
                    content="Detalhes"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click="detalhesCandidato(row.id_candidato)"
                      type="primary"
                      icon
                      size="sm"
                      class="btn-link"
                    >
                      <i class="tim-icons icon-badge"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip
                    content="Bloquear"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="handleDelete(row.id_candidato, row)"
                      :type="row.fl_status < 3 ? 'danger' : 'neutral'"
                      :disabled="row.fl_status < 3 ? false : true"
                      icon
                      size="sm"
                      class="btn-link"
                    >
                      <i class="tim-icons icon-lock-circle"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
           <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { adminApiUrl } from "@/global";
import moment from "moment";
import { Table, TableColumn, Select, Option } from 'element-ui';
import { RouteBreadCrumb, BasePagination, Badge } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';

export default {
  name: 'ListaCandidatos',
  components: {
    BasePagination,
    Badge,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RouteBreadCrumb,
  },
  data() {
    return {
      candidatos: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['nm_nome', 'id_candidato', 'hl_email'],
      searchedData: [],
      fuseSearch: null
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.candidatos;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.candidatos.length;
    }
  },
  methods: {
    getCandidatos() {
      this.$http
        .get('candidatos/lista')
        .then(res => {
          this.candidatos = res.data;
        })
        .catch(err => {
          console.log(err.message);
        });
    },
    handleDelete(index, row) {
      swal.fire({
        title: 'ATENÇÃO!',
        text: `Este candidato será bloqueado e não poderá entrar na MatchJob`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Sim, bloquear!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          if (this.bloquearCandidato(row)){
            swal.fire({
              title: 'Bloqueado!',
              text: `O candidato: ${row.id_candidato} - ${row.nm_nome} está bloqueado!`,
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          } else {
            swal.fire({
              title: 'Bloqueado!',
              text: 'Houve uma falha no servidor.',
              icon: 'error',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          }
        }
      });
    },
    async bloquearCandidato(candidato) {
      await this.$http
        .post('candidatos/bloquearcandidato', candidato)
        .then(() => {
          console.log('Usuario bloqueado na API')
          return true;
        })
        .catch(err => {
          console.log('Erro na API -> ', err)
          return false;
        });
    },
    formatTimestamp: function(row, column) {
      var date = row[column.property]
      if (date == undefined) {
        return "";
      }
      return moment(date).format("DD/MM/YYYY hh:mm");
    },
    formatCvpro: function(data) {
      if (data === "1") {
        return "Ativo";
      } else if (data === "2") {
        return "Vencido";
      } else {
        return "N/A";
      }
    },
    formatStatus: function(row, column) {
      var data = row[column.property]
      if (data === "1") {
        return "Criado";//Registro recém criado
      } else if (data === "2") {
        return "Ativo";//Validado o e-mail
      } else if (data === "3") {
        return "Desativado";
      } else if (data === "4") {
        return "Bloqueado";//Bloqueado via sistema
      } else if (data === "5") {
        return "Inativado";//E-mail inválido no retorno do iAgente
      } else {
        return "Nenhum Statuts";
      }
    },
    badgeStatus(value) {
      if (value === '1') {
        return "Criado";
      } else if (value === '2') {
        return "Ativo";
      } else if (value === '3') {
        return "Desativado";
      } else if (value === '4') {
        return "Bloqueado";
      } else if (value === '5') {
        return "Inativado";
      } else {
        return "Falha";
      }
    },
    badgeStatusType(value) {
      if (value === '1') {
        return "primary";
      } else if (value === '2') {
        return "success";
      } else if (value === '3') {
        return "danger";
      } else if (value === '4') {
        return "danger";
      } else if (value === '5') {
        return "warning";
      } else {
        return "default";
      }
    },
    badgeCvproType(data) {
      if (data === "1") {
        return "success";
      } else if (data === "2") {
        return "warning";
      } else {
        return "default";
      }
    },
    detalhesCandidato(id) {
      this.$router.push(`/candidatos/detalhecandidato/${id}`)
    },
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
        //return moment(String(value)).format("L");
      }
    }
  },
  created(){
    this.getCandidatos();
  },
  mounted() {
    // Fuse search initialization.
    /*  this.fuseSearch = new Fuse(this.candidatos, {
      keys: ['nm_nome', 'id_candidato'],
      threshold: 0.6
    }); */
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.candidatos;
      if (value !== '') {
        this.fuseSearch = new Fuse(this.candidatos, {
          keys: ['nm_nome', 'id_candidato'],
          threshold: 0.6
        });
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(a => a.item)
    }
  }
};
</script>
<style scoped>
.pagination-select,
.search-input {
  width: 200px;
}
.el-input {
  width: 200px;
}
</style>
