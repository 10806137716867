<template>
  <div>
    <card v-if="!flVagaSelecionada">
      <template slot="header">
        <div class="row">
          <div class="col-lg-12">
            <h4>Vagas</h4>
          </div>
        </div>
      </template>
      <div class="row my-2" v-for="(vaga, index) in propVagas" :key="index">
        <div class="col-lg-auto">
          <badge :type="badgeStatusType(vaga.fl_status)">{{ retornaStatusVaga(vaga.fl_status) }}</badge> -
          <strong>{{ vaga.nm_cargo }}</strong> (ID: {{ vaga.id_processo_vaga }})<br>
          Candidaturas: <strong>{{ vaga.json_candidaturas.length }}</strong>
        </div>
        <div class="col-lg-2">
          <base-button type="primary" size="sm" @click="visualizarVaga(vaga.id_processo_vaga)">Visualizar</base-button>
        </div>
      </div>
    </card>
    <card v-if="flVagaSelecionada">
      <template slot="header">
        <div class="row">
          <div class="col-lg-12">
            <h4>Vagas</h4>
          </div>
        </div>
      </template>
      <div class="row mb-2">
        <div class="col-lg-auto">
          <base-button type="default" class="mr-1" size="sm" @click="fecharVisualizarVaga()">Fechar</base-button>
          <base-button type="primary" class="mx-1" size="sm" @click="validaJson()">Check JSON</base-button>
          <base-button type="success" class="mx-1" size="sm" @click="salvarVaga()">Salvar</base-button>
        </div>
      </div>
      <div class="row my-2" v-if="flErros">
        <div class="col-lg-12">
          <base-alert type="danger">
            <strong>Erros:</strong><br>
            <template v-if="errosVaga.geral.length > 0">
              Geral: <ul><li v-for="(item, index) in errosVaga.geral" :key="index">{{ item }}</li></ul>
            </template>
            <template v-if="errosVaga.json_vaga.length > 0">
              json_vaga: <ul><li v-for="(item, index) in errosVaga.json_vaga" :key="index">{{ item }}</li></ul>
            </template>
          </base-alert>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <JsonEditorVue
            v-model="vagaSelecionada"
            v-bind="{/* local props & attrs */}"
          />
        </div>
      </div>
      <!-- <pre>{{ vagaSelecionada }}</pre> -->
    </card>
    <!-- <div class="row">
      <div class="col-lg">
        <pre>{{propVagas}}</pre>
      </div>-6
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue'
import VCA from '@vue/composition-api'
import JsonEditorVue from 'json-editor-vue'
import { TabPane, Tabs, Badge, Collapse, CollapseItem, BaseSwitch, Modal } from "src/components";
import { adminApiUrl } from "@/global";

Vue.use(VCA)

export default {
  name: "VagasEmpresas",
  props: {
    propUsuarios: { type: Array },
    propPlano: { type: Object },
    propVagas: { type: Array }
  },
  components: {
    TabPane,
    Tabs,
    Badge,
    JsonEditorVue
  },
  data() {
    return {
      vagaSelecionada: {},
      flVagaSelecionada: false,
      statusVagas: [
        { id: 1, text: "Aberta" },
        { id: 2, text: "Em aprovação" },
        { id: 3, text: "Aprovada" },
        { id: 4, text: "Publicada" },
        { id: 5, text: "Rascunho" },
        { id: 6, text: "Encerrada" },
        { id: 7, text: "Cancelada" },
        { id: 8, text: "Congelada" },
        { id: 9, text: "Reprovada" }
      ],
      errosVaga: {
        geral: [],
        json_vaga: [],
        ps: [],
        vagas: [],
        matchAi: [],
        usuarios: [],
        pgCarreiras: []
      },
      flErros: true
    }
  },
  methods: {
    retornaStatusVaga(value) {
      let item = this.statusVagas.find(i => i.id === parseInt(value));
      return item.text;
    },
    badgeStatusType(value) {
      if (value === '1') {
        return "default";
      } else if (value === '2') {
        return "warning";
      } else if (value === '3') {
        return "primary";
      } else if (value === '4') {
        return "success";
      } else if (value === '5') {
        return "info";
      } else if (value === '6') {
        return "default";
      } else if (value === '7') {
        return "info";
      } else if (value === '8') {
        return "default";
      } else if (value === '9') {
        return "danger";
      } else {
        return "default";
      }
    },
    visualizarVaga(id) {
      this.vagaSelecionada = structuredClone(this.propVagas.find(v => v.id_processo_vaga === id));
      this.flVagaSelecionada = true;
    },
    fecharVisualizarVaga() {
      this.flVagaSelecionada = false;
      this.vagaSelecionada = {};
    },
    validaGeral() {
      const geral = Object.keys(this.vagaSelecionada);
      if (geral.length < 21) {
        this.notificacao('Erro encontrado no JSON Geral da Vaga', 'warning');
        let geralKeys = ['id_processo_vaga', 'id_empresa', 'id_empresa_user', 'fl_status', 'nm_cargo', 'hl_link', 'nm_solicitante', 'nm_depto', 'dt_abertura', 'dt_prev_fechamento', 'dt_fechamento', 'json_vaga', 'json_tags', 'json_fases', 'json_competencias', 'json_candidaturas', 'json_analise_ia', 'json_timeline', 'created_at', 'updated_at', 'deleted_at'];
        for(var i = 0; i < geralKeys.length; i++) {
          let idx = geral.find(e => e === geralKeys[i]);
          if (idx === undefined) {
            this.errosVaga.geral.push(`${geralKeys[i]} -> Chave faltante`)
          }
        }
      } else if (geral.length > 21) {
        this.notificacao('Erro encontrado no JSON Geral da Vaga', 'warning');
        this.errosVaga.geral.push('Excesso de chaves encontradas no geral da vaga');
      }
      if(this.vagaSelecionada.id_processo_vaga === "") {
        this.errosVaga.geral.push('id_processo_vaga vazio');
      } else if(this.vagaSelecionada.id_processo_vaga === null || this.vagaSelecionada.id_processo_vaga === undefined) {
        this.errosVaga.geral.push('id_processo_vaga -> nulo ou indefinido');
      }
      if(this.vagaSelecionada.id_empresa_user === "") {
        this.errosVaga.geral.push('id_empresa_user -> nulo ou indefinido');
      }
      if(this.vagaSelecionada.fl_status === "") {
        this.errosVaga.geral.push('fl_status -> nulo ou indefinido');
      }
      if(this.vagaSelecionada.hl_link === "") {
        this.errosVaga.geral.push('hl_link -> nulo ou indefinido');
      }
      if(this.vagaSelecionada.nm_solicitante === "") {
        this.errosVaga.geral.push('nm_solicitante -> nulo ou indefinido');
      }
      if(this.vagaSelecionada.nm_depto === "") {
        this.errosVaga.geral.push('nm_depto -> nulo ou indefinido');
      }
      if(this.vagaSelecionada.dt_abertura === "") {
        this.errosVaga.geral.push('dt_abertura -> nulo ou indefinido');
      }
      if(this.vagaSelecionada.dt_prev_fechamento === "") {
        this.errosVaga.geral.push('dt_prev_fechamento -> nulo ou indefinido');
      }
      if(typeof this.vagaSelecionada.json_vaga !== 'object') {
        this.errosVaga.geral.push('json_vaga -> tipo incorreto');
      }
      if(typeof this.vagaSelecionada.json_tags !== 'array') {
        console.log('json_tags -> ', typeof this.vagaSelecionada.json_tags)
        this.errosVaga.geral.push('json_tags -> tipo incorreto');
      }
      if(typeof this.vagaSelecionada.json_fases !== 'array') {
        this.errosVaga.geral.push('json_fases -> tipo incorreto');
      }
      if(typeof this.vagaSelecionada.json_competencias !== 'array') {
        this.errosVaga.geral.push('json_competencias -> tipo incorreto');
      }
      if(typeof this.vagaSelecionada.json_candidaturas !== 'array') {
        this.errosVaga.geral.push('json_candidaturas -> tipo incorreto');
      }
      if(typeof this.vagaSelecionada.json_analise_ia !== 'object') {
        this.errosVaga.geral.push('json_analise_ia -> tipo incorreto');
      }
      if(typeof this.vagaSelecionada.json_timeline !== 'array') {
        this.errosVaga.geral.push('json_timeline -> tipo incorreto');
      }
    },
    validaJsonVaga() {
      const json_vaga = Object.keys(this.vagaSelecionada.json_vaga)
      if (json_vaga.length < 29) {
        this.notificacao('Erro encontrado no JSON json_vaga da Vaga', 'warning');
        let vagaKeys = ['nm_area', 'nm_pais', 'fl_video', 'hl_video', 'id_teste', 'nm_cargo', 'nm_nivel', 'nm_cidade', 'id_empresa', 'nm_empresa', 'nu_salario', 'nm_endereco', 'json_idiomas', 'tx_descricao', 'fl_tipo_video', 'json_formacao', 'tx_requisitos', 'fl_contratacao', 'fl_deficientes', 'id_empresa_user', 'json_beneficios', 'fl_tipo_trabalho', 'id_empresa_ender', 'fl_oculta_empresa', 'fl_oculta_salario', 'json_certificacao', 'json_competencias', 'nm_turno_trabalho', 'json_nivelexperiencia'];
        for(var i = 0; i < vagaKeys.length; i++) {
          let idx = json_vaga.find(e => e === vagaKeys[i]);
          if (idx === undefined) {
            this.errosVaga.json_vaga.push(`${vagaKeys[i]} -> Chave faltante`)
          }
        }
      } else if (json_vaga.length > 29) {
        this.notificacao('Erro encontrado no JSON da Vaga', 'warning');
        this.errosPlano.json_vaga.push('Excesso de chaves encontradas no json_vaga da vaga');
      }
    },
    validaJson() {
      this.limpaArrErrors();//Limpa Array de erros
      this.validaGeral();
      this.validaJsonVaga();
      //const geral = Object.keys(this.vagaSelecionada)
      //console.log('geral -> ', geral)
      //const json_vaga = Object.keys(this.vagaSelecionada.json_vaga)
      //console.log('json_vaga -> ', json_vaga)
      /* const json_tags = Object.keys(this.vagaSelecionada.json_tags)
      console.log('json_tags -> ', json_tags)
      const json_fases = Object.keys(this.vagaSelecionada.json_fases)
      console.log('json_fases -> ', json_fases)
      const json_competencias = Object.keys(this.vagaSelecionada.json_competencias)
      console.log('json_competencias -> ', json_competencias)
      const json_candidaturas = Object.keys(this.vagaSelecionada.json_candidaturas)
      console.log('json_candidaturas -> ', json_candidaturas)
      const json_analise_ia = Object.keys(this.vagaSelecionada.json_analise_ia)
      console.log('json_analise_ia -> ', json_analise_ia)
      const json_timeline = Object.keys(this.vagaSelecionada.json_timeline)
      console.log('json_timeline -> ', json_timeline) */
    },
    limpaArrErrors() {
      this.errosVaga.geral = []
      this.errosVaga.json_vaga = []
    },
    async salvarVaga() {
      this.$delete(this.vagaSelecionada, 'created_at');
      this.$delete(this.vagaSelecionada, 'updated_at');
      this.$delete(this.vagaSelecionada, 'deleted_at');
      await this.$http
        .post(`${adminApiUrl}/vagas/salvar`, this.vagaSelecionada)
        .then(() => {
          this.notificacao('Vaga Atualizada!', 'success');
          this.fecharVisualizarVaga();
        })
        .catch(err => {
          this.notificacao('Erro salvando a vaga!', 'danger');
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    //Função de notificação
    notificacao(msg, tipo) {
      this.$notify({
        type: tipo,
        message: msg,
        icon: "tim-icons icon-bell-55",
      });
    }
  }
}
</script>

<style>

</style>
