import Vue from "vue";
import axios from "axios";
import router from '../routes/starterRouter'

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios.create({
      baseURL: "https://api.admin.matchjob.com.br/",
      //baseURL: "http://api.admin.matchjob.local/",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    });
    Vue.prototype.$http.interceptors.request.use(config => {
      let token = localStorage.getItem("access_token");
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      return config
    }, error => Promise.reject(error))
    Vue.prototype.$http.interceptors.response.use(res => {
      return res
    }, error => {
      if (error.response) {
        if (error.response.status === 401) {
          console.log("axios.js - Interceptor -> Recebido erro 401 ->>");
          localStorage.clear();
          router.push('/login');
        } else {
          console.log("axios.js - Interceptor  -> Recebido erro ->>", error);
          localStorage.clear();
          router.push('/login');
        }
      } else if (error.request) {
        console.log("Error na requisição - Interceptor  ->>", error.request);
        localStorage.clear();
        router.push('/login');
      } else {
        console.log('Error - Interceptor ', error.message);
        localStorage.clear();
        router.push('/login');
      }
       return Promise.reject(error)
      })
    }
  });

  const success = res => res;
  const error = err => {
    if (401 === err.response.status) {
    localStorage.clear();
    router.push('/login');
   // window.location = "/login";
  } else {
    return Promise.reject(err);
  }
};

axios.interceptors.response.use(success, error);
