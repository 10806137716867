<template>
  <div>
    <div class="row">
      <!-- Stats Cards -->
      <div class="col-sm-4 col-lg-2" v-for="(card, index) in statsCards" :key="index">
        <stats-card
          :title="card.title"
          :sub-title="card.subTitle"
          :type="card.type"
          :icon="card.icon"
        >
          <div slot="footer" v-html="card.footer"></div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <base-button type="primary" class="m-3" >Dashboard Empresas <i class="fas fa-angle-double-right"></i></base-button>
        <base-button type="primary" class="m-3" >Dashboard Candidatos <i class="fas fa-angle-double-right"></i></base-button>
      </div>
    </div>
    <div class="row">
      <!-- Lista -->
      <div class="col-lg-6">
        <card type="tasks">
          <template slot="header">
            <h6 class="title d-inline">Mesangens ({{mensagens.length}})</h6>
            <base-dropdown
              menu-on-right=""
              tag="div"
              title-classes="btn btn-link btn-icon"
            >
              <i slot="title" class="tim-icons icon-settings-gear-63"></i>
              <a class="dropdown-item" href="#pablo"> Action </a>
              <a class="dropdown-item" href="#pablo"> Another action </a>
              <a class="dropdown-item" href="#pablo"> Something else </a>
            </base-dropdown>
          </template>
          <div class="table-full-width table-responsive">
            <ListaMensagens
              :propMensagens="mensagens"
              :propClique="goToMensagens"
            />
          </div>
        </card>
      </div>
      <!-- Lista -->
      <div class="col-lg-6">
        <card type="tasks">
          <template slot="header">
            <h6 class="title d-inline">Mesangens ({{mensagens.length}})</h6>
            <p class="card-category d-inline">Today</p>
            <base-dropdown
              menu-on-right=""
              tag="div"
              title-classes="btn btn-link btn-icon"
            >
              <i slot="title" class="tim-icons icon-settings-gear-63"></i>
              <a class="dropdown-item" href="#pablo"> Action </a>
              <a class="dropdown-item" href="#pablo"> Another action </a>
              <a class="dropdown-item" href="#pablo"> Something else </a>
            </base-dropdown>
          </template>
          <div class="table-full-width table-responsive">
            <base-button type="primary" class="m-3" @click="getEnviosIagente">Teste</base-button>
            teste2
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { adminApiUrl } from "@/global";
import StatsCard from 'src/components/Cards/StatsCard';
//import firebase from 'firebase/app'
//import { projectFirestore, timestamp } from '../firebase/config'
import moment from "moment";
import { format } from 'date-fns';

import ListaMensagens from '../components/Mensagens/ListaMensagens.vue'

export default {
  name: 'starter-page',
  components: {
    StatsCard,
    ListaMensagens
  },
  data() {
    return {
      statsCards: [
        {
          title: '0',
          subTitle: 'Empresas',
          type: 'primary',
          icon: 'far fa-building',
        },
        {
          title: '0',
          subTitle: 'Uso de IA',
          type: 'primary',
          icon: 'tim-icons icon-molecule-40',
        },
        {
          title: '0',
          subTitle: 'Vagas',
          type: 'info',
          icon: 'fas fa-briefcase',
        },
        {
          title: '0',
          subTitle: 'Aplicaçoes',
          type: 'info',
          icon: 'fas fa-user-plus',
        },
        {
          title: '0',
          subTitle: 'Candidatos',
          type: 'warning',
          icon: 'fas fa-users',
        },
        {
          title: '0',
          subTitle: 'Curriculos',
          type: 'warning',
          icon: 'fas fa-file-alt',
        },
        {
          title: '0',
          subTitle: 'E-mails',
          type: 'danger',
          icon: 'fas fa-mail-bulk',
        },
      ],
      mensagens: [],
      noticias: [],
      notificacoes: []
    };
  },
  methods: {
    getDashboard() {
      this.$http
        .get('dashboard')
        .then(res => {
          // console.log(res)
          this.statsCards[0].title = res.data.empresas
          this.statsCards[1].title = res.data.usoia
          this.statsCards[2].title = res.data.vagas
          this.statsCards[3].title = res.data.candidaturas
          this.statsCards[4].title = res.data.candidatos
          this.statsCards[5].title = res.data.curriculos
          this.mensagens = res.data.mensagens;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    goToMensagens() {
      this.$router.push({ name: 'DashboardMensagens' });
    },
    async getEnviosIagente() {
      var data = {
        api_user: "contato@work4data.com.br",
        api_key : "5c18558dc11afd8081bfb634fe8ef564"
      }
      await this.$http
        .post("https://api.iagentesmtp.com.br/api/v3/traffic/", data)
        .then((res) => {
          //console.log('res -> ', res.data)
          this.statsCards[6].title = res.data.traffic[0].sent;
          /* var javaScriptRelease = Date.parse(res.data.traffic[0].period);
          var dataTmp = format(res.data.traffic[0].period, 'yyyy-MM');// this.formataDataMesAno(javaScriptRelease);
          console.log('dataTmp -> ', dataTmp); */
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    formataDataMesAno: function(value) {
      if (value) {
        return moment(String(value)).format("MM/YYYY");
        //return moment(String(value)).format("L");
      }
    }
    /* async getPosts() {
      const db = projectFirestore.collection('candidatos').doc('4442562882')
      const res = await db.collection('notificacoes').get()
      console.log('Retorno do CPF: 4442562882 - ', res)
      this.noticias = res.docs.map(doc => {
        return { ...doc.data(), id: doc.id }
      })
      console.log('As notificacoes: ', this.noticias)
      //const db = projectFirestore.collection('candidatos')
      //const res = await db.doc('21929186827').collection('notificacoes').get()
      //console.log(res)
      //const res = await projectFirestore.collection("candidatos").doc('21929186827').orderBy("datetime", "desc").get();
      //this.noticias = res.docs.map(doc => {
        //console.log(doc.data())
      //  return { ...doc.data(), id: doc.id }
      //})
      //console.log(this.noticias)
    },
    async getNotificacoes() {
      const res = await projectFirestore.collection('candidatos').get()
      //const res = await db.collection('notificacoes').get()
      //const res = await projectFirestore.collection("candidatos").doc('21929186827').orderBy("datetime", "desc").get();

      console.log('Collection Candidatos: ', res)
      this.notificacoes = res.docs.map(doc => {
        return { ...doc.data(), id: doc.id }
      })
    },
    async setCandidato() {
      const res = await projectFirestore.collection('candidatos').doc('edgard.michel@gmail.com').set({
        nome: "Edgard Arthur Michel",
        idMatchjob: 2,
        email: "edgard.michel@gmail.com",
        urlPhoto: "https://storagematchjobdefault.blob.core.windows.net/7823160matchjob/photo-profile-id-2-candidato.jpg"
      })
      console.log('Resultado da gravacao: ', res)
      const db = await projectFirestore.doc('candidatos/edgard.michel@gmail.com')
        .collection('notificacoes')
        .add({
        title: 'Exemplo de título de notificação',
        body: 'Exemplo de conteúdo de notificação',
        from: 'SAC MatchJob',
        link:  'https://notificacao.matchjob.com.br',
        icon: 'bell-55',
        color: 'primary',
        datetime: timestamp(),
        unread: true
      })
      console.log(db.id)
    }, */
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.getDashboard();
    this.getEnviosIagente();
  }
};
</script>
<style></style>
