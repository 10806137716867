var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('route-bread-crumb')],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('card',{attrs:{"card-body-classes":"table-full-width"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Lista de Empresas")])]),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',{staticClass:"form-check form-check-inline"},[_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Procurar..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('el-table',{attrs:{"data":_vm.queriedData}},[_c('el-table-column',{attrs:{"min-width":"50","prop":"id_empresa","label":"#","align":"center"}}),_c('el-table-column',{attrs:{"min-width":"250","label":"Empresa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('strong',[_c('router-link',{attrs:{"to":("/empresas/detalheempresa/" + (row.id_empresa))}},[_vm._v(" "+_vm._s(row.nm_fantasia))])],1),_c('br'),_c('small',[_vm._v(_vm._s(row.nm_razao))])])}}])}),_c('el-table-column',{attrs:{"min-width":"100","label":"Tipo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('strong',[_vm._v(_vm._s(row.fl_cpf_cnpj === '0' ? 'CNPJ' : 'CPF'))]),_c('br'),_c('small',[_vm._v(_vm._s(row.nu_cadastro))])])}}])}),_c('el-table-column',{attrs:{"min-width":"120","label":"Plano"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('strong',[_vm._v(_vm._s(_vm.retornaNomePlano(row.plano.nm_plano)))])])}}])}),_c('el-table-column',{attrs:{"min-width":"120","label":"Datas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('small',[_vm._v(" Inicio: "+_vm._s(_vm._f("formataDiaHora")(row.plano.dt_inicio))),_c('br'),_vm._v(" Fim: "+_vm._s(_vm._f("formataDiaHora")(row.plano.dt_fim))),_c('br')])])}}])}),_c('el-table-column',{attrs:{"min-width":"120","label":"Usuários"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" RH: "),_c('strong',[_vm._v(_vm._s(row.plano.qtdRh))]),_c('br'),_vm._v(" Areas: "+_vm._s(row.plano.qtdArea)+" ")])}}])})],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Mostrando "+_vm._s(_vm.from + 1)+" de "+_vm._s(_vm.to)+" em "+_vm._s(_vm.total)+" testes."),_c('br')])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }