<template>
  <div class="content">
    <div class="row"><route-bread-crumb /></div>
    <div class="row">
      <div class="col-2" v-for="(card, index) in statsCards" :key="index">
        <stats-card
          :title="card.title"
          :sub-title="card.subTitle"
          :type="card.type"
          :icon="card.icon"
        >
          <div slot="footer" v-html="card.footer"></div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <h4>Candidatos sem Currículo ({{semCurriculos.length}}) <i :class="flVerCandidatosSemCv ? 'fas fa-eye' : 'fas fa-eye-slash'" @click="flVerCandidatosSemCv = !flVerCandidatosSemCv"></i></h4>
        <base-button type="primary" size="sm" @click="separaCandidatosSemCv">Enviar Notificação</base-button>
        <template v-if="flVerCandidatosSemCv">
          <div v-for="(id, index) in semCurriculos" :key="index">
            <h5 class="mb-0 mt-1">{{retornaCandidato(id)}} ({{id}}) <template v-if="retornaStatusEnvio(id) !== undefined">
              <badge :type="retornaStatusEnvio(id) ? 'success' : 'danger'">{{ retornaStatusEnvio(id) ? 'Enviado' : 'Falha' }}</badge>
            </template></h5>
            {{retornaEmailCandidato(id)}}
          </div>
        </template>
      </div>
      <div class="col-4">
        <h4>Enviar Pesquisa de Satisfação de Cadastro</h4>
        <base-button type="primary" size="sm" class="mx-2" :disabled="enviarPesquisaCandidatos.length < 1" @click="enviaPesquisaSatisfacao()">Enviar Pesquisa</base-button>
        <base-button type="warning" size="sm" class="mx-2" @click="enviarPesquisaCandidatos = []">Limpar</base-button>
        <div v-for="(candidato, index) in candidatos" :key="index">
          <div class="form-check" v-if="verificaEnvioPesquisa(candidato.id_candidato)">
            <input class="form-check-input" type="checkbox" :value="candidato.id_candidato" :id="candidato.id_candidato" v-model="enviarPesquisaCandidatos">
            <label class="form-check-label" :for="candidato.id_candidato">
              {{candidato.nm_nome}} <template v-if="verificaCv(candidato.id_candidato)">
                <i class="text-success fas fa-check-circle"></i></template>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <h1>Dashboard Candidato</h1>
        {{semCurriculos}}
        <br>
        enviarPesquisaCandidatos -> {{enviarPesquisaCandidatos}}<br>
        pesquisaEnviadaCandidatos -> {{pesquisaEnviadaCandidatos}}
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        candidatos ->
        <pre>
          {{candidatos}}
        </pre>
      </div>
      <div class="col-6">
        curriculos ->
        <pre>
          {{curriculos}}
        </pre>
      </div>
    </div>
  </div>
</template>

<script>
import { adminApiUrl } from "@/global";
import moment from "moment";

import { db } from "../../firebase/config";
import { doc, getDoc, query, collection, getDocs, addDoc } from "firebase/firestore";

import { RouteBreadCrumb, Badge } from 'src/components';
import StatsCard from 'src/components/Cards/StatsCard';

export default {
  name: "DashboardCandidato",
  components: {
    RouteBreadCrumb,
    Badge,
    StatsCard
  },
  data() {
    return {
      flVerCandidatosSemCv: false,
      candidatos: [],
      curriculos: [],
      semCurriculos: [],
      retornoEnviaCandidatosSemCv: [],
      candidatosSemCvJaEnviado: [],
      pesquisaEnviadaCandidatos: [],
      enviarPesquisaCandidatos: [],
      retornoEnviaPesquisa: [],
      statsCards: [
        {
          title: '0',
          subTitle: 'Candidatos',
          type: 'warning',
          icon: 'fas fa-users',
        },
        {
          title: '0',
          subTitle: 'Curriculos',
          type: 'warning',
          icon: 'fas fa-file-alt',
        },
        {
          title: '0',
          subTitle: 'Sem CV',
          type: 'primary',
          icon: 'far fa-building',
        }
      ],
    }
  },
  methods: {
    getCandidatos() {
      this.$http
        .get('candidatos/dashboard')
        .then((res) => {
          this.candidatos = res.data.candidatos;
          this.curriculos = res.data.curriculos;
          this.semCurriculos = res.data.semcurriculo;
          this.statsCards[0].title = this.candidatos.length;
          this.statsCards[1].title = this.curriculos.length;
          this.statsCards[2].title = this.semCurriculos.length;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    retornaCandidato(id) {
      var candidato = this.candidatos.find(i => i.id_candidato === id);
      return candidato.nm_nome;
    },
    retornaEmailCandidato(id) {
      var candidato = this.candidatos.find(i => i.id_candidato === id);
      return candidato.hl_email;
    },
    retornaStatusEnvio(id) {
      var candidato = this.candidatosSemCvJaEnviado.find(c => c.id_candidato === id);
      if(candidato !== undefined) {
        return candidato.statusEnvio
      } else {
        return undefined;
      }
      //console.log('candidato retornaStatusEnvio -> ', candidato)
    },
    verificaEnvioPesquisa(id) {
      var candidato = this.pesquisaEnviadaCandidatos.find(c => c.id_candidato === id);
      if(candidato !== undefined) {
        return false
      } else {
        return true;
      }
    },
    verificaCv(id) {
      var curriculo = this.curriculos.find(i => i.id_candidato === id)
      if(curriculo !== undefined) {
        return true;
      }
    },
    async separaCandidatosSemCv() {
      /* var candidatos = [
        {id_candidato:"9",nm_nome:"Edgard Arthur Michel",hl_email:"edgard.michel@gmail.com",created_at:"11/03/2024"},
        {id_candidato:"10",nm_nome:"Edgard Arthur Michel",hl_email:"edgard.michel@outlook.com",created_at:"11/03/2024"}
      ]; */
      var candidatos = [];
      if(this.semCurriculos.length > 0) {
        this.semCurriculos.forEach(element => {
          let candidato = this.candidatos.find(i => i.id_candidato === element);
          let temp = {
            id_candidato: candidato.id_candidato,
            nm_nome: candidato.nm_nome,
            hl_email: candidato.hl_email,
            created_at: this.formatDate(candidato.created_at),
          }
          candidatos.push(temp);
        });
        await this.$http
          .post('candidatos/completarcv', candidatos)
          .then((res) => {
            // console.log('res -> ', res.data)
            this.retornoEnviaCandidatosSemCv = res.data
            if(this.retornoEnviaCandidatosSemCv.length > 0) {
              this.gravaEnvioCandidatosSemCv();
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
      //console.log('candidatos -> ', candidatos)
    },
    gravaEnvioCandidatosSemCv() {
      if(this.retornoEnviaCandidatosSemCv.length > 0) {
        this.retornoEnviaCandidatosSemCv.forEach(element => {
          setTimeout(() => {
            this.gravaRetornoCandidatosSemCv(element);
          }, 300);
        });
      }
    },
    async gravaRetornoCandidatosSemCv(dados) {
      const colRef = "admin-semcv";
      const resRef = collection(db, colRef);
      const res = await addDoc(resRef, dados)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          return false;
        })
      this.carregaEnviosSemCv();
      return res;
    },
    async carregaEnviosPesquisa() {
      const colRef = "admin-pesquisa";
      const resRef = collection(db, colRef);
      const q = query(resRef);
      await getDocs(q).then((snapshot) => {
        let docs = [];
        snapshot.docs.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        this.pesquisaEnviadaCandidatos = docs;
      });
      //console.log('teste carregados do FS ->> ', this.testes);
    },
    async carregaEnviosSemCv() {
      const colRef = "admin-semcv";
      const resRef = collection(db, colRef);
      const q = query(resRef);
      await getDocs(q).then((snapshot) => {
        let docs = [];
        snapshot.docs.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        this.candidatosSemCvJaEnviado = docs;
      });
      //console.log('teste carregados do FS ->> ', this.testes);
    },
    async enviaPesquisaSatisfacao() {
      var candidatos = [];
      if(this.enviarPesquisaCandidatos.length > 0) {
        this.enviarPesquisaCandidatos.forEach(element => {
          let candidato = this.candidatos.find(i => i.id_candidato === element);
          let temp = {
            id_candidato: candidato.id_candidato,
            nm_nome: candidato.nm_nome,
            hl_email: candidato.hl_email
          }
          candidatos.push(temp);
        });
        await this.$http
          .post('candidatos/enviarpesquisa', candidatos)
          .then((res) => {
            // console.log('res -> ', res.data)
            this.retornoEnviaPesquisa = res.data
            if(this.retornoEnviaPesquisa.length > 0) {
              this.gravaEnvioPesquisa();
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    },
    gravaEnvioPesquisa() {
      if(this.retornoEnviaPesquisa.length > 0) {
        this.retornoEnviaPesquisa.forEach(element => {
          setTimeout(() => {
            this.gravaRetornoEnvioPesquisa(element);
          }, 300);
        });
      }
    },
    async gravaRetornoEnvioPesquisa(dados) {
      const colRef = "admin-pesquisa";
      const resRef = collection(db, colRef);
      const res = await addDoc(resRef, dados)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          return false;
        })
      this.carregaEnviosPesquisa();
      return res;
    },
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.getCandidatos();
    this.carregaEnviosSemCv();
    this.carregaEnviosPesquisa();
  }
}
</script>

<style scoped>
.form-check input[type="checkbox"], .radio input[type="radio"] {
  opacity: 1;
  position: absolute;
  visibility: visible;
  margin-left: 15px;
}
.form-check .form-check-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 25px;
  margin-left: 15px;
  line-height: 18px;
  margin-bottom: 0;
  -webkit-transition: color 0.3s linear;
  transition: color 0.3s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
