  <template>
  <div class="card-empresas">
    <div class="row my-2">
      <div class="col-lg-2">
        <base-button type="success" size="sm" :disabled="flSave" @click="atualizaPlano">Salvar</base-button>
      </div>
      <div class="col-lg-2">
        <base-button type="warning" size="sm" @click="validaJsonPlano">Check Errors</base-button>
      </div>
      <div class="col-lg-2">
        <base-button type="default" size="sm" @click="flErros = !flErros">Editar JSON</base-button>
      </div>
    </div>
    <!-- Alerta de Erro no JSON -->
    <template v-if="flErros">
      <base-alert type="danger">
        <strong>Erros:</strong><br>
        <template v-if="errosPlano.geral.length > 0">
          Geral: <ul><li v-for="(item, index) in errosPlano.geral" :key="index">{{ item }}</li></ul>
        </template>
        <template v-if="errosPlano.detalhes.length > 0">
          Detalhes: <ul><li v-for="(item, index) in errosPlano.detalhes" :key="index">{{ item }}</li></ul>
        </template>
        <template v-if="errosPlano.ps.length > 0">
          PS: <ul><li v-for="(item, index) in errosPlano.ps" :key="index">{{ item }}</li></ul>
        </template>
        <template v-if="errosPlano.vagas.length > 0">
          Vagas: <ul><li v-for="(item, index) in errosPlano.vagas" :key="index">{{ item }}</li></ul>
        </template>
        <template v-if="errosPlano.matchAi.length > 0">
          MatchAi: <ul><li v-for="(item, index) in errosPlano.matchAi" :key="index">{{ item }}</li></ul>
        </template>
        <template v-if="errosPlano.usuarios.length > 0">
          Usuários: <ul><li v-for="(item, index) in errosPlano.usuarios" :key="index">{{ item }}</li></ul>
        </template>
        <template v-if="errosPlano.pgCarreiras.length > 0">
          Pg Carreiras: <ul><li v-for="(item, index) in errosPlano.pgCarreiras" :key="index">{{ item }}</li></ul>
        </template>
      </base-alert>
      <div class="row">
        <div class="col-lg-12">
          <JsonEditorVue
            v-model="plano"
            v-bind="{/* local props & attrs */}"
          />
        </div>
      </div>
    </template>
    <template v-if="flErros === false">
      <div class="row my-2">
        <div class="col-lg-12">
          <card>
            <template slot="header">
              <div class="row">
                <div class="col-lg-12">
                  <h4>Geral</h4>
                </div>
              </div>
            </template>
            <div class="row flex-wrap">
              <!-- Nome do Plano -->
              <div class="col-lg-2">
                <label for="plano">Plano Contratado </label> <el-select
                  ref="plano"
                  name="Plano Contratado"
                  class="select-primary"
                  id="plano"
                  v-model="plano.nm_plano"
                >
                  <el-option
                    v-for="tipo in nomePlanos"
                    :key="tipo.value"
                    :value="tipo.value"
                    :label="tipo.label"
                  ></el-option>
                </el-select>
              </div>
              <!-- Data de Inicio -->
              <div class="col-lg-2">
                <div class="form-group">
                <label for="dt_inicio">Data de Inicio</label>
                <el-date-picker
                  type="date"
                  v-model="plano.json_detalhes.dt_inicio"
                  format="dd/MM/yyyy"
                />
                </div>
              </div>
              <!-- Data Fim -->
              <div class="col-lg-2">
                <div class="form-group">
                <label for="dt_fim">Data de Término</label>
                <el-date-picker
                  type="date"
                  v-model="plano.json_detalhes.dt_fim"
                  format="dd/MM/yyyy"
                />
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- Linha a avaliar -->
      <!-- <div class="row my-2">
        <div class="col text-center">
          <p>Processo de Seleção<br>
            <base-switch
              v-model="plano.fl_processo_rs"
              type="primary"
              on-text="Sim"
              off-text="Não"
            ></base-switch>
          </p>
        </div>
        <div class="col text-center">
          <p>Fluxo de Aprovação de Vagas<br>
            <base-switch
              v-model="plano.fl_fluxo_aprovacao_vagas"
              type="primary"
              on-text="Sim"
              off-text="Não"
            ></base-switch>
          </p>
        </div>
        <div class="col text-center">
          <p>Fluxo de Aprovação de CVs<br>
            <base-switch
              v-model="plano.fl_fluxo_aprovacao_cvs"
              type="primary"
              on-text="Sim"
              off-text="Não"
            ></base-switch>
          </p>
        </div>
        <div class="col text-center">
          <p>IA na Avaliação de CVs<br>
            <base-switch
              v-model="plano.fl_ai_avaliacao_cvs"
              type="primary"
              on-text="Sim"
              off-text="Não"
            ></base-switch>
          </p>
        </div>
        <div class="col text-center">
          <p>Envia Avisos de Vagas<br>
            <base-switch
              v-model="plano.fl_enviar_aviso"
              type="primary"
              on-text="Sim"
              off-text="Não"
            ></base-switch>
          </p>
        </div>
      </div> -->
      <!-- Linha do Card de Usuarios -->
      <div class="row">
        <div class="col-lg-12">
          <card>
            <template slot="header">
              <div class="row">
                <div class="col-lg-12">
                  <h4>Usuários</h4>
                </div>
              </div>
            </template>
            <div class="row flex-wrap">
              <!-- Usuários RH -->
              <div class="col-lg-2">
                <label for="qtdRh">Usuários RH</label>
                <input
                  id="qtdRh"
                  ref="qtdRh"
                  type="text"
                  class="form-control"
                  name="Usuários RH"
                  v-model="plano.json_detalhes.usuarios.qtdRh"
                />
              </div>
              <!-- Usuários de Areas -->
              <div class="col-lg-2">
                <label for="qtdArea">Usuários de Areas</label>
                <input
                  id="qtdArea"
                  ref="qtdArea"
                  type="text"
                  class="form-control"
                  name="Usuários de Areas"
                  v-model="plano.json_detalhes.usuarios.qtdArea"
                />
              </div>
              <!-- Auth MS -->
              <div class="col-lg-2">
                <p>Auth MS<br>
                  <base-switch
                    v-model="plano.json_detalhes.usuarios.authMs"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- Linha do Card de Vagas -->
      <div class="row">
        <div class="col-lg-12">
          <card>
            <template slot="header">
              <div class="row">
                <div class="col-lg-12">
                  <h4>Vagas</h4>
                </div>
              </div>
            </template>
            <div class="row flex-wrap">
              <!-- Qtd de Vagas/mês -->
              <div class="col-lg-2">
                <label for="qtdVagas">Quantidade de vagas</label>
                <input
                  id="qtdVagas"
                  ref="qtdVagas"
                  type="text"
                  class="form-control"
                  name="Quantidade de vagas"
                  v-model="plano.json_detalhes.vagas.qtd"
                />
              </div>
              <!-- Anúncio/Publicação da vaga -->
              <div class="col-lg-2">
                <label for="anuncioVagas">Publicação da vaga</label>
                <el-select
                  ref="anuncioVagas"
                  name="Publicação da vaga"
                  class="select-primary"
                  id="anuncioVagas"
                  v-model="plano.json_detalhes.vagas.anuncio"
                >
                  <el-option
                    v-for="tipo in vagaAnuncio"
                    :key="tipo.value"
                    :value="tipo.value"
                    :label="tipo.label"
                  ></el-option>
                </el-select>
              </div>
              <!-- Vaga Home Office -->
              <div class="col-lg-2">
                <p>Vaga de HomeOffice<br>
                  <base-switch
                    v-model="plano.json_detalhes.vagas.vagaHO"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Vaga Multinacionais -->
              <div class="col-lg-2">
                <p>Vaga Multinacionais<br>
                  <base-switch
                    v-model="plano.json_detalhes.vagas.muiltinacional"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Vaga Export API -->
              <div class="col-lg-2">
                <p>Export de Vagas por API<br>
                  <base-switch
                    v-model="plano.json_detalhes.vagas.exportApi"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Vaga Import API -->
              <div class="col-lg-2">
                <p>Import de Vagas por API<br>
                  <base-switch
                    v-model="plano.json_detalhes.vagas.importApi"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- vagasGeracaoIa -->
              <div class="col-lg-2">
                <p>Vagas por IA<br>
                  <base-switch
                    v-model="plano.json_detalhes.vagas.vagasGeracaoIa"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- Linha do Card de Pagina de Carreiras -->
      <div class="row">
        <div class="col-lg-12">
          <card>
            <template slot="header">
              <div class="row">
                <div class="col-lg-12">
                  <h4>Página de Carreiras</h4>
                </div>
              </div>
            </template>
            <div class="row flex-wrap">
              <!-- Domínio   -->
              <div class="col-lg-2">
                <label for="dominio">Domínio</label>
                <input
                  id="dominio"
                  ref="dominio"
                  type="text"
                  class="form-control"
                  name="Domínio"
                  v-model="plano.json_detalhes.pgCarreiras.dominio"
                />
              </div>
              <!-- Customizável -->
              <div class="col-lg-2">
                <label for="customizavel">Customizável</label>
                <input
                  id="customizavel"
                  ref="customizavel"
                  type="text"
                  class="form-control"
                  name="Customizável"
                  v-model="plano.json_detalhes.pgCarreiras.customizavel"
                />
              </div>
              <!-- Responsivo -->
              <div class="col-lg-2">
                <p>Responsivo<br>
                  <base-switch
                    v-model="plano.json_detalhes.pgCarreiras.respMobile"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Multi Idioma -->
              <div class="col-lg-2">
                <p>Multi Idioma<br>
                  <base-switch
                    v-model="plano.json_detalhes.pgCarreiras.multiIdioma"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- Linha do Card de Processo Seletivo -->
      <div class="row">
        <div class="col-lg-12">
          <card>
            <template slot="header">
              <div class="row">
                <div class="col-lg-12">
                  <h4>Processo Seletivo</h4>
                </div>
              </div>
            </template>
            <div class="row flex-wrap">
              <!-- Criação de Processo de R&S -->
              <div class="col-lg-2">
                <p>Criação de Processo de R&S<br>
                  <base-switch
                    v-model="plano.json_detalhes.ps.criacaoPs"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Fluxo de Aproção de Vagas -->
              <div class="col-lg-2">
                <p>Fluxo de Aproção de Vagas<br>
                  <base-switch
                    v-model="plano.json_detalhes.ps.fluxoVagas"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Fluxo de Aprovação de CVs -->
              <div class="col-lg-2">
                <p>Fluxo de Aprovação de CVs<br>
                  <base-switch
                    v-model="plano.json_detalhes.ps.fluxoCvs"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Fluxo de Solicitação de abertura de Vagas -->
              <div class="col-lg-2">
                <p>Fluxo de abertura de Vagas<br>
                  <base-switch
                    v-model="plano.json_detalhes.ps.fluxoSolicitacaoVaga"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- AI na avaliação de CVs -->
              <div class="col-lg-2">
                <p>AI na avaliação de CVs<br>
                  <base-switch
                    v-model="plano.json_detalhes.ps.avaliacaoAi"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Enviar aviso de publicação de vagas -->
              <div class="col-lg-2">
                <p>Aviso de publicação de vagas<br>
                  <base-switch
                    v-model="plano.json_detalhes.ps.enviarAvisosPublicacao"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Salvar CVs -->
              <div class="col-lg-2">
                <p>Salvar CVs<br>
                  <base-switch
                    v-model="plano.json_detalhes.ps.salvarCvs"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Gravação de vídeo-entrevista -->
              <div class="col-lg-2">
                <p>Gravação de vídeo-entrevista<br>
                  <base-switch
                    v-model="plano.json_detalhes.ps.gravacaoVideoEntrevista"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Envio de testes -->
              <div class="col-lg-2">
                <label for="enviarTeste">Envio de testes</label>
                <el-select
                  ref="enviarTeste"
                  name="Envio de testes"
                  class="select-primary"
                  id="enviarTeste"
                  v-model="plano.json_detalhes.ps.enviarTeste"
                >
                  <el-option value="Simples" label="Simples"></el-option>
                  <el-option value="Total" label="Total"></el-option>
                </el-select>
              </div>
              <!-- Revalidação de Match -->
              <div class="col-lg-2">
                <label for="reValidaMatch">Revalidação de Match</label>
                <base-switch
                    v-model="plano.json_detalhes.ps.reValidacaoMatch"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- Linha do Card de Inteligência Artificial de Match -->
      <div class="row">
        <div class="col-lg-12">
          <card>
            <template slot="header">
              <div class="row">
                <div class="col-lg-12">
                  <h4>Inteligência Artificial de Match</h4>
                </div>
              </div>
            </template>
            <div class="row flex-wrap">
              <!-- Pretensão salarial -->
              <div class="col-lg-2">
                <p>Pretensão salarial<br>
                  <base-switch
                    v-model="plano.json_detalhes.matchAi.pretensao"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Competências -->
              <div class="col-lg-2">
                <p>Competências<br>
                  <base-switch
                    v-model="plano.json_detalhes.matchAi.competencias"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Idiomas -->
              <div class="col-lg-2">
                <p>Idiomas<br>
                  <base-switch
                    v-model="plano.json_detalhes.matchAi.idiomas"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Formação -->
              <div class="col-lg-2">
                <p>Formação<br>
                  <base-switch
                    v-model="plano.json_detalhes.matchAi.formacao"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Certificações -->
              <div class="col-lg-2">
                <p>Certificações<br>
                  <base-switch
                    v-model="plano.json_detalhes.matchAi.certificacoes"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Experiência no cargo da vaga -->
              <div class="col-lg-2">
                <p>Experiência no cargo da vaga<br>
                  <base-switch
                    v-model="plano.json_detalhes.matchAi.tempoExperiencia"
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                  ></base-switch>
                </p>
              </div>
              <!-- Localização -->
              <div class="col-lg-2">
                <label for="localizacao">Localização</label>
                <input
                  id="localizacao"
                  ref="localizacao"
                  type="text"
                  class="form-control"
                  name="Localização"
                  v-model="plano.json_detalhes.matchAi.localizacao"
                />
              </div>
            </div>
          </card>
        </div>
      </div>
    </template>
    <!-- errosPlano -> <pre>{{errosPlano}}</pre>
    plano -> <pre>{{plano}}</pre> -->
  </div>
</template>

<script>
import Vue from 'vue'
import VCA from '@vue/composition-api'
import { adminApiUrl } from "@/global";
import { Badge, BaseSwitch } from "src/components";
import { Loading, Table, TableColumn, Select, Option, Tooltip, DatePicker } from "element-ui";
import JsonEditorVue from 'json-editor-vue'

Vue.use(VCA)

export default {
  name: "PlanoEmpresa",
  props: {
    propPlano: { type: Object }
  },
  components: {
    Badge,
    BaseSwitch,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    JsonEditorVue
  },
  data() {
    return {
      plano: {},
      nomePlanos: [
        { label: "Free", value: "aa2d6e4f578eb0cfaba23beef76c2194" },
        { label: "Básico", value: "4069743c5c82111a7a66113eb450e95d" },
        { label: "Profissional", value: "e5f66152b69ccd368b3decaa070198b8" },
        { label: "Enterprise", value: "09300df44b9d20cc219b25abddc3346e" }
      ],
      vagaAnuncio: [
        { value: 'Só na MatchJob', text: 'Só na MatchJob' },
        { value: 'Todos', text: 'Todos' }
      ],
      flSave: true,
      flWatcher: false,
      flErros: false,
      errosPlano: {
        geral: [],
        detalhes: [],
        ps: [],
        vagas: [],
        matchAi: [],
        usuarios: [],
        pgCarreiras: []
      }
    }
  },
  methods: {
    async atualizaPlano() {
      await this.$http
        .post(`${adminApiUrl}/empresa/atualizaplano`, this.plano)
        .then(() => {
          alert("Configurações atualizadas!")
          this.flSave = false;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    validaJsonPlano() {
      this.limpaArrErrors();
      const planoKeys = Object.keys(this.plano)
      const json_detalhes = Object.keys(this.plano.json_detalhes)
      const ps = Object.keys(this.plano.json_detalhes.ps);
      const vagas = Object.keys(this.plano.json_detalhes.vagas);
      const matchAi = Object.keys(this.plano.json_detalhes.matchAi);
      const usuarios = Object.keys(this.plano.json_detalhes.usuarios);
      const pgCarreiras = Object.keys(this.plano.json_detalhes.pgCarreiras);
      if (planoKeys.length < 16) {
        this.notificacao('Erro encontrado no JSON do Plano', 'warning');
        let keys = ['id_empresa_plano', 'id_empresa', 'nm_plano', 'dt_inicio', 'dt_fim', 'nu_qtd_usuarios_rh', 'nu_qtd_usuarios', 'fl_processo_rs', 'fl_fluxo_aprovacao_vagas', 'fl_fluxo_aprovacao_cvs', 'fl_ai_avaliacao_cvs', 'fl_enviar_aviso', 'json_detalhes', 'created_at', 'updated_at', 'deleted_at'];
        for(var i = 0; i < keys.length; i++) {
          let idx = planoKeys.find(e => e === keys[i]);
          if (idx === undefined) {
            this.errosPlano.geral.push(keys[i])
          }
        }
      } else if (planoKeys.length > 16) {
        this.notificacao('Erro encontrado no JSON do Plano', 'warning');
        this.errosPlano.geral.push('Excesso de chaves encontradas no geral do plano');
      }
      if (json_detalhes.length < 9) {
        this.notificacao('Erro encontrado no JSON Detalhes do Plano', 'warning');
        let realKeys = ["ps", "vagas", "dt_fim", "matchAi", "nm_plano", "usuarios", "dt_inicio", "id_empresa", "pgCarreiras"];
        for(var i = 0; i < realKeys.length; i++) {
          let idx = json_detalhes.find(e => e === realKeys[i]);
          if (idx === undefined) {
            this.errosPlano.detalhes.push(realKeys[i])
          }
        }
      } else if(json_detalhes.length > 9) {
        this.notificacao('Erro encontrado no JSON Detalhes do Plano', 'warning');
        this.errosPlano.detalhes.push('Excesso de chaves encontradas no Detalhes do Plano');
      }
      if (ps.length < 11) {
        this.notificacao('Erro encontrado no JSON de PS', 'warning');
        const psKeys = ['fluxoCvs', 'criacaoPs', 'salvarCvs', 'fluxoVagas', 'avaliacaoAi', 'enviarTeste', 'testePerfil', 'reValidacaoMatch', 'fluxoSolicitacaoVaga', 'enviarAvisosPublicacao', 'gravacaoVideoEntrevista'];
        for(var i = 0; i < psKeys.length; i++) {
          let idx = ps.find(e => e === psKeys[i]);
          if (idx === undefined) {
            this.errosPlano.ps.push(psKeys[i])
          }
        }
      } else if (ps.length > 11) {
        this.notificacao('Erro encontrado no JSON PS', 'warning');
        this.errosPlano.ps.push('Excesso de chaves encontradas no JSON de PS');
      } else {
        if (typeof this.plano.json_detalhes.ps.fluxoCvs !== 'boolean') {
          this.errosPlano.ps.push('ps.fluxoCvs -> Tipo incorreto - Deve ser boolean')
        }
        if (typeof this.plano.json_detalhes.ps.criacaoPs !== 'boolean') {
          this.errosPlano.ps.push('ps.criacaoPs -> Tipo incorreto - Deve ser boolean')
        }
        if (typeof this.plano.json_detalhes.ps.salvarCvs !== 'boolean') {
          this.errosPlano.ps.push('ps.salvarCvs -> Tipo incorreto - Deve ser boolean')
        }
        if (typeof this.plano.json_detalhes.ps.fluxoVagas !== 'boolean') {
          this.errosPlano.ps.push('ps.fluxoVagas -> Tipo incorreto - Deve ser boolean')
        }
        if (typeof this.plano.json_detalhes.ps.avaliacaoAi !== 'boolean') {
          this.errosPlano.ps.push('ps.avaliacaoAi -> Tipo incorreto - Deve ser boolean')
        }
        if (typeof this.plano.json_detalhes.ps.enviarTeste !== 'string') {
          this.errosPlano.ps.push('ps.enviarTeste -> Tipo incorreto - Deve ser string')
        } else {
          if(this.plano.json_detalhes.ps.enviarTeste !== 'Simples') {
            if(this.plano.json_detalhes.ps.enviarTeste !== 'Total') {
              this.errosPlano.ps.push('ps.enviarTeste -> Valor incorreto - Deve ser Simples ou Total')
            }
          }
        }
        if (typeof this.plano.json_detalhes.ps.testePerfil !== 'string') {
          this.errosPlano.ps.push('ps.testePerfil -> Tipo incorreto - Deve ser string')
        } else {
          if (this.plano.json_detalhes.ps.testePerfil !== '0') {
            if (this.plano.json_detalhes.ps.testePerfil !== '1') {
              if (this.plano.json_detalhes.ps.testePerfil !== '2') {
                this.errosPlano.ps.push('ps.testePerfil -> Valor incorreto - Deve ser: 0 - Não / 1 - Simples / 2 - Total')
              }
            }
          }
        }
        if (typeof this.plano.json_detalhes.ps.reValidacaoMatch !== 'boolean') {
          this.errosPlano.ps.push('ps.reValidacaoMatch -> Tipo incorreto - Deve ser boolean')
        }
        if (typeof this.plano.json_detalhes.ps.fluxoSolicitacaoVaga !== 'boolean') {
          this.errosPlano.ps.push('ps.fluxoSolicitacaoVaga -> Tipo incorreto - Deve ser boolean')
        }
        if (typeof this.plano.json_detalhes.ps.enviarAvisosPublicacao !== 'boolean') {
          this.errosPlano.ps.push('ps.enviarAvisosPublicacao -> Tipo incorreto - Deve ser boolean')
        }
        if (typeof this.plano.json_detalhes.ps.gravacaoVideoEntrevista !== 'boolean') {
          this.errosPlano.ps.push('ps.gravacaoVideoEntrevista -> Tipo incorreto - Deve ser boolean')
        }
      }
      if (vagas.length < 7) {
        this.notificacao('Erro encontrado no JSON de Vagas', 'warning');
        let vagasKeys = ['qtd', 'vagaHO', 'anuncio', 'exportApi', 'importApi', 'multinacional', 'vagasGeracaoIa'];
        for(var i = 0; i < vagasKeys.length; i++) {
          let idx = vagas.find(e => e === vagasKeys[i]);
          if (idx === undefined) {
            this.errosPlano.vagas.push(vagasKeys[i])
          }
        }
      } else if (vagas.length > 7) {
        this.notificacao('Erro encontrado no JSON de Vagas', 'warning');
        this.errosPlano.vagas.push('Excesso de chaves encontradas no JSON de Vagas');
      }
      if (matchAi.length < 7) {
        this.notificacao('Erro encontrado no JSON de MatchAi', 'warning');
        let matchaiKeys = ['idiomas', 'formacao', 'pretensao', 'localizacao', 'competencias', 'certificacoes', 'tempoExperiencia'];
        for(var i = 0; i < matchaiKeys.length; i++) {
          let idx = matchAi.find(e => e === matchaiKeys[i]);
          if (idx === undefined) {
            this.errosPlano.matchAi.push(matchaiKeys[i])
          }
        }
      } else if (matchAi.length > 7) {
        this.notificacao('Erro encontrado no JSON de MatchAi', 'warning');
        this.errosPlano.matchAi.push('Excesso de chaves encontradas no JSON de MatchAi');
      }
      if (usuarios.length < 3) {
        this.notificacao('Erro encontrado no JSON de Usuários', 'warning');
        let userKeys = ['qtdRh', 'authMs', 'qtdArea'];
        for(var i = 0; i < userKeys.length; i++) {
          let idx = usuarios.find(e => e === userKeys[i]);
          if (idx === undefined) {
            this.errosPlano.usuarios.push(userKeys[i])
          }
        }
      } else if (usuarios.length > 3) {
        this.notificacao('Erro encontrado no JSON de Usuários', 'warning');
        this.errosPlano.usuarios.push('Excesso de chaves encontradas no JSON de Usuários');
      }
      if (pgCarreiras.length < 4) {
        this.notificacao('Erro encontrado no JSON da Pagina de Carreiras', 'warning');
        let pgKeys = ['dominio', 'respMobile', 'multiIdioma', 'customizavel'];
        for(var i = 0; i < pgKeys.length; i++) {
          let idx = pgCarreiras.find(e => e === pgKeys[i]);
          if (idx === undefined) {
            this.errosPlano.pgCarreiras.push(pgKeys[i])
          }
        }
      } else if (pgCarreiras.length > 4) {
        this.notificacao('Erro encontrado no JSON da Pagina de Carreiras', 'warning');
        this.errosPlano.pgCarreiras.push('Excesso de chaves encontradas no JSON da Pagina de Carreiras');
      }
    },
    limpaArrErrors() {
      this.errosPlano.geral = []
      this.errosPlano.detalhes = []
      this.errosPlano.ps = []
      this.errosPlano.vagas = []
      this.errosPlano.matchAi = []
      this.errosPlano.usuarios = []
      this.errosPlano.pgCarreiras = []
    },
    //Função de notificação
    notificacao(msg, tipo) {
      this.$notify({
        type: tipo,
        message: msg,
        icon: "tim-icons icon-bell-55",
      });
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.plano = this.propPlano;
    }, 500);
    setTimeout(() => {
      this.validaJsonPlano();
      this.flWatcher = true;
    }, 1000);
  },
  watch: {
    plano: {
      handler(value) {
        if (this.flWatcher){
          this.flSave = false;
        }
      },
      deep: true
    },
    errosPlano: {
      handler(value) {
        if (this.flWatcher){
          this.flErros = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style>
.card-empresas {
  margin: 10px;
  padding: 10px;
}
.bootstrap-switch .bootstrap-switch-label {
  background: #2dce89;
}
.textjson {
  background-color: white;
  max-height: 100%;
}
</style>
